import styled from "styled-components";


interface BarRangeStyledProps{
 active?:  boolean;
 color?: string;

}

export const RangeInputWrapper = styled.div`
display:flex;
flex-direction: column;
width:100%;
margin-top:3rem;

span{
    display: flex;
    width:100%;
    justify-content: space-between;
}


${({ theme }) => theme.breakpoints.down('md')} {
     margin-top:1rem;
    }


`;

export const BarLabel =styled.div<BarRangeStyledProps>`
display: flex;
flex-direction:column;
align-items: center;
justify-content: center;
margin-top:1rem;

svg{
       
       fill: ${({color, active}) => active ? `${color}` : 'transparent'};
   };

span{
   
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-top:0.2rem;
    color: ${({color, active}) => active ? `${color}` : 'transparent'};
    }

${({ theme }) => theme.breakpoints.down('md')} {
     margin-top:0.5rem;

     span{
        display:${({active}) => active ? 'block' : 'none'};
        font-size:0.9rem;
     }
    }

`;


export const BarResult = styled.div`
border-radius: 10px;
height:21px;
width:100%;
background: linear-gradient(90deg, #CC0100 24.15%, #FF922D 50.82%, #81D84E 77.34%);

`;