import styled from "styled-components";
import { Theme } from "utils/theme/theme.types";

interface RadioButtonStyledProps {
    justify?: string;
    htmlFor?: any;
    inline?:boolean;
}


 export const RadioRowStyled = styled.div<RadioButtonStyledProps>`
 display:flex;
 flex-wrap:wrap;
 width:100%;
 
  &:not(:last-child){
     margin-right: 1rem; 
 }

 ${({ theme , inline}) => theme.breakpoints.down('sm')} {
     display:flex;
     flex-direction: ${({inline}) => inline ? 'row' : 'column'};
     align-items: baseline;

     &:not(:last-child){
        margin-right:0.5rem;
     }
    };



 `;

export const RadioRowOuter=styled.div`
//height:40px;
width:100%;
position:relative;


`;

export const RadioWrapper = styled.div<RadioButtonStyledProps>`
    position:relative;
    display:  flex;
    justify-content: center;
    align-items: center;
    &:not(:last-child){
        margin-right: 2rem; 
    }
    cursor:pointer;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        &:not(:last-child){
            margin-right: ${({inline}) => inline ? '0.5rem' : '1rem'}; 
        }
    }

`;

export const RadioLabel= styled.label<RadioButtonStyledProps>`
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
    line-height: 24px;
    padding-left: 0.5rem;
    font-size: 1rem;
    display: block;
    cursor: pointer;

    ${({ theme }) => theme.breakpoints.down('md')} {
        font-size: 1rem; 
    };
`;


export const RadioInput = styled.input`
    width: 1rem;
    height: 1rem;
    border: 1px solid  ${({ theme }) => theme.colors.primary};
    appearance: none;
    border-radius: 99px;
    cursor:pointer;

    &:checked {
        border-color:  ${({ theme }) => theme.colors.primary};
        background-color:  ${({ theme }) => theme.colors.primary};
    }

`;

