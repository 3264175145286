import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const QuestionnaireContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    position: relative;
    ${theme.breakpoints.down('lg')} {
        border-top: none;
        padding-bottom: 0;
        width: 100%;
    }
`;

export const QuestionnaireWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 2.5rem;
    position: relative;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding-right: 0;
    }
`;

export const QuestionSection = styled.div`
    position: relative;
    border-left-width: 2px;
    border-left-color: ${({ theme }) => theme.colors.primary};
    border-left-style: ${({ activeStep }) => (activeStep === 0 ? 'dashed' : 'solid')};
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 19px;
    flex: 1 1 auto;
    padding-top: 0.5rem;
    padding-left: 3.75rem;

    ${({ theme }) => theme.breakpoints.down('md')} {
        border-left-style: none;
        margin-left: 0;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    ::after {
        content: '${({ activeStep }) => activeStep}';
        display: ${({ activeStep }) => (activeStep === 0 ? 'none' : 'flex')};
        position: absolute;
        top: -48px;
        left: -24px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.primary};
        color: #fff;
        align-items: center;
        justify-content: center;

        ${({ theme }) => theme.breakpoints.down('md')} {
            display: none;
        }
    }
`;

export const QuestionnaireTitleWrapper = styled.div`
    display: flex;
    height: 165px;
    width: 100%;
    flex: 0 0 auto;

    ${({ theme }) => theme.breakpoints.down('md')} {
        height: min-content;
        max-height: 40px;
    }
`;

export const QuestionText = styled.span`
    width: 80%;
    min-height: 58px;
    display: block;
    ${({ theme }) => theme.breakpoints.down('lg')} {
        font-size: 1.5rem !important;
        line-height: 1.5 !important;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 100%;
        font-size: 1rem !important;
    }
`;

export const QuestionLabel = styled.span`
    position: absolute;
    top: 125px;
    left: 80px;
    font-size: 1.8rem !important;
    font-weight: 200;
    color: ${({ theme }) => theme.colors.primary};
    user-select: none;

    ${({ theme }) => theme.breakpoints.down('md')} {
        position: relative;
        top: 0;
        left: 0;
        font-size: 1rem !important;
        padding-left: 0.5rem;
        color: ${({ theme }) => theme.colors.textPrimary};
    }
`;

export const QuestionTooltip = styled.div`
    position: relative;
    display: inline-flex;
    vertical-align: top;
    &:not(:first-child) {
        margin-left: 0.5rem;
    }
    &:active {
        svg {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }
    &:hover {
        svg {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }
    svg {
        width: 0.5em;
        height: 0.5em;
        fill: ${({ theme }) => theme.colors.primary};
        transition: fill 0.25s;
    }
`;

export const IntroductionAnswerSection = styled.div`
    display: flex;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    ${({ theme }) => theme.breakpoints.down('sm')} {
        flex-direction: column;
    }
`;

export const QuestionAnswerSection = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    padding-bottom: 20px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        flex-direction: column;
    }
`;

export const HexagonWrapper = styled.div`
    position: relative;
    margin-right: 100px;

    ${({ theme }) => theme.breakpoints.down('md')} {
        margin-right: 0;
        transform: scale(0.8);
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        transform: scale(0.7);
    }
`;

export const IntroductionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    user-select: none;
    margin-bottom: 10px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        display: ${({ intro }) => (intro ? 'flex' : 'none')};
    }
`;

export const IntroductionHeader = styled.span`
    font-size: 1.5rem !important;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.textPrimary};
`;

export const IntroductionDetails = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        flex-direction: row;
    }
`;

export const IntroductionDetailsText = styled.span`
    font-size: 0.85rem !important;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.textPrimary};
    margin: 0;
`;

export const QuestionAnswerSectionLeft = styled.div`
    display: flex;
    min-width: 60%;
    max-width: 60%;
    height: 100%;
    justify-content: center;
    align-items: center;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        min-width: 100%;
        max-width: 100%;
    }
`;

export const QuestionAnswerSectionRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    min-width: 40%;
    max-width: 40%;
    text-align: center;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        min-width: 100%;
        max-width: 100%;
        height: min-content;
    }
`;
