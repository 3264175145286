import styled from 'styled-components';
import { theme } from 'utils/theme/theme';
import { Theme } from 'utils/theme/theme.types';
import { ButtonProps, ButtonSize, ButtonTextColor } from './Button.types';


export const getFontSizeForButtonSize = (buttonSize: ButtonSize | any ) => {
    switch (buttonSize) {
        case 'xtra-small':
            return '0.8rem';
        case 'small':
            return '1.25rem';

        case 'medium':
            return '1.75rem';

        case 'large':
            return '2.25rem';

        default:
            return '1.5rem';
    }
};

const isThemeColor = (color: string | any ) => color === 'primary' || color === 'secondary';

const generateButtonColor = (color: string | any, theme: Theme | any ) => {
    if (isThemeColor(color)) {
        return theme.colors[color as 'primary' | 'secondary'];
    }

    return color;
};

const getButtonTextColor = (color: string | any ) => {
    if (isThemeColor(color)) {
        const transformedColor = `text${color.charAt(0).toUpperCase()}${color.slice(1)}`;
        return theme.colors[transformedColor as ButtonTextColor];
    }

    return color;
};




const ButtonBase = styled.button<ButtonProps>`
    padding: ${({ size }) => (size === 'xtra-small' ? '12px 36px' : '8px 24px')};
    border: none;
    text-transform: uppercase;
    font-weight: ${({ size }) => (size === 'xtra-small' ? 400 : 200)} !important;
    font-size: ${({ size }: ButtonProps) => getFontSizeForButtonSize(size)} !important;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    user-select: none;
    width: ${({ width }) => (width ? `${width}px` : 'max-content')};
    border-radius: ${({ rounded }: ButtonProps) => (rounded ? '8px' : 'none')};
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const ContainedButton = styled(ButtonBase)`
    background-color: ${({ color, theme, disabled }: ButtonProps) =>
    disabled ? '#ccc' : generateButtonColor(color, theme)};
    color: ${({ theme, textColor, disabled }: ButtonProps) => (disabled ? '#666' : getButtonTextColor(textColor))};
    cursor: ${({ theme, color, disabled }: ButtonProps) => disabled ? 'not-allowed' : 'pointer'};

    .arrow-right-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;

        path:not(:first-child) {
            stroke: ${({ theme }: ButtonProps) => theme?.colors.textSecondary};
        }
    }

    .arrow-left-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        left: 10px;

        path:not(:first-child) {
            stroke: ${({ theme }: ButtonProps) => theme?.colors.textSecondary};
        }
    }
`;

export const TextButton = styled(ButtonBase)`
    color: ${({ theme, color }: ButtonProps) => generateButtonColor(color, theme)};

    .arrow-right-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;

        path:not(:first-child) {
            stroke: ${({ color, theme }: ButtonProps) => generateButtonColor(color, theme)};
        }
    }

    .arrow-left-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        left: 10px;

        path:not(:first-child) {
            stroke: ${({ color, theme }: ButtonProps) => generateButtonColor(color, theme)};
        }
    }
`;

export const OutlinedButton = styled(ButtonBase)`
    color: ${({ theme, color, disabled }: ButtonProps) => (disabled ? '#666' : generateButtonColor(color, theme))};
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme, color, disabled }: ButtonProps) => disabled ? '#ccc' : generateButtonColor(color, theme)};
    cursor: ${({ theme, color, disabled }: ButtonProps) => disabled ? 'not-allowed' : 'pointer'};
    .arrow-right-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;

        path:not(:first-child) {
            stroke: ${({ color, theme }: ButtonProps) => generateButtonColor(color, theme)};
        }
    }

    .arrow-left-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        left: 10px;

        path:not(:first-child) {
            stroke: ${({ color, theme }: ButtonProps) => generateButtonColor(color, theme)};
        }
    }
`;

export const SurveyButton = styled.button<ButtonProps>`
    padding: 11px 50px;
    border: none;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    position: relative;
    user-select: none;
    width: ${({ width }) => (width ? `${width}px` : 'max-content')};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    min-width:125px;

    border-radius: 50px !important;
    color: ${({ theme, textColor, disabled , variant }: ButtonProps) => (disabled ? '#666' : variant === 'outlined' ? '#C00000' :  '#FFF' )};
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme, color, disabled , variant }: ButtonProps) => disabled ? '#ccc' : variant === 'outlined' ?  '#C00000' :  '#FFF' };
    cursor: ${({ theme, color, disabled }: ButtonProps) => disabled ? 'not-allowed' : 'pointer'};
    background-color: ${({ color, theme, disabled , textColor }: ButtonProps) =>
        disabled ? '#ccc' : textColor ==='primary' ? '#FFF' : '#C00000'};
    margin:${({margin})=> margin? margin : 0};
    margin-top:${({marginTop})=> marginTop ? marginTop : 0}

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: ${({ size }) => (size === 'xtra-small' ? '12px 26px' : '8px 24px')} !important;
    }
    
`;

