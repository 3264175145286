import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1 1 auto;
    position: relative;
    ${theme.breakpoints.down('lg')} {
        margin-top: auto;
        border-top: none;
        justify-content: center;
    }

    /* ${({ theme }) => theme.breakpoints.down('md')} {
        align-items: center;
    } */
`;

export const SummaryHeader = styled.div`
    text-align: right;
    margin-bottom: 2rem;
    padding-top: 45px;
    color: ${({ theme }) => theme.colors.secondary};
`;

export const SummaryHeaderTitle = styled.span`
    display: block;
    font-weight: 300;
    font-size: 2rem !important;
    color: ${({ theme }) => theme.colors.secondary};
`;

export const SummaryHeaderSubtitle = styled.span`
    display: block;
    font-weight: 200;
    font-size: 1.5rem !important;
    color: ${({ theme }) => theme.colors.secondary};
`;

export const SummaryWrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 50px 40px 120px 40px;
    align-items: center;
    justify-content: center;
    height: 70%;

    {SummaryCircles} {
        transform: scale(1);

        ${({ theme }) => theme.breakpoints.down('xl')} {
            transform: scale(0.9);
        }

        ${({ theme }) => theme.breakpoints.down('lg')} {
            margin-top: -50px;
            transform: scale(0.8);
            padding: 0;
        }

        ${({ theme }) => theme.breakpoints.down('md')} {
            transform: scale(0.7);
            height: 100%;
        }
    }

    ${({ theme }) => theme.breakpoints.down('lg')} {
        padding: 0;
    }
`;

export const SectionButtonsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    > * {
        margin: 10px;

        ${({ theme }) => theme.breakpoints.down('sm')} {
            width: 100%;
        }
    }
`;

export const NavigationButtonsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    > * {
        margin: 10px;

        ${({ theme }) => theme.breakpoints.down('sm')} {
            width: 100%;
        }
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
`;

export const ButtonInnerWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;

    ${({ theme }) => theme.breakpoints.down('lg')} {
        grid-template-columns: repeat(3, 1fr);
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        grid-template-columns: repeat(2, 1fr);
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
        grid-template-columns: 1fr;
    }
`;
