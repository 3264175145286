import { ReactNode, useContext } from 'react';
import styled from 'styled-components';

interface Props {
    children: ReactNode;
    dense?: boolean;
    variante?: string;
}

const StyledHeadRow = styled.tr`
    display: table-row;
    vertical-align: middle;
    outline: 0;
`;

const StyledBodyRow = styled.tr`
    display: table-row;
    vertical-align: middle;
    outline: 0;
    height: 25px;
`;

const StyledFooterRow = styled.tr`
    display: table-row;
    vertical-align: middle;
    outline: 0;
`;

export const TableRow = ({ children, variante }: Props): JSX.Element => {
    switch (variante) {
        case 'head':
            return <StyledHeadRow>{children}</StyledHeadRow>;

        case 'footer':
            return <StyledFooterRow>{children}</StyledFooterRow>;

        default:
            return <StyledBodyRow>{children}</StyledBodyRow>;
    }
};
