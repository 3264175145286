import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex: 1 1 auto;
    min-height: calc(100vh - 120px);
    ${({ theme }) => theme.breakpoints.down('xl')} {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    ${({ theme }) => theme.breakpoints.down('lg')} {
        min-height: calc(100vh - 220px);
    }
    ${({ theme }) => theme.breakpoints.down('md')} {
        min-height: calc(100vh - 160px);
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
        min-height: calc(100vh - 140px);
    }
`;
