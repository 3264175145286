import { ReactNode } from 'react';
import styled from 'styled-components';
// import { ChildrenWrapper, HeaderCellWrapper, StyledHeadCell } from './Table.styled';

interface TableCellProps {
    children: ReactNode;
    sortable?: boolean;
    type?: string;
    searchInput?: boolean;
    noData?: boolean;
}

const StyledHeadCell = styled.th`
    display: table-cell;
    vertical-align: inherit;
    color: #fff;
    flex-grow: 0;
    max-width: 400px;
    top: 0;
    white-space: nowrap;
`;

const HeaderCellWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: inherit;
    border-bottom: 3px solid #c00000;
`;

// ivo
const ChildrenWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: inherit;
    color: gray;
    padding: 1rem;
    font-size: 26px !important;
`;

// ivo
const StyledBodyCell = styled.td`
    display: table-cell;
    vertical-align: inherit;
    text-align: center;
    font-size: 26px !important;
    padding: 0.5rem;
    div {
        display: flex;
    }
`;

export const TableCell = ({ children, type }: TableCellProps): JSX.Element => {
    switch (type) {
        case 'head':
            return (
                <StyledHeadCell>
                    <HeaderCellWrapper>
                        <ChildrenWrapper>{children}</ChildrenWrapper>
                    </HeaderCellWrapper>
                </StyledHeadCell>
            );
            break;
        default:
            return <StyledBodyCell>{children}</StyledBodyCell>;
    }
};
