import { StyledDashboard, DashboardInner } from './Dashboard.styled';
import { Button } from '../../shared/components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import { useAuthContext } from 'shared/hooks/useAuthContext';
import { SectionLabelWrapper } from 'components/CustomersList/CustomersList.styled';
import { SectionLabel } from 'shared/components';

export const DashboardView = (): JSX.Element => {
    const { logoutUser, user } = useAuthContext();
    const navigate = useNavigate();
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));
    const tabletView = useMediaQuery(theme.breakpoints.down('lg'));
    const xlView = useMediaQuery(theme.breakpoints.up('xl'));


    return (
        <StyledDashboard>
             {!tabletView && (
                    <SectionLabelWrapper>
                        <SectionLabel>Dashboard</SectionLabel>
                    </SectionLabelWrapper>
                )}
            <DashboardInner>
                <Button
                    style={{ alignSelf: 'center' }}
                    variant="text"
                    color="primary"
                    size={tabletView ? (mobileView ? 'small' : 'medium') : 'large'}
                    onClick={() => navigate('/customers')}
                >
                    Kundenliste
                </Button>
                <Button
                    style={{ alignSelf: 'center' }}
                    variant="text"
                    color="primary"
                    size={tabletView ? (mobileView ? 'small' : 'medium') : 'large'}
                    onClick={() => navigate('/customers/new')}
                >
                    Neuen Kunden erstellen
                </Button>
                <Button
                    withArrowLeft
                    rounded
                    variant="contained"
                    color="primary"
                    textColor="secondary"
                    size="xtra-small"
                    onClick={logoutUser}
                >
                    Ausloggen
                </Button>
            </DashboardInner>
        </StyledDashboard>
    );
};
