import styled from 'styled-components';
import { theme } from 'utils/theme/theme';
import { DetailsSectionItemDetailsProps, DetailsSectionItemProps, DetailsSectionProps } from './CustomerDetails.types';

export const CustomerDetailsContainer = styled.div`
    display: flex;
    width: 100%;
    position: relative;

    ${theme.breakpoints.down('lg')} {
        border-top: none;
    }
`;

export const MobileCustomerDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    align-self: center;
`;

export const CustomerDetailsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    grid-row-gap: 50px;
    grid-column-gap: 20px;
    padding-top: 100px;
    padding-bottom: 100px;
    margin: auto 0;
    .hexagon-dark-container {
        justify-self: center;
    }

    ${({ theme }) => theme.breakpoints.down('lg')} {
        transform: scale(0.9);
    }
`;

export const MobileLabelProgressWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 12px;
    width: calc(70% - 1px);
    overflow: hidden;
    white-space: nowrap;
`;

export const MobileDetailsItem = styled.div`
    display: flex;
    flex-direction: row;
    height: max-content;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    flex-shrink: 0;
    overflow: hidden;
    width: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 15px;
    > * {
        flex-shrink: 0;
        white-space: nowrap;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 0;
    }

    svg {
        fill: ${({ theme, complete, incomplete }: DetailsSectionItemProps) =>
            complete ? theme.colors.iconGreen : incomplete ? theme.colors.iconRed : theme.colors.mediumGrey} !important;
    }

    .mobileDetailsIcon {
        * {
            fill: ${({ theme, complete, incomplete }: DetailsSectionItemProps) =>
                complete
                    ? theme.colors.iconGreen
                    : incomplete
                    ? theme.colors.iconRed
                    : theme.colors.mediumGrey} !important;
        }
    }

    span {
        text-overflow: ellipsis;
        color: ${({ theme, complete, incomplete }: DetailsSectionItemProps) =>
            complete ? theme.colors.iconGreen : incomplete ? theme.colors.iconRed : theme.colors.mediumGrey} !important;
    }

    :hover {
        svg {
            fill: ${({ theme }) => theme.colors.primary} !important;
        }

        .mobileDetailsIcon {
            * {
                fill: ${({ theme }) => theme.colors.primary} !important;
            }
        }

        span {
            color: ${({ theme }) => theme.colors.primary} !important;
        }
    }
`;

export const MobileDetailsItemLabel = styled.span`
    font-family: 'Gotham SSm A', 'Gotham SSm B' !important;
    color: ${({ theme }) => theme.colors.textPrimary} !important;
    font-size: 1.5rem !important;
    font-weight: 300 !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    ${({ theme }) => theme.breakpoints.down('md')} {
        font-size: 1.4rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 1.2rem !important;
    }
`;

export const MobileDetailsItemProgress = styled.span`
    font-family: 'Gotham SSm A', 'Gotham SSm B' !important;
    color: ${({ theme }) => theme.colors.textPrimary} !important;
    font-weight: 300 !important;
    font-size: 1.25rem !important;

    ${({ theme }) => theme.breakpoints.down('md')} {
        font-size: 1.2rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 1rem !important;
    }
`;

export const DetailsSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    align-items: ${({ align }: DetailsSectionProps) => (align === 'right' ? 'flex-end' : 'flex-start')};
`;

export const DetailsSectionItem = styled.div`
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: ${({ theme, complete, incomplete, hovered }: DetailsSectionItemProps) =>
        hovered
            ? theme.colors.primary
            : complete
            ? theme.colors.iconGreen
            : incomplete
            ? theme.colors.iconRed
            : theme.colors.mediumGrey};
    user-select: none;

    svg {
        * {
            fill: ${({ theme, complete, incomplete, hovered }: DetailsSectionItemProps) =>
                hovered
                    ? theme.colors.primary
                    : complete
                    ? theme.colors.iconGreen
                    : incomplete
                    ? theme.colors.iconRed
                    : theme.colors.mediumGrey};
        }

        transition: transform 0.3s linear;
        transform: ${({ hovered }) => (hovered ? 'scale(1.25)' : 'scale(1)')};
    }

    .details {
        &--left {
            transition: transform 0.3s linear;
            transform: ${({ hovered }) => (hovered ? 'translateX(-10%)' : 'translateX(0)')};
        }
        &--right {
            transition: transform 0.3s linear;
            transform: ${({ hovered }) => (hovered ? 'translateX(10%)' : 'translateX(0)')};
        }
    }
`;

export const DetailsSectionItemDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({ align }: DetailsSectionItemDetailsProps) => (align === 'left' ? 'flex-start' : 'flex-end')};
    text-align: ${({ align }: DetailsSectionItemDetailsProps) => (align === 'left' ? 'left' : 'right')};
    margin-right: 10px;
    margin-left: 10px;
`;

export const DetailsSectionLabel = styled.span`
    text-transform: uppercase;
    font-family: 'Gotham SSm A', 'Gotham SSm B' !important;
    font-size: 1.5rem !important;
`;

export const DetailsSectionProgress = styled.span`
    text-transform: uppercase;
    font-family: 'Gotham SSm A', 'Gotham SSm B' !important;
    font-size: 1.25rem !important;
    font-weight: 200;
`;

export const ExportButtonLabel = styled.span`
    font-size: 0.8rem !important;
`;

export const MobileDetailsButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    margin-top: 20px;
    align-content: end;
    justify-items: center;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        grid-template-columns: 1fr;
    }
`;
