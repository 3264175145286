import { gql } from '@apollo/client';

export const CREATE_COMPANY = gql`
    mutation CREATE_COMPANY($name: String!) {
        createCompany(name: $name) {
            id
            name
        }
    }
`;
