import styled from 'styled-components';
import { CheckboxBoxProps, CheckboxErrorProps, CheckboxLabelProps } from './Checkbox.types';

export const CheckboxContainer = styled.div`
    display: flex;
    position: relative;
`;

export const CheckboxWrapper = styled.label`
    input[type='checkbox'] {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

export const CheckboxBox = styled.div`
    width: 32px;
    height: 32px;
    border: ${({ theme, dialogRed }: CheckboxBoxProps) => !dialogRed ? `1px solid ${theme.colors.secondary}`: `1px solid ${theme.colors.textSecondary}`};
    border-width: 1px;
    border-style: solid;
    border-color: ${({ error, theme, dialogRed }: CheckboxBoxProps) => 
        !dialogRed ? 
            error ? theme.colors.primary : theme.colors.secondary
        :
         theme.colors.textSecondary
        };
    position: relative;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    /* &::after {
        display: ${({ isChecked }) => (isChecked ? 'block' : 'none')};
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-30%, -55%) rotate(-45deg);
        width: 16px;
        height: 2px;
        background-color: #fff;
    }

    &::before {
        display: ${({ isChecked }) => (isChecked ? 'block' : 'none')};
        content: '';
        position: absolute;
        top: 59%;
        left: 21%;
        transform: translateY(-50%) rotate(45deg);
        width: 8px;
        height: 2px;
        background-color: #fff;
    } */
`;

export const CheckboxLabel = styled.span`
    margin-left: 8px;
    font-weight: 300 !important;
    font-size: 1.25rem !important;
    color: ${({ error, theme , dialogRed }: CheckboxLabelProps) => 
    !dialogRed ? 
        error ? theme.colors.primary : theme.colors.textPrimary
    :
        theme.colors.textSecondary
    };
`;

export const CheckboxError = styled.span`
    color: ${({ theme }: CheckboxErrorProps) => theme.colors.error};
    position: absolute;
    bottom: -20px;
    right: 0;
    font-size: 0.8rem !important;
`;
