import React from 'react'
import { BarLabel, BarResult, RangeInputWrapper } from './RangeBarResult.styled';


interface RangeBarResultProps{
  color?: string;
  label?:string
}

export const RangeBarResult = ({color, label}:RangeBarResultProps):JSX.Element => {

    const labelArray : string[] = ['SCHWACH', 'MÄßIG', 'DURCHSCHNITT', 'GUT', 'SEHR GUT'];


  return (
    <RangeInputWrapper>
      <BarResult/>
        <span>
          {labelArray.map((item, index: number)=> 
          <BarLabel active={label === item} color={color} key={`${label}_${index}`}>
            <svg width="16" height="14" viewBox="0 0 16 14"  xmlns="http://www.w3.org/2000/svg">
                <path d="M7.13397 0.5C7.51887 -0.166666 8.48112 -0.166667 8.86602 0.5L15.7942 12.5C16.1791 13.1667 15.698 14 14.9282 14H1.0718C0.301996 14 -0.179129 13.1667 0.205771 12.5L7.13397 0.5Z"/>
            </svg>
            <span>{item}</span>
          </BarLabel>
         )}
        </span>
    </RangeInputWrapper>
  )
} 


