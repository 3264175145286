import { useMutation, useQuery } from '@apollo/client';
import { SectionLabelWrapper } from 'components/CustomersList/CustomersList.styled';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { SuccessDialog } from 'components/SuccessDialog/CreateAccountSuccessDialog';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, SectionLabel, Select, TextField } from 'shared/components';
import { CircularLoader } from 'shared/components/CircularLoader/CircularLoader';
import { useAuthContext } from 'shared/hooks/useAuthContext';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { errorMessages } from 'utils/constants';
import { theme } from 'utils/theme/theme';
import { CREATE_ACCOUNT } from './CreateAccount.mutation';
import { GET_ROLES } from './CreateAccount.query';
import { CreateAccountContainer, CreateAccountForm, ErrorMessage, FieldWrapper } from './CreateAccount.styled';


const inputErrors = ['E-Mail', 'role', 'password', 'name'];

export const CreateAccount = () => {
    const { isAdmin } = useAuthContext();
    const navigate = useNavigate();

    const [roles, setRoles] = useState<any>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [success, setSuccess] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const {
        handleSubmit,
        register,
        formState: { errors },
        setError,
        setValue,
    } = useForm<CreateAccountFormData>();

    const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

    const [createAccount, { loading, error }] = useMutation(CREATE_ACCOUNT, {
        onCompleted: (data) => {
            if (data.signUp.ok) {
                setSuccess(true);
                setMessage('Benutzer erfolgreich angelegt');
                //navigate('/dashboard');
            }

            if (data.signUp.ok === false) {
                setErrorMessage(data.signUp.message);
            }
        },
        onError: (err) => {
            // console.log('error', err);
        },
    });

    const {
        data,
        loading: loadingRoles,
        error: rolesError,
    } = useQuery(GET_ROLES, {
        onCompleted: (data) => {
            const options = data.roles.map((item: any) => {
                return { value: item.name, label: item.description };
            });
            setRoles(options);
        },
    });

    useEffect(() => {
        if (data) {
            setValue('role', 'USER');
        }
    }, [data]);

    const onSubmit = (data: CreateAccountFormData) => {
        // console.log('data', data);
        createAccount({
            variables: {
                name: data.name,
                email: data.email,
                password: data.password,
                role: data.role,
            },
        });
    };

    const onClose = (action: string) => {
        setSuccess(false);
        setMessage('');
    };

    if (error) {
        <ErrorPage error={error.message} />;
    }

    return (
        <CreateAccountContainer>
            {loading ? (
                <CircularLoader thick size="200" />
            ) : (
                <>
                    {!mobileView && (
                        <SectionLabelWrapper>
                            <SectionLabel>Neuen Benutzer anlegen</SectionLabel>
                        </SectionLabelWrapper>
                    )}
                    <CreateAccountForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <Select
                            {...register('role', { required: errorMessages.required })}
                            label="Rolle"
                            placeholder="I am a placeholder"
                            error={!!errors?.role?.message}
                            errorMessage={errors?.role?.message}
                            options={roles}
                        />
                        <TextField
                            {...register('name', { required: errorMessages.required })}
                            label="Name"
                            autoComplete="nope"
                            error={!!errors?.name?.message}
                            errorMessage={errors?.name?.message}
                            placeholder="Name"
                        />
                        <TextField
                            {...register('email', {
                                required: errorMessages.required,
                            })}
                            label="E-Mail"
                            autoComplete="nope"
                            error={!!errors?.email}
                            errorMessage={errors?.email?.message}
                            placeholder="E-Mail-Adresse"
                        />
                        <TextField
                            {...register('password', { required: errorMessages.required })}
                            label="Password"
                            type="password"
                            placeholder="**************"
                            textAlign="center"
                            error={!!errors?.password}
                            errorMessage={errors?.password?.message}
                        />

                         {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                        <Button
                            style={{ width: '100%', alignSelf: 'center', marginTop: 24 }}
                            variant="text"
                            type="submit"
                            color="primary"
                            size="medium"
                        >
                            Erstellen
                        </Button>
                    </CreateAccountForm>
                    {success && <SuccessDialog open={success} message={message} onClose={onClose} />}
                </>
            )}
        </CreateAccountContainer>
    );
};
