import { useMutation, useQuery } from '@apollo/client';
import { GET_ROLES } from 'components/CreateAccount/CreateAccount.query';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button, Select, TextField } from 'shared/components';
import { Checkbox } from 'shared/components/Checkbox/Checkbox';
import { errorMessages } from 'utils/constants';
import { theme } from 'utils/theme/theme';
import { UPDATE_USER } from './EditUserDialog.mutation';
import { DialogBackground, DialogContainer, DialogForm, DialogFormButtons } from './EditUserDialogModal.styled';

interface EditUserDataProps {
    userId: number;
    role?: string;
    email: string;
    password?: string;
    name?: string;
    active: boolean;
}

export const EditUserDialogModal = ({ open, onClose, setDialog, editUser }: any): JSX.Element => {
    const [roles, setRoles] = useState<any>();
    const [success, setSuccess] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [apiSubmitError, setApiSubmitError] = useState<string>('');
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm<EditUserDataProps>({
        shouldUnregister: true,
        defaultValues: {
            role: editUser?.role,
            name: editUser?.name,
            email: editUser?.email,
            active: editUser?.active,
        },
    });

    const [updateUser, { loading }] = useMutation(UPDATE_USER, {
        onCompleted: (data) => {
            if (data.updateUser.ok) {
                setSuccess(true);
                setMessage(data.updateUser.message);
                reset(editUser);
            }
        },
        onError: (err) => {
            // console.log('error UPdateUser', err);
            if (err) {
                setApiSubmitError('Ein System-Fehler ist aufgetreten, bitte versuchen Sie es später erneut.');
            }
        },
    });

    const {
        data,
        loading: loadingRoles,
        error,
    } = useQuery(GET_ROLES, {
        onCompleted: (data) => {
            if (data) {
                const options = data.roles.map((item: any) => {
                    return { value: item.name, label: item.description };
                });
                setRoles(options);
            }
        },
    });

    const onSubmit = (data: EditUserDataProps) => {
        updateUser({
            variables: {
                userId: editUser.id,
                email: data.email,
                active: data.active,
                name: data.name,
                role: data.role,
            },
        });
    };

    const close = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setDialog(false);
        setSuccess(false);
        setMessage('');
        reset(editUser);
    };

    useEffect(() => {
        if (editUser) {
            // console.log('user', editUser);
            setValue('active', editUser.active ? true : false);
            setValue('role', editUser.role);
            setValue('email', editUser.email);
            setValue('name', editUser?.name);
        }
    }, [editUser]);

    return (
        <DialogBackground open={open}>
            {!apiSubmitError && (
                <DialogContainer>
                    {!success ? (
                        <DialogForm onSubmit={handleSubmit(onSubmit)}>
                            <Select
                                {...register('role', { required: errorMessages.required })}
                                label="Rolle"
                                placeholder="I am a placeholder"
                                error={!errors?.role?.message}
                                errorMessage={errors?.role?.message}
                                options={roles}
                                dialogRed={true}
                            />
                            <TextField
                                {...register('name', { required: errorMessages.required })}
                                label="Name"
                                autoComplete="nope"
                                error={!!errors?.name?.message}
                                errorMessage={errors?.name?.message}
                                placeholder="Name"
                                defaultValue={editUser?.name}
                                dialogRed={true}
                            />
                            <TextField
                                {...register('email', {
                                    required: errorMessages.required,
                                })}
                                label="E-Mail"
                                autoComplete="nope"
                                error={!!errors?.email}
                                errorMessage={errors?.email?.message}
                                placeholder="E-Mail-Adresse"
                                dialogRed={true}
                            />
                            <div style={{ marginBottom: '1.5rem' }}>
                                <Checkbox
                                    placeholder={''}
                                    error={false}
                                    errorMessage={''}
                                    {...register('active')}
                                    label="Aktiv​"
                                    defaultValue={editUser?.active}
                                    dialogRed
                                />
                            </div>
                            <DialogFormButtons>
                                <Button
                                    variant="contained"
                                    color="white"
                                    size="xtra-small"
                                    textColor={theme.colors.primary}
                                    rounded
                                    type="submit"
                                    onClick={() => {
                                        console.log('clicked!!!');
                                    }}
                                >
                                    aktualisieren
                                </Button>
                                <Button
                                    variant="contained"
                                    color="white"
                                    size="xtra-small"
                                    textColor={theme.colors.primary}
                                    rounded
                                    type="button"
                                    onClick={(e) => close(e)}
                                >
                                    schließen
                                </Button>
                            </DialogFormButtons>
                        </DialogForm>
                    ) : (
                        <DialogForm>
                            <p>{message}</p>
                            <DialogFormButtons>
                                <Button
                                    variant="contained"
                                    color="white"
                                    size="xtra-small"
                                    textColor={theme.colors.primary}
                                    rounded
                                    type="button"
                                    onClick={() => {
                                        onClose(() => {
                                            setSuccess(false);
                                            setMessage('');
                                        });
                                    }}
                                >
                                    schließen
                                </Button>
                            </DialogFormButtons>
                        </DialogForm>
                    )}
                </DialogContainer>
            )}
            {apiSubmitError && (
                <DialogContainer>
                    <DialogForm>
                        <p style={{ margin: '1rem' }}>{apiSubmitError}</p>
                        <DialogFormButtons>
                            <Button
                                variant="contained"
                                color="white"
                                size="xtra-small"
                                textColor={theme.colors.primary}
                                rounded
                                type="button"
                                onClick={() => {
                                    onClose(() => {
                                        setSuccess(false);
                                        setMessage('');
                                        setApiSubmitError('');
                                    });
                                }}
                            >
                                schließen
                            </Button>
                        </DialogFormButtons>
                    </DialogForm>
                </DialogContainer>
            )}
        </DialogBackground>
    );
};
