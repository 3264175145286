import { ThemeColors } from './theme.types';

export const colors: ThemeColors = {
    primary: '#C00000',
    secondary: '#4A4E52',
    textPrimary: '#4A4E52',
    textSecondary: '#FFF',
    textBlack:'#000000',
    lightGrey: '#E2E4E4',
    mediumGrey: '#999',
    darkGrey: '#4A4E52',
    error: '#C00000',
    iconRed: '#D17171',
    iconGreen: '#819E70',
    iconBlue: '#7DA1C4',
    iconBrown: '#B7B09C',
    iconPurple: '#B284BE',
};
