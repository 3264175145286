import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, TextField } from 'shared/components';
import { errorMessages } from 'utils/constants';
import { theme } from 'utils/theme/theme';
import { DELETE_CUSTOMER } from './DeleteCustomerDialog.mutation';
import {
    DeleteCustomerDialogBackground,
    DeleteCustomerDialogCloseButton,
    DeleteCustomerDialogContainer,
    DeleteCustomerDialogForm,
    DeleteCustomerDialogFormButtons
} from './DeleteCustomerDialog.styled';
import { DeleteCustomerDialogFormData, DeleteCustomerDialogProps } from './DeleteCustomerDialog.types';

export const DeleteCustomerDialog = ({ open, onClose, id, customerName }: DeleteCustomerDialogProps): JSX.Element => {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid, isSubmitting },
    } = useForm<DeleteCustomerDialogFormData>();

    const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
        refetchQueries: ['customers'],
        onCompleted: () => {
            onClose(true);
        },
    });

    const onSubmit = (data: DeleteCustomerDialogFormData) => {
        //console.log('data submitted: ', data);
        deleteCustomer({ variables: {id} });
    };

    return (
        <DeleteCustomerDialogBackground open={open}>
            <DeleteCustomerDialogContainer>
                <DeleteCustomerDialogCloseButton id="dialog-close-button" onClick={onClose} />
                <DeleteCustomerDialogForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    {/* <TextField
                        {...register('name', { required: errorMessages.required })}
                        label="Firma"
                        placeholder="Firma hinzufügen"
                        autoComplete="nope"
                        textAlign="center"
                        type="text"
                        error={!!errors?.name}
                        errorMessage={errors?.name?.message}
                    /> */}
                    Sind Sie sicher, dass sie den Kunden "{customerName}" löschen möchten?
                    <DeleteCustomerDialogFormButtons>
                    <Button
                        variant="contained"
                        color="white"
                        size="xtra-small"
                        textColor={theme.colors.primary}
                        rounded
                        type="submit"
                        onClick={() => {
                            console.log('clicked!!!');
                        }}
                    >
                        Ja
                    </Button>
                    <Button
                        variant="contained"
                        color="white"
                        size="xtra-small"
                        textColor={theme.colors.primary}
                        rounded
                        type="button"
                        onClick={() => {
                            onClose(false);
                        }}
                    >
                        Nein
                    </Button>
                    </DeleteCustomerDialogFormButtons>
                </DeleteCustomerDialogForm>
            </DeleteCustomerDialogContainer>
        </DeleteCustomerDialogBackground>
    );
};
