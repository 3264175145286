import styled, { keyframes } from 'styled-components';
import { theme } from 'utils/theme/theme';
import { ThemeColors } from 'utils/theme/theme.types';
import { StyledCircularLoaderProps } from './CircularLoader.types';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

const getColor = (color?: string): string => theme.colors[color as keyof ThemeColors] ?? color;

export const CircularLoaderContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const StyledCircularLoader = styled.div`
    height: ${({ size }: StyledCircularLoaderProps) => size}px;
    width: ${({ size }: StyledCircularLoaderProps) => size}px;
    position: relative;
    border-radius: 50%;
    border: ${({ thick }) => (thick ? 8 : 4)}px solid ${({ color }) => getColor(color)};
    border-bottom: ${({ thick }) => (thick ? 8 : 4)}px solid transparent;
    animation: ${spin} 1s linear infinite;
`;
