import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const StyledReports = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;
    position: relative;
    width: 100%;

    ${theme.breakpoints.down('lg')} {
        border-top: none;
    }
`;

export const ReportsInner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 1 auto;
    position: relative;
    align-items: center;
    justify-content: center;

    button {
        position: relative;
        &:not(:first-child) {
            margin-top: 3rem;
            &:before {
                content: '';
                position: absolute;
                display: block;
                top: -2rem;
                width: 3rem;
                height: 2px;
                background-color: ${({ theme }) => theme.colors.lightGrey};
            }
        }
    }
    ${theme.breakpoints.down('lg')} {
        border-top: none;
    }
`;

export const DownloadItemButton = styled.span`
    font-family: 'Gotham SSm A', 'Gotham SSM B' !important;
    font-size: 1.75rem !important;
    font-weight: 200;
    color: ${({ theme }) => theme.colors.textPrimary};
    margin-bottom: 15px;
    cursor: pointer;
    margin-left: 1rem;
    display: flex;
    align-items: center;

    .icon {
        position: relative;
        top: 0%;
        transform: translateY(10%);
        margin-left: 1rem;
    }

    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }

    ${({ theme }) => theme.breakpoints.down('lg')} {
        font-size: 1.5rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
        font-size: 1.3rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 1.15rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
        font-size: 1rem !important;
    }
`;
