import { gql, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from 'shared/components';
import { errorMessages } from 'utils/constants';
import { PasswordForm } from './ResetPassword.styled';

interface FormData {
    email: string;
}

const REQUEST_PASSWORD_RESET = gql`
    mutation REQUEST_PASSWORD_RESET($email: String!) {
        requestPasswordReset(email: $email) {
            ok
            errors
            message
        }
    }
`;

export const RequestResetPassword = (): JSX.Element => {
    const navigate = useNavigate();
    const [requestPasswordReset, { error }] = useMutation(REQUEST_PASSWORD_RESET);

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = useForm<FormData>();

    const onSubmit = async (data: FormData): Promise<void> => {
        await requestPasswordReset({
            variables: {
                email: data.email,
            },
        });
    };

    const redirectToLogin = (): void => {
        navigate('/login');
    };

    if (isSubmitSuccessful)
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <span>
                    Sofern es zu der eingegebenen E-Mail-Adresse einen Nutzer gibt, erhalten Sie innerhalb der nächsten
                    Minuten eine E-Mail zur Zurücksetzung Ihres Passworts. Bitte prüfen Sie sicherheitshalber auch Ihren
                    Spam-Ordner.
                </span>
                <Button
                    variant="contained"
                    size="small"
                    type="button"
                    color="primary"
                    textColor="white"
                    rounded
                    onClick={redirectToLogin}
                    style={{ marginTop: 20 }}
                >
                    Zurück zum Login
                </Button>
            </div>
        );

    return (
        <PasswordForm onSubmit={handleSubmit(onSubmit)}>
            <TextField
                {...register('email', { required: errorMessages.required })}
                error={!!errors?.email || error}
                errorMessage={errors?.email?.message || error?.message}
                label="User"
                placeholder="Username"
                type="email"
                textAlign="center"
            />
            <Button disabled={isSubmitting} variant="text" color="primary" type="submit" size="large">
                Absenden
            </Button>
        </PasswordForm>
    );
};
