import styled from "styled-components";


interface FormStyledProps{
    width?: any;
    fillColor?:any;
    height?: any;
    justify?:string;
    align?:string;
    margin?:string;
    padding?:string;
    marginTop?:string;
}

export const FormLabel = styled.div`
 font-weight:700;
 font-size:16px;
    margin-bottom: 1rem;

    ${({ theme }) => theme.breakpoints.down('md')} {
        font-size:1rem;
     }
    

`;

export const FormInputColumn = styled.div<FormStyledProps>`
 width:100%;

&:not(:last-child){
    margin-bottom: 4rem;
 }
 ${({ theme }) => theme.breakpoints.down('md')} {
    &:not(:last-child){
        margin-bottom: 2rem;
     }
    }
`;

export const FormColumn = styled.div<FormStyledProps>`
width:${({width}) => width ? `${width}` : '100%' };
display: flex;
flex-direction: column;
justify-content: center;
height: ${({height})=> height ? `${height}` : 'auto'};
`;

export const FormInputRow= styled.div`
display: flex;
flex-direction:row;
align-items: center;
justify-content: left;
margin-left: -1rem;
margin-right: -1rem;
&:not(:last-child){
    padding-bottom: 1.5rem;
}
${({ theme }) => theme.breakpoints.down('md')} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0;
       
    }

`;

export const RequiredNote= styled.div`
font-size:14px;
font-weight: 400;
line-height: 12px;
margin-bottom:0.5rem;
margin-top: -0.5rem;
`;

export const FormResultContainer= styled.div`
    max-width: 1200px;
    width: 100%;
`
export const FormWrapper=styled.div<FormStyledProps>`
width:${({width})=> width ? `${width}`: '100%'};
margin: ${({margin})=> margin ? margin : 0};
margin-top: ${({marginTop})=> marginTop ? marginTop : 0};
padding:${({padding})=> padding ? padding : 0};

${({ theme }) => theme.breakpoints.down('md')} {
   width:100%;
   margin-top: 0.5rem;

};

${({ theme }) => theme.breakpoints.up('md')} {
   width:95%;
   margin-top: 1.5rem;

};

${({ theme }) => theme.breakpoints.down('sm')} {
   width:100%;
   margin-top: 0.5rem;


};


`;

export const FormWrapperInner = styled.div`
margin-top: 1rem;

 ${({ theme }) => theme.breakpoints.down('md')} {
   margin-top: 0.5rem;
    }
`


export const FormParagraphWrapper= styled.div`
width:100%;
display: flex;
flex-direction: column;
justify-content: space-between;

`;

export const FormParagraph =styled.span`
line-height: 24px;
font-size: 16px;
font-weight: 400;
width:100%;
color: #000000;
margin-bottom:1rem;


`;


export const InputFormErrors = styled.div`
position: absolute;
font-size:12px;
font-weight:500;
line-height: 24px;
color: red;
`;


export const FormInputRowInner= styled.div`
width:100%;
padding: 0 1rem;

${({ theme }) => theme.breakpoints.down('md')} {
   margin-top: 1rem;
    }


`;



