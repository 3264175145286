import styled from "styled-components";

interface DigiCheckLogoProps{

}

export const DigiCheckLogoWrapper = styled.div<DigiCheckLogoProps>`
    margin-bottom: 2rem;

    svg{
        width:100%;
        max-width: 495px;
    }

`;