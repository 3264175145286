import styled from 'styled-components';

export const SidebarContainer = styled.div`
    flex: 1 1 auto;
    width: 100%;
    max-width: 368px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(1);
    margin-top: 0;
    margin-bottom: auto;
    text-align: center;
    user-select: none;
`;

export const SidebarTitle = styled.span`
    font-size: 1.75rem !important;
    font-weight: 400;
`;

export const SidebarIcon = styled.span`
    transform: scale(0.7);
`;

export const SidebarSubtitle = styled.span`
    font-size: 1rem !important;
    font-weight: 300;
    text-transform: uppercase;
`;
