import styled from 'styled-components';

export const ImportancePoint = ({
    height = '43.856',
    width = '37.98',
    positions,
    saveImportance,
    importance,
    answerImportance,
    selectedImportance,
}): JSX.Element => (
    <>
        <StyledImportancePoint
            id={`importance-${importance}`}
            importance={importance}
            selectedImportance={selectedImportance}
            answerImportance={answerImportance}
            onClick={() => saveImportance(importance)}
            positions={positions}
            height={height}
            viewBox="0 0 37.98 43.856"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m288.3 112.986v-21.351l-18.485-10.675-18.49 10.675v21.35l18.49 10.675z"
                fill="#fff"
                stroke="#000"
                transform="translate(-250.825 -80.383)"
            />
        </StyledImportancePoint>
        <ImportanceLabel
            answerImportance={answerImportance}
            selectedImportance={selectedImportance}
            positions={positions}
            importance={importance}
        >
            {importance}
        </ImportanceLabel>
    </>
);

const StyledImportancePoint = styled.svg`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ positions }) => `translate(calc(-50% + ${positions.x}px), calc(-50% + ${positions.y}px))`};
    cursor: pointer;

    path {
        fill: ${({ theme, answerImportance, selectedImportance, importance }) =>
            answerImportance && selectedImportance === importance ? theme.colors.primary : '#fff'};
        transition: fill 0.3s linear;
    }
`;

const ImportanceLabel = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ positions }) => `translate(calc(-50% + ${positions.x}px), calc(-50% + ${positions.y}px))`};
    color: ${({ theme, answerImportance, selectedImportance, importance }) =>
        answerImportance && selectedImportance === importance ? theme.colors.textSecondary : theme.colors.textPrimary};
    font-family: 'Tungsten A', 'Tungsten B' !important;
    font-weight: 500;
    font-size: 1.5rem !important;
    pointer-events: none;
    user-select: none;

    transition: color 0.3s linear;
`;
