import { useQuery } from '@apollo/client';
import { AnyFn } from '@react-spring/types';
import { SectionLabelWrapper } from 'components/CustomersList/CustomersList.styled';
import { EditUserDialogModal } from 'components/EditUserDialog/EditUserDialogModal';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { PasswordDialog } from 'components/PasswordDialog/PasswordDialog';
import { MagnifyingGlass, User } from 'phosphor-react';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SectionLabel } from 'shared/components';
import { CircularLoader } from 'shared/components/CircularLoader/CircularLoader';
import { TextFilter } from 'shared/components/Filters/TextFilter';
import { Pagination } from 'shared/components/Pagination/Pagination';
import { Table } from 'shared/components/Table/Table';
import { useAuthContext } from 'shared/hooks/useAuthContext';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import { GET_USERS } from './UserManagment.query';
import { UserListWrapper, UsersListContainer } from './UserManagment.styled';

const pageSize = 10;

export const UserManagment = (): JSX.Element => {
    const { isAdmin, user } = useAuthContext();
    const { data, loading, error, refetch: refetchUsers } = useQuery(GET_USERS);
    const navigate = useNavigate();

    const [filterData, setFilterData] = useState([]);
    const [filterValue, setFilterValue] = useState('');
    const [filterError, setFilterError] = useState('');
    const [editUserDialog, setEditUserDialog] = useState<boolean>(false);
    const [passwordDialog, setPasswordDialog] = useState<boolean>(false);
    const [editUser, setEditUser] = useState<any>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageData, setCurrentPageData] = useState([]);

    const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

    const columns = [
        {
            id: 1,
            name: 'active',
            label: 'Status',
        },
        {
            id: 2,
            name: '__typename',
            label: 'Rolle',
        },
        {
            id: 3,
            name: 'name',
            label: 'Name',
        },
        {
            id: 4,
            name: 'email',
            label: 'E-Mail',
        },
        {
            id: 5,
            name: 'actions',
            label: 'Aktionen',
        },
    ];

    useEffect(() => {
        if (data?.users) {
            // console.log(data.users);
            const sorted: any = sortData(data?.users);
            const firstPageIndex = (currentPage - 1) * pageSize;
            const lastPageIndex = firstPageIndex + pageSize;
            setCurrentPageData(sorted.slice(firstPageIndex, lastPageIndex));
        }
    }, [data, currentPage]);

    useEffect(() => {
        if (filterValue) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const filter: any = [...data?.users].filter((item: any) => {
                return item.name.includes(filterValue) || item.email.includes(filterValue);
            });
            if (filter) {
                const sorted: any = sortData(filter);
                setFilterData(sorted);
            } else {
                setFilterData([]);
                setFilterValue('');
            }
        }
    }, [filterValue, data, currentPage]);

    const handleUser = (user: any, action: string) => {
        console.log('user', user);
        // eslint-disable-next-line default-case
        switch (action) {
            case 'password':
                setEditUser(user);
                setPasswordDialog(true);
                break;
            case 'edit':
                setEditUser(user);
                setEditUserDialog(true);
                break;
        }
    };

    const onClose = () => {
        refetchUsers();
        setEditUserDialog(false);
        setEditUser('');
    };

    const sortData = (array: any) => {
        const newArray = [...array];
        return newArray.sort((a, b) => {
            if (a.name !== b.name) {
                if (a.name < b.name) {
                    return -1;
                } else {
                    return 1;
                }
            } else {
                return 0;
            }
        });
    };

    if (error) {
        return <ErrorPage error={error.message} />;
    }

    return (
        <>
            {loading ? (
                <CircularLoader />
            ) : (
                <>
                    <UsersListContainer>
                        {!mobileView && (
                            <SectionLabelWrapper className="subHeader">
                                <SectionLabel>Benutzerverwaltung</SectionLabel>
                            </SectionLabelWrapper>
                        )}
                        <TextFilter
                            value={filterValue ? filterValue : ''}
                            onChange={(e: { target: { value: SetStateAction<string> } }) =>
                                setFilterValue(e.target.value)
                            }
                            placeholder="Benutzer suchen"
                            error={filterError}
                        />

                        <Table
                            columns={columns}
                            rows={filterValue ? filterData : currentPageData}
                            loading={loading}
                            handleUser={handleUser}
                        />

                        {!filterValue && (
                            <Pagination
                                currentPage={currentPage}
                                totalCount={data?.users?.length}
                                pageSize={pageSize}
                                onPageChange={(page: SetStateAction<number>) => setCurrentPage(page)}
                            />
                        )}
                    </UsersListContainer>

                    <EditUserDialogModal
                        open={editUserDialog}
                        onClose={onClose}
                        setDialog={setEditUserDialog}
                        editUser={editUser}
                    />
                    <PasswordDialog
                        open={passwordDialog}
                        setDialog={setPasswordDialog}
                        editUser={editUser}
                        setEditUser={setEditUser}
                    />
                </>
            )}
        </>
    );
};
