import { useEffect, useState } from 'react';
import { surveyData } from 'components/SurveyData/SurveyData';
import { FormProvider, useForm } from 'react-hook-form';


//! component 
import { SurveyForm } from 'components/SurveyForms/SurveyForm';
import { SidebarCircle } from 'shared/components/SidebarCircle/SidebarCircle';
import { DigiCheckQuickLogo } from 'shared/DigiCheckQuickLogo/DigiCheckQuickLogo';
import { Button, Header } from 'shared/components';


// ! stylecomponents
import { ButtonContainer, SurveyInner, SurveyOuter, SurveyWizard, SurveyWizardActiveBar, SurveyWizardBarGray, SurveyWizardItem, SurveyWizardItemWrapper, SurveyIntroWrapper, SurveyHeader, SurveyStep, StepTitle, SurveyContainer, SurveyFormWrapper, SideBarWrapper, SurveyParagrapgh, SurveyInnerInner } from './Survey.styled';
import { LayoutSurvey } from 'components/Layout/LayoutSurvey';


// ! SVGS
import Infrastructure from '../../assets/img/infrastruktur.svg';
import Security from '../../assets/img/security.svg';
import Mobiles from '../../assets/img/mobiles.svg';
import Auswertung from '../../assets/img/auswertung.svg';
import Termin from '../../assets/img/termineVereinbaren.svg';


//! API
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { QUICK_CHECK_VALIDATION } from 'shared/queries';
import { SAVE_QUICK_CHECK_ANSWERS, SEND_QUICK_CHECK_MEETING_REQUEST } from 'shared/mutations';
import { CircularLoader } from 'shared/components/CircularLoader/CircularLoader';
import styled from 'styled-components';

const ErrorTitle = styled.h1`
    font-size: 2rem;
    margin-bottom: .5rem;
    font-weight: 400;
    color: #C00000;
`
export const Survey = () => {

    const  { partnerId } = useParams()

    const { data:queryValidation, loading: queryLoading, error:queryError } = useQuery(QUICK_CHECK_VALIDATION, { variables: { partnerId }})

    const [survey, setSurvey] = useState<Survey>(surveyData)
    const [selectedStep, setSelectedStep] = useState<number>(0)
    const [result, setResult] = useState<any>(0)
    const [isSubmited, setIsSubmited] = useState<boolean>(false)
    const [isErrorApi, setIsErrorApi] = useState<boolean>(false)
    const [token, setToken]=useState<string>();

    const options = [
        { value: 0, label: 'Nein, trifft nicht zu.' },
        { value: 10, label: 'Trifft teilweise zu.' },
        { value: 20, label: 'Ja, trifft zu.' },
      ];
    
    

    const [ submitResults ] = useMutation(SAVE_QUICK_CHECK_ANSWERS,{
        onCompleted: (data ) =>{
            if(data?.saveQuickCheckAnswers?.ok && data?.saveQuickCheckAnswers?.token ){
                setToken(data?.saveQuickCheckAnswers?.token)
            }
            
        },
        onError:(err)=>{
        },

    })

    const [ submitRequestMeeting, {data, loading: loadingMeeting}]= useMutation(SEND_QUICK_CHECK_MEETING_REQUEST,{
            onCompleted:(data)=>{
                if(data?.sendQuickCheckMeetingRequest?.ok){
                    setIsSubmited(true)
                } else {
                    setIsErrorApi(true)
                }
            },
            onError:(err)=>{
                setIsErrorApi(true)
                console.log('error', err);
            },
    })

    const formMethods = useForm({
        shouldFocusError: false,
        shouldUnregister: false,

    });
    const { formState: { errors }, handleSubmit, reset } = formMethods;

    useEffect(() =>{
        setIsErrorApi(false);
        setIsSubmited(false);
        setToken("")
    },[queryLoading, queryValidation])


    useEffect(()=>{

        console.log('partnerId', partnerId)
    },[partnerId])


    useEffect(() => {
        if (selectedStep === 0) {
            reset()
            setResult(0)
            setIsErrorApi(false)
            setIsSubmited(false)
            setToken("")
        }

    }, [selectedStep])


    const onSubmit = (data: any) => {
        let form;
        let points = Object?.values(data)?.reduce((prev: any, current: any) => {
            if(Number.isNaN(Number(prev))){
                return  0;
            }
            if(Number.isNaN(Number(current))){
                return Number(prev);
            }
            return Number(prev) + Number(current)
        });

        console.log('data', data)
        console.log(points)

        setResult(data);
        setResult(points);
        form={
            infrastructure_1: options?.find((item: any) => item?.value == data?.infrastruktur_1)?.label,
            infrastructure_2: options?.find((item: any) => item?.value == data?.infrastruktur_2)?.label,
            security_1: options?.find((item: any) => item?.value == data?.security_1)?.label,
            security_2: options?.find((item: any) => item?.value == data?.security_2)?.label,
            mobiles_1: options?.find((item: any) => item?.value == data?.mobiles_1)?.label,
            mobiles_2: options?.find((item: any) => item?.value == data?.mobiles_2)?.label,
        }


        console.log(form)
                
        if(selectedStep == 3){ 
 
            submitResults({
                variables:{ 
                    partnerId: partnerId,
                    resultInPercent: points,
                    answers:form 
                }})
        }

        if (selectedStep == 5) {
            form = {
                anrede: data?.salutation,
                name: data?.name,
                email: data?.email,
                telefon: data?.phoneNumber ? data.phoneNumber : undefined,
                unternehmen: data?.company,
                standort: data?.location,
                datenschutzhinweise: data?.consent
            }
            submitRequestMeeting({variables: { partnerToken: partnerId, resultToken: token, form: form}})
        } 


        
        nextStep()
        
    }

    const nextStep = () => {
        if (selectedStep === 5) {
            setSelectedStep(5)
        } else {
            setSelectedStep(selectedStep + 1)
        }
    }

    const previousStep = () => {
        if (selectedStep === 0) {
            setSelectedStep(0)
            reset()
        } else {
            setSelectedStep(selectedStep - 1)
        }
    }

    const svgsSurvey = [
        <Infrastructure />,
        <Security />,
        <Mobiles />,
        <Auswertung />,
        <Termin />
    ]


    return (

        <>
        { queryLoading && (
        <LayoutSurvey>
            <SurveyOuter>
                <CircularLoader thick size="200" />
            </SurveyOuter>
        </LayoutSurvey>
        )}

        { !queryLoading && !queryValidation?.quickCheckValidation?.ok && (
        <LayoutSurvey>
            <SurveyOuter>
                <SurveyIntroWrapper>
                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="#C00000" viewBox="0 0 256 256"><path d="M160,40A88.09,88.09,0,0,0,81.29,88.67,64,64,0,1,0,72,216h88a88,88,0,0,0,0-176Zm0,160H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.11A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,72,72Zm-8-72V88a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,172,164Z"></path></svg>
                    <SurveyParagrapgh size="20px" weight="700">Etwas ist falsch gelaufen</SurveyParagrapgh>
                    <SurveyParagrapgh size="20px" > Bitte überprüfen Sie, ob Ihr Token korrekt ist, oder versuchen Sie es später noch einmal</SurveyParagrapgh> 
                </SurveyIntroWrapper>
            </SurveyOuter>
        </LayoutSurvey>
         )}

        { !queryLoading && queryValidation?.quickCheckValidation?.ok && !isSubmited && !isErrorApi && (
        <FormProvider {...formMethods}>
            <LayoutSurvey>
                {selectedStep === 0 && !isSubmited && (
                    <SurveyOuter>
                        <SurveyIntroWrapper>
                            <DigiCheckQuickLogo surveyPage width="400" />
                            <SurveyForm step={survey?.steps[selectedStep]} />
                            <Button
                                group="survey"
                                color='primary'
                                textColor='white'
                                size="xtra-small"
                                onClick={nextStep}
                                marginTop="1rem">Los geht's</Button>
                        </SurveyIntroWrapper>
                    </SurveyOuter>
                )
                }

                {selectedStep > 0 && !isSubmited && <>
                    <Header survey />
                    <SurveyInner>
                        <SideBarWrapper>
                            <SidebarCircle survey width="100%" score={Math.floor(((selectedStep == 4 ? 113 : selectedStep == 5 ? 100 : 125) / svgsSurvey?.length * selectedStep))} icon={svgsSurvey[selectedStep - 1]} />
                        </SideBarWrapper>


                        <SurveyContainer>
                            <SurveyHeader>
                                <SurveyStep>{survey?.steps[selectedStep].id}</SurveyStep>
                                <StepTitle>{survey?.steps[selectedStep].name}</StepTitle>
                            </SurveyHeader>

                            <SurveyFormWrapper>
                                <SurveyInnerInner>
                                    <SurveyForm step={survey?.steps[selectedStep]} surveyResult={result} />
                                </SurveyInnerInner>
                                <ButtonContainer>
                                    <Button
                                        group="survey"
                                        type="submit"
                                        color="textSecondary"
                                        textColor='primary'
                                        size="xtra-small"
                                        variant="outlined"
                                        onClick={previousStep}
                                    >
                                        Zurück
                                    </Button>
                                    <Button
                                        group="survey"
                                        color='primary'
                                        textColor='white'
                                        // size="xtra-small"
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        {selectedStep < 4 && 'Weiter'}
                                        {selectedStep === 4 && 'Termine vereinbaren'}
                                        {selectedStep === 5 && !loadingMeeting && 'Absenden'}
                                        { loadingMeeting && <CircularLoader size="20" color="white"/>}
                                    </Button>
                                </ButtonContainer>
                            </SurveyFormWrapper>

                            <SurveyWizard>
                                <SurveyWizardActiveBar width={Math.floor((150 / survey.steps?.length * (selectedStep - 1)))} />
                                <SurveyWizardBarGray />
                                <SurveyWizardItemWrapper>
                                    {survey.steps.map((item: any) => {
                                        if (item.id == 0) {
                                            return
                                        }
                                        return (<SurveyWizardItem
                                            key={item.name}
                                            activated={item.id <= selectedStep}
                                        ><span>{item.id}</span></SurveyWizardItem>)
                                    })}
                                </SurveyWizardItemWrapper>

                            </SurveyWizard>
                        </SurveyContainer>

                    </SurveyInner>
                </>}

            </LayoutSurvey>
        </FormProvider>
        )}

        { isSubmited && <LayoutSurvey>
                    <SurveyOuter>
                        <SurveyIntroWrapper>
                            <DigiCheckQuickLogo surveyPage width="400" />
                                <ErrorTitle>Vielen Dank für Ihr Interesse!</ErrorTitle>
                                <SurveyParagrapgh>Wir werden uns zeitnah bei Ihnen melden.</SurveyParagrapgh>
                        </SurveyIntroWrapper>
                    </SurveyOuter>
                </LayoutSurvey>
                }
        { isErrorApi &&
            <LayoutSurvey>
                <SurveyOuter>
                    <SurveyIntroWrapper>
                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="#C00000" viewBox="0 0 256 256"><path d="M160,40A88.09,88.09,0,0,0,81.29,88.67,64,64,0,1,0,72,216h88a88,88,0,0,0,0-176Zm0,160H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.11A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,72,72Zm-8-72V88a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,172,164Z"></path></svg>
                            <ErrorTitle>Etwas ist schief gelaufen</ErrorTitle>
                            <SurveyParagrapgh>
                                Ihre Anfrage konnte nicht verschickt werden. Bitte versuchen Sie es später erneut.
                            </SurveyParagrapgh>
                    </SurveyIntroWrapper>
                </SurveyOuter>
            </LayoutSurvey>
            }
        </>
    );
};
