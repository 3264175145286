import { MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
import { Button, NoteIcon } from 'shared/components';
import { theme } from 'utils/theme/theme';
import {
    NoteDialogBackground,
    NoteDialogBody,
    NoteDialogCloseButton,
    NoteDialogContainer,
    NoteDialogFooter,
    NoteDialogHeader,
    NoteDialogTextArea,
    NoteDialogTitle,
} from './NoteDialog.styled';
import { NoteDialogProps } from './NoteDialog.types';

export const NoteDialog = ({
    open,
    saveNotes = () => undefined,
    onClose = () => undefined,
    initValue = '',
}: NoteDialogProps) => {
    const [inputValue, setInputValue] = useState<string | null>(initValue);

    if (typeof saveNotes !== 'function') {
        console.error('Param saveNotes should be a function, otherwise notes cannot be saved properly.');
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setInputValue(e.target.value);
    };

    const handleDialogClose = (e?: any): void => {
        if (e?.target?.id === 'dialog-close-button' && inputValue !== initValue) {
            console.warn('unsaved changes!');
        } else {
            onClose();
        }
    };

    const handleButtonClick = (): void => {
        saveNotes(inputValue);
        handleDialogClose();
    };

    useEffect(() => {
        setInputValue(initValue);
    }, [initValue]);

    return open ? (
        <>
            <NoteDialogBackground open={open}>
                <NoteDialogContainer>
                    <NoteDialogCloseButton id="dialog-close-button" onClick={handleDialogClose} />
                    <NoteDialogHeader>
                        <NoteIcon />
                        <NoteDialogTitle label="Notiz" />
                    </NoteDialogHeader>
                    <NoteDialogBody>
                        <NoteDialogTextArea onChange={handleInputChange} value={inputValue} />
                    </NoteDialogBody>
                    <NoteDialogFooter>
                        <Button
                            variant="contained"
                            color="white"
                            onClick={handleButtonClick}
                            size="xtra-small"
                            textColor={theme.colors.primary}
                            rounded
                        >
                            Hinzufügen
                        </Button>
                    </NoteDialogFooter>
                </NoteDialogContainer>
            </NoteDialogBackground>
        </>
    ) : null;
};
