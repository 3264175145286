 export const phoneValidator=(value: any)=>{
    let result;
    const regexPattern = /^\s*\+?\s*\d[\d\s-]*$/
    const isValid = regexPattern.test(value)
    if(!isValid){
        result = 'Bitte geben Sie eine gültige Nummer ein'
    }
    return result
 }



 export const emailValidator = (value: any)=>{
    let result;
    const regexPattern =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // const regexPattern= /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{5,}$/
    const isValid = regexPattern.test(value)
    if(!isValid){
        result ='Bitte geben Sie eine gültige E-Mail Adresse ein';
    }
    return result;
 }