import styled from "styled-components";
import { theme } from 'utils/theme/theme';



export const TextInputWrapper = styled.div`
width:100%;
padding:0 1rem;
//padding-left:0rem;

${({ theme }) => theme.breakpoints.down('md')} {
  &:not(:first-child){
    padding-top: 1.5rem;
  };
};

`;


export const TextInputInner = styled.div`
border: 1px solid #C00000;
border-radius: 8px;
padding:1rem;
${({ theme }) => theme.breakpoints.down('md')} {
    padding:.5rem 1rem;
}`;

export const TextInputStyled =styled.input`
border: none;
width:100%;
color:#000000 !important;
font-size:1rem;
outline: none;
font-weight: 400 !important;
line-height:1.5;
background-color: transparent;
${({ theme }) => theme.breakpoints.down('md')} {
font-size:.8rem;
}
&::placeholder{
    font-weight: 400 !important;
    color:#000000 !important;
    line-height: 24px;
    background-color: transparent;
}

&:-webkit-autofill,
&:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

&:focus{
 background-color: transparent;
}
`; 


export const TextInputError=styled.span`
color: red;
font-size:1rem;
`;


