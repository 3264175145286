import { ContainedButton, getFontSizeForButtonSize, OutlinedButton, SurveyButton, TextButton } from './Button.styled';
import { ButtonProps } from './Button.types';
import ArrowIcon from '../../../assets/img/arrow-right.svg';

export const Button = ({
    variant = 'contained',
    color = 'primary',
    textColor = 'primary',
    children,
    onClick,
    size = 'medium',
    type = 'button',
    withArrowRight = false,
    withArrowLeft = false,
    style,
    rounded,
    width,
    disabled,
    group,
    margin,
    marginTop,
}: ButtonProps): JSX.Element => {

    if(group === 'survey'){
        return (
        <SurveyButton
            disabled={disabled}
            textColor={textColor}
            style={style}
            type={type}
            color={color}
            onClick={onClick}
            size={size}
            width={width}
            variant={variant}
            margin={margin}
            marginTop={marginTop}
        >
         {children}
        </SurveyButton>)
    }

    if (variant === 'outlined' && !group) {
        return (
            <OutlinedButton
                disabled={disabled}
                textColor={textColor}
                rounded={rounded}
                style={style}
                type={type}
                color={color}
                onClick={onClick}
                size={size}
                width={width}
            >
                {withArrowLeft && (
                    <ArrowIcon
                        height={getFontSizeForButtonSize(size)}
                        width={getFontSizeForButtonSize(size)}
                        class="arrow-left-icon"
                    />
                )}
                {children}
                {withArrowRight && (
                    <ArrowIcon
                        height={getFontSizeForButtonSize(size)}
                        width={getFontSizeForButtonSize(size)}
                        class="arrow-right-icon"
                    />
                )}
            </OutlinedButton>
        );
    } else if (variant === 'text') {
        return (
            <TextButton
                disabled={disabled}
                textColor={textColor}
                rounded={rounded}
                style={style}
                type={type}
                color={color}
                onClick={onClick}
                size={size}
                width={width}
            >
                {withArrowLeft && (
                    <ArrowIcon
                        height={getFontSizeForButtonSize(size)}
                        width={getFontSizeForButtonSize(size)}
                        class="arrow-left-icon"
                    />
                )}
                {children}
                {withArrowRight && (
                    <ArrowIcon
                        height={getFontSizeForButtonSize(size)}
                        width={getFontSizeForButtonSize(size)}
                        class="arrow-right-icon"
                    />
                )}
            </TextButton>
        );
    } else {
        return (
            <ContainedButton
                disabled={disabled}
                textColor={textColor}
                rounded={rounded}
                style={style}
                type={type}
                color={color}
                onClick={onClick}
                size={size}
                width={width}
            >
                {withArrowLeft && (
                    <ArrowIcon
                        height={getFontSizeForButtonSize(size)}
                        width={getFontSizeForButtonSize(size)}
                        class="arrow-left-icon"
                    />
                )}
                {children}
                {withArrowRight && (
                    <ArrowIcon
                        height={getFontSizeForButtonSize(size)}
                        width={getFontSizeForButtonSize(size)}
                        class="arrow-right-icon"
                    />
                )}
            </ContainedButton>
        );
    }
};
