import { useFormContext } from 'react-hook-form'
import { CheckBoxLabel, CheckboxInput, SurveyCheckBoxInner, SurveyCheckBoxWrapper } from './SurveyCheckBox.styled';
import { InputFormErrors } from 'components/SurveyForms/SurveyForm.styled';

export const SurveyCheckBox = ({name, required, label, disabled, readOnly}:SurveyCheckBoxProps) :JSX.Element => {

    const { register, formState: {errors}, } = useFormContext();
    const { ref, ...rest } = register(name, {
        required: required,
    });

  return (
    <SurveyCheckBoxWrapper>
        <SurveyCheckBoxInner>
            <CheckboxInput
            id={name}
            type="checkbox"
            disabled={disabled}
            readOnly={readOnly}
            {...register(name, {required: required})}
            />
            <CheckBoxLabel htmlFor={name}>
                <p dangerouslySetInnerHTML={{__html: label}}></p>
            </CheckBoxLabel>
        </SurveyCheckBoxInner>

        {errors[name] && <InputFormErrors>{errors[name]?.message?.toString()}</InputFormErrors>}
    </SurveyCheckBoxWrapper>
  )
}


