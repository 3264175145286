import styled from 'styled-components';

export const ClickAwayContainer = styled.div`
    position: fixed;
    background-color: transparent;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
    justify-content: center;
    align-items: center;
    z-index: 490 !important;
`;

export const MobileMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    min-width: 300px;
    max-width: 300px;
    position: fixed;
    right: 0;
    top: 0;
    background-color: #fff;
    box-shadow: -2px 0px 4px 0px #ccc;
    padding: 0 1rem;

    z-index: 500 !important;

    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(105%)')};

    transition: transform 0.3s linear;

    svg {
        margin-top: auto;
        margin-bottom: 8px;
        transform: scale(0.7);
    }
`;

export const MobileMenuHeader = styled.div`
    display: flex;
    height: 60px;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    padding-top: 1rem;
`;

export const MobileMenuCloseButton = styled.div`
    background-color: ${({ theme }) => theme.colors.textSecondary};
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    margin-right: 20px;

    ::before {
        content: '';
        width: 2px;
        height: 28px;
        transform: translate(-50%, -50%) rotate(-45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 8px;
    }

    ::after {
        content: '';
        width: 2px;
        height: 28px;
        transform: translate(-50%, -50%) rotate(45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 8px;
    }
`;
