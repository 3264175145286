import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from 'shared/hooks/useAuthContext';
import { LoginInfo, MenuItem, StyledMenu, UserInfo } from './Menu.styled';

const questionnaireRegex = /\/[0-9]+\/questionnaire\/[0-9]+$/;
const summaryRegex = /\/[0-9]+\/summary/;
const passwordResetRegex = /^\/reset-password/;

export const Menu = (): JSX.Element => {
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
    const { user, logoutUser, isAdmin, isReporter } = useAuthContext();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLogin(location.pathname === '/login');
        setIsPasswordReset(passwordResetRegex.test(location.pathname));
        
    }, [location]);

    const redirectToCustomersList = (): void => {
        navigate('/customers');
    };

    const redirectToCustomerDetails = (): void => {
        const customerId = location.pathname.split('/')[2];
        navigate(`/customers/${customerId}/overview`);
    };

    const redirectToLogin = (): void => {
        navigate('/login');
    };

    const redirectToAdminSettings = (): void => {
        navigate('/admin');
    };

    const redirectToDashboard = (): void => {
        navigate('/dashboard');
    };

    // console.log('USER', user);

    return (
        <StyledMenu>
            {!isPasswordReset && (
                <>
                    {isLogin && !user && <LoginInfo>User</LoginInfo>}
                    {!isLogin && user &&  <UserInfo>{user?.name?.replace(/ /g, '.')}</UserInfo>}
                    {!isLogin && user &&  (
                        <>
                            {questionnaireRegex.test(location.pathname) || summaryRegex.test(location.pathname) ? (
                                <MenuItem onClick={redirectToCustomerDetails}>Kundenübersicht</MenuItem>
                            ) : null}
                            <MenuItem onClick={redirectToDashboard}>Dashboard</MenuItem>
                            <MenuItem onClick={redirectToCustomersList}>Kundenliste</MenuItem>

                            {(isAdmin || isReporter) && <MenuItem onClick={redirectToAdminSettings}>Administration</MenuItem>}

                            <MenuItem className="logout" onClick={logoutUser}>
                                Ausloggen
                            </MenuItem>
                        </>
                    )}
                </>
            )}
        </StyledMenu>
    );
};
