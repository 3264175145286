import { useState } from 'react';
import { MobileWizardContainer, MobileWizardStep, MobileWizardStepSeparator } from './MobileWizard.styled';
import { MobileWizardProps } from './MobileWizard.types';

export const MobileWizard = ({ steps, activeStep = 1 }: MobileWizardProps): JSX.Element => {
    return (
        <MobileWizardContainer>
            {steps.map((step) => (
                <>
                    <MobileWizardStep key={`mobile-wizard-step-${step}`} active={step === activeStep}>
                        {step}
                    </MobileWizardStep>
                    {steps.indexOf(step) < steps.length - 1 ? <MobileWizardStepSeparator /> : null}
                </>
            ))}
        </MobileWizardContainer>
    );
};
