import { useMutation } from '@apollo/client';
import { PasswordDialog } from 'components/PasswordDialog/PasswordDialog';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Select } from 'shared/components';
import { useAuthContext } from 'shared/hooks/useAuthContext';
import { LOGIN } from 'shared/mutations';
import { errorMessages } from 'utils/constants';
import { Button } from '../../shared/components/Button/Button';
import { TextField } from '../../shared/components/TextField/TextField';
import { LoginForm, PasswordButton, StyledLogin } from './Login.styled';
import { LoginFormData } from './Login.types';

const inputErrors = ['Bitte geben Sie vollständige Zugangsdaten ein', 'Die Zugangsdaten sind falsch.'];

export const Login = (): JSX.Element => {
    const { setUserToken, user } = useAuthContext();
    const navigate = useNavigate();

    const [passwordDialog, setPasswordDialog] = useState<boolean>(false); 

    useEffect(() => {
        if (user?.id) {
            navigate('/dashboard');
        }
    }, [user]); //eslint-disable-line

    const {
        handleSubmit,
        register,
        formState: { errors },
        setError,
    } = useForm<LoginFormData>();

    const [logIn] = useMutation(LOGIN, {
        onCompleted: (data) => {
            // console.log('data', data);
            setUserToken(data?.logIn?.accessToken);
        },
        onError: (err) => {
            if (err.message && inputErrors.includes(err.message)) {
                ['email', 'password'].forEach((field) => {
                    setError(field as 'email' | 'password', {
                        ...err,
                    });
                });
            }
        },
    });

    const onSubmit = (data: LoginFormData) => {
        logIn({ variables: { email: data.email, password: data.password } });
    };

    return (
        <>
            <StyledLogin>
                <LoginForm onSubmit={handleSubmit(onSubmit)} noValidate>
                    <TextField
                        {...register('email', { required: errorMessages.required })}
                        label="User"
                        type="email"
                        placeholder="Username"
                        textAlign="center"
                        error={!!errors?.email}
                        errorMessage={errors?.email?.message}
                    />
                    <TextField
                        {...register('password', { required: errorMessages.required })}
                        label="Passwort"
                        type="password"
                        placeholder="**************"
                        textAlign="center"
                        error={!!errors?.password}
                        errorMessage={errors?.password?.message}
                    />
                    <Button variant="text" color="primary" type="submit" size="large">
                        Enter
                    </Button>
                    <div style={{ marginTop: '2rem' }}>
                        <PasswordButton onClick={()=> setPasswordDialog(true)}>Passwort vergessen</PasswordButton>
                    </div>
                </LoginForm>
            </StyledLogin>
            <PasswordDialog open={passwordDialog} setDialog={setPasswordDialog} />
        </>
    );
};
