import { gql } from '@apollo/client';

export const GET_HEADER_CUSTOMER = gql`
    query GET_HEADER_CUSTOMER($customerId: ID!) {
        customer(customerId: $customerId) {
            id
            name
            company {
                id
                name
            }
        }
    }
`;

export const GET_HEADER_CATEGORY = gql`
    query GET_HEADER_CATEGORY($categoryId: ID!) {
        category(categoryId: $categoryId) {
            id
            name
        }
    }
`;
