import { gql } from '@apollo/client';

export const CURRENT_USER = gql`
    query CURRENT_USER {
        currentUser {
            id
            name
            email
            active
            role
        }
    }
`;
