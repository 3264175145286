import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const StyledMenu = styled.div`
    height: 100%;
    flex: 1 1 auto;
    width: 100%;
    max-width: 315px;
    padding-top: 45px;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-wrap: nowrap;
    user-select: none;

    ${theme.breakpoints.down('lg')} {
        border-top: none;
    }

    .logout {
        margin-top: 3rem;
        font-size: 1rem !important;
    }
`;

export const UserInfo = styled.span`
    text-transform: uppercase;
    font-family: 'Tungsten A', 'Tungsten B' !important;
    font-size: 2.35rem !important;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textPrimary};

    ::before {
        content: 'User_';
        color: ${({ theme }) => theme.colors.lightGrey};
    }

    align-self: ${({ mobile }) => (mobile ? 'flex-start' : 'inherit')};
    margin-left: ${({ mobile }) => (mobile ? '8px' : '0')};
`;

export const LoginInfo = styled.span`
    text-transform: uppercase;
    font-family: 'Tungsten A', 'Tungsten B' !important;
    font-size: 2.35rem !important;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary};

    ::before {
        content: 'Login_';
        color: ${({ theme }) => theme.colors.primary};
    }
`;

export const MenuItem = styled.span`
    color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
    font-size: 1.1rem !important;
    font-weight: 300;
    width: max-content;
    margin-left: ${({ mobile }) => (mobile ? '8px' : '4.4rem')};
    margin-top: 0.25rem;
    align-self: ${({ mobile }) => (mobile ? 'flex-start' : 'inherit')};

    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }
`;
