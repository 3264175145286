import styled from 'styled-components';
import { theme } from 'utils/theme/theme';
import '../../utils/theme/fonts.scss';

export const Layout = styled.div`
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    * {
        font-family: 'Tungsten A', 'Tungsten B';
        font-size: 32px;
        box-sizing: border-box;
        vertical-align: baseline;
    }

    input {
        font-family: 'Gotham SSm A', 'Gotham SSm B';
        font-size: 24px;
        font-weight: 200;
    }

    select {
        font-family: 'Gotham SSm A', 'Gotham SSm B';
        font-size: 24px;
        font-weight: 200;
    }

    span {
        font-family: 'Gotham SSm A', 'Gotham SSm B';
        font-size: 24px;
    }

    button {
        font-family: 'Gotham SSm A', 'Gotham SSm B';
        font-size: 24px;
        font-weight: 200;
    }

    tspan {
        font-size: 46px;
    }
`;
