import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'shared/components';
import { HexagonDark } from 'shared/components/Hexagon/HexagonDark';
import { SectionLabel } from 'shared/components/SectionLabel/SectionLabel.styled';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import AutomationIcon from '../../assets/img/automation-icon.svg';
import InformationManagementIcon from '../../assets/img/information-management-icon.svg';
import InfrastructureIcon from '../../assets/img/infrastructure-icon.svg';
import MobileIcon from '../../assets/img/mobile-icon.svg';
import ProductivityIcon from '../../assets/img/productivity-icon.svg';
import SecurityIcon from '../../assets/img/security-icon.svg';
import ArrowCircleRight from '../../assets/img/arrow-circle-right.svg';
import { GET_CUSTOMER, GET_FILE_EXPORT } from './CustomerDetails.query';
import {
    CustomerDetailsContainer,
    CustomerDetailsWrapper,
    DetailsSection,
    DetailsSectionItem,
    DetailsSectionItemDetails,
    DetailsSectionLabel,
    DetailsSectionProgress,
    ExportButtonLabel,
    MobileCustomerDetailsContainer,
    MobileDetailsButtonWrapper,
    MobileDetailsItem,
    MobileDetailsItemLabel,
    MobileDetailsItemProgress,
    MobileLabelProgressWrapper,
} from './CustomerDetails.styled';
import { CircularLoader } from 'shared/components/CircularLoader/CircularLoader';

const categoryPositions = {
    Produktivität: 'top-left',
    Security: 'top-right',
    '(Prozess-) Automatisierung': 'left',
    Infrastruktur: 'right',
    'Mobiles Arbeiten': 'bottom-left',
    Informationsmanagement: 'bottom-right',
};

const positionCategoryIds = {
    'top-left': 3,
    'top-right': 2,
    left: 6,
    right: 1,
    'bottom-left': 4,
    'bottom-right': 5,
};

const formatPercentage = (progress: number) => (progress === 0 ? '' : progress < 10 ? `0${progress}%` : `${progress}%`);

export const CustomerDetails = (): JSX.Element => {
    const [hoveredSectionId, setHoveredSectionId] = useState<string | null>(null);
    const [completedSections, setCompletedSections] = useState<Record<string, any>>({});
    const [mobileCompletedSections, setMobileCompletedSections] = useState<Record<string, any>>({});
    const [incompleteSections, setIncompleteSections] = useState<Record<string, any>>({});
    const [mobileIncompleteSections, setMobileIncompleteSections] = useState<Record<string, any>>({});
    const [loadingExport, setLoadingExport] = useState<boolean>(false);
    const [loadingSummary, setLoadingSummary] = useState<boolean>(false);

    const navigate = useNavigate();
    const params = useParams();
    const tabletView = useMediaQuery(theme.breakpoints.down('md'));
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

    const { data: queryData, loading: queryLoading } = useQuery(GET_CUSTOMER, {
        variables: { customerId: params.customerId },
    });
    const [getFileExport] = useLazyQuery(GET_FILE_EXPORT, { variables: { customerId: params.customerId } });

    useEffect(() => {
        if (!queryLoading) {
            if (queryData?.customer?.check?.categories) {
                if (!mobileView && !tabletView) {
                    const completed = {};
                    const incomplete = {};
                    queryData.customer.check.categories.forEach((category) => {
                        if (category.questions?.every((question) => !!question?.answer)) {
                            completed[categoryPositions[category.name]] = 100;
                        } else {
                            const answeredQuestions = category.questions.filter((question) => !!question.answer);
                            const percentageAnswered = !answeredQuestions.length
                                ? 0
                                : Math.ceil((answeredQuestions.length / category.questions.length) * 100);
                            incomplete[categoryPositions[category.name]] = percentageAnswered;
                        }
                    });
                    setCompletedSections(completed);
                    setIncompleteSections(incomplete);
                } else {
                    const mobileCompleted = {};
                    const mobileIncomplete = {};

                    queryData.customer.check.categories.forEach((category) => {
                        if (category.questions?.every((question) => !!question?.answer)) {
                            mobileCompleted[category.name] = 100;
                        } else {
                            const answeredQuestions = category.questions.filter((question) => !!question.answer);
                            const percentageAnswered = !answeredQuestions.length
                                ? 0
                                : Math.ceil((answeredQuestions.length / category.questions.length) * 100);
                            mobileIncomplete[category.name] = percentageAnswered;
                        }
                    });
                    setMobileCompletedSections(mobileCompleted);
                    setMobileIncompleteSections(mobileIncomplete);
                }
            }
        }
    }, [queryData, queryLoading, mobileView, tabletView]);

    useEffect(() => {
        Object.keys(completedSections).forEach((cS) => {
            const el = document.getElementById(`hexagon-dark-${cS}`);
            el?.setAttribute('orig-fill', theme.colors.iconGreen);
            el?.setAttribute('fill', theme.colors.iconGreen);
        });

        Object.keys(incompleteSections).forEach((iS) => {
            const el = document.getElementById(`hexagon-dark-${iS}`);
            el?.setAttribute('fill', theme.colors.iconRed);
        });
    }, [completedSections, incompleteSections]);

    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>, hexagonTriangleId?: string) => {
        setHoveredSectionId(hexagonTriangleId ?? e.currentTarget.id);
    };

    const handleMouseLeave = () => {
        if (hoveredSectionId) {
            const hexagonSection = document.getElementById(`hexagon-dark-${hoveredSectionId}`);
            const origFill = hexagonSection?.getAttribute('orig-fill');
            if (origFill) {
                hexagonSection?.setAttribute('fill', origFill);
            }
        }
        setHoveredSectionId(null);
    };



    useEffect(() => {
        if (hoveredSectionId) {
            const hexagonSection = document.getElementById(`hexagon-dark-${hoveredSectionId}`);
              hexagonSection?.setAttribute('fill', theme.colors.primary);
        }
    }, [hoveredSectionId]);

    const redirectToSection = (e, hexagonTargetId): void => {
        const categoryId = hexagonTargetId
            ? positionCategoryIds[hexagonTargetId]
            : positionCategoryIds[e.currentTarget.id];

        navigate(`../questionnaire/${categoryId}`);
    };

    const redirectToSummary = (): void => {
        navigate(`../summary`);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
    };

    const downloadFileExport = async (): Promise<void> => {
        setLoadingExport(true);
        const { data } = await getFileExport();

        const blob = new Blob([s2ab(atob(data.fileExport))], { type: 'application/octet-stream' });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `Export_${queryData?.customer?.company.name?.trim()}_${queryData?.customer?.name?.trim()}.xlsx`,
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setLoadingExport(false);
    };

    const downloadSummary = async () => {
        setLoadingSummary(true);
    };

    return mobileView || tabletView ? (
        <MobileCustomerDetailsContainer>
            {queryLoading ? (
                <CircularLoader thick size="150" />
            ) : (
                <>
                    <MobileDetailsItem
                        id="top-left"
                        onClick={redirectToSection}
                        complete={Object.keys(mobileCompletedSections).includes('Produktivität')}
                        incomplete={Object.keys(mobileIncompleteSections).includes('Produktivität')}
                    >
                        <ProductivityIcon
                            className="mobileDetailsIcon"
                            height={tabletView && !mobileView ? '60' : '40'}
                            width={tabletView && !mobileView ? '60' : '40'}
                            viewBox="0 0 35 35"
                        />
                        <MobileLabelProgressWrapper>
                            <MobileDetailsItemLabel>Produktivität</MobileDetailsItemLabel>

                            {Object.keys(mobileCompletedSections).includes('Produktivität') && (
                                <MobileDetailsItemProgress>100%</MobileDetailsItemProgress>
                            )}
                            {Object.keys(mobileIncompleteSections).includes('Produktivität') && (
                                <MobileDetailsItemProgress>
                                    {formatPercentage(mobileIncompleteSections['Produktivität'])}
                                </MobileDetailsItemProgress>
                            )}
                        </MobileLabelProgressWrapper>
                        <ArrowCircleRight
                            style={{ marginRight: 0, marginLeft: 'auto' }}
                            height={tabletView && !mobileView ? '60' : '40'}
                            width={tabletView && !mobileView ? '60' : '40'}
                            weight="fill"
                        />
                    </MobileDetailsItem>
                    <MobileDetailsItem
                        id="left"
                        onClick={redirectToSection}
                        complete={Object.keys(mobileCompletedSections).includes('(Prozess-) Automatisierung')}
                        incomplete={Object.keys(mobileIncompleteSections).includes('(Prozess-) Automatisierung')}
                    >
                        <AutomationIcon
                            className="mobileDetailsIcon"
                            height={tabletView && !mobileView ? '55' : '35'}
                            width={tabletView && !mobileView ? '55' : '35'}
                            viewBox="0 0 80 80"
                        />
                        <MobileLabelProgressWrapper>
                            <MobileDetailsItemLabel>Automatisierung</MobileDetailsItemLabel>

                            {Object.keys(mobileCompletedSections).includes('(Prozess-) Automatisierung') && (
                                <MobileDetailsItemProgress>100%</MobileDetailsItemProgress>
                            )}
                            {Object.keys(mobileIncompleteSections).includes('(Prozess-) Automatisierung') && (
                                <MobileDetailsItemProgress>
                                    {formatPercentage(mobileIncompleteSections['(Prozess-) Automatisierung'])}
                                </MobileDetailsItemProgress>
                            )}
                        </MobileLabelProgressWrapper>
                        <ArrowCircleRight
                            style={{ marginRight: 0, marginLeft: 'auto' }}
                            height={tabletView && !mobileView ? '60' : '40'}
                            width={tabletView && !mobileView ? '60' : '40'}
                            weight="fill"
                        />
                    </MobileDetailsItem>
                    <MobileDetailsItem
                        id="bottom-left"
                        onClick={redirectToSection}
                        complete={Object.keys(mobileCompletedSections).includes('Mobiles Arbeiten')}
                        incomplete={Object.keys(mobileIncompleteSections).includes('Mobiles Arbeiten')}
                    >
                        <MobileIcon
                            className="mobileDetailsIcon"
                            height={tabletView && !mobileView ? '60' : '40'}
                            width={tabletView && !mobileView ? '60' : '40'}
                            viewBox="-10 0 80 80"
                        />
                        <MobileLabelProgressWrapper>
                            <MobileDetailsItemLabel>Mobiles Arbeiten</MobileDetailsItemLabel>

                            {Object.keys(mobileCompletedSections).includes('Mobiles Arbeiten') && (
                                <MobileDetailsItemProgress>100%</MobileDetailsItemProgress>
                            )}
                            {Object.keys(mobileIncompleteSections).includes('Mobiles Arbeiten') && (
                                <MobileDetailsItemProgress>
                                    {formatPercentage(mobileIncompleteSections['Mobiles Arbeiten'])}
                                </MobileDetailsItemProgress>
                            )}
                        </MobileLabelProgressWrapper>
                        <ArrowCircleRight
                            style={{ marginRight: 0, marginLeft: 'auto' }}
                            height={tabletView && !mobileView ? '60' : '40'}
                            width={tabletView && !mobileView ? '60' : '40'}
                            weight="fill"
                        />
                    </MobileDetailsItem>
                    <MobileDetailsItem
                        id="top-right"
                        onClick={redirectToSection}
                        complete={Object.keys(mobileCompletedSections).includes('Security')}
                        incomplete={Object.keys(mobileIncompleteSections).includes('Security')}
                    >
                        <SecurityIcon
                            className="mobileDetailsIcon"
                            height={tabletView && !mobileView ? '60' : '40'}
                            width={tabletView && !mobileView ? '60' : '40'}
                            viewBox="-5 0 80 80"
                        />
                        <MobileLabelProgressWrapper>
                            <MobileDetailsItemLabel>Security</MobileDetailsItemLabel>

                            {Object.keys(mobileCompletedSections).includes('Security') && (
                                <MobileDetailsItemProgress>100%</MobileDetailsItemProgress>
                            )}
                            {Object.keys(mobileIncompleteSections).includes('Security') && (
                                <MobileDetailsItemProgress>
                                    {formatPercentage(mobileIncompleteSections['Security'])}
                                </MobileDetailsItemProgress>
                            )}
                        </MobileLabelProgressWrapper>
                        <ArrowCircleRight
                            style={{ marginRight: 0, marginLeft: 'auto' }}
                            height={tabletView && !mobileView ? '60' : '40'}
                            width={tabletView && !mobileView ? '60' : '40'}
                            weight="fill"
                        />
                    </MobileDetailsItem>
                    <MobileDetailsItem
                        id="right"
                        onClick={redirectToSection}
                        complete={Object.keys(mobileCompletedSections).includes('Infrastruktur')}
                        incomplete={Object.keys(mobileIncompleteSections).includes('Infrastruktur')}
                    >
                        <InfrastructureIcon
                            className="mobileDetailsIcon"
                            height={tabletView && !mobileView ? '60' : '40'}
                            width={tabletView && !mobileView ? '60' : '40'}
                            viewBox="5 0 80 80"
                        />
                        <MobileLabelProgressWrapper>
                            <MobileDetailsItemLabel>Infrastruktur</MobileDetailsItemLabel>

                            {Object.keys(mobileCompletedSections).includes('Infrastruktur') && (
                                <MobileDetailsItemProgress>100%</MobileDetailsItemProgress>
                            )}
                            {Object.keys(mobileIncompleteSections).includes('Infrastruktur') && (
                                <MobileDetailsItemProgress>
                                    {formatPercentage(mobileIncompleteSections['Infrastruktur'])}
                                </MobileDetailsItemProgress>
                            )}
                        </MobileLabelProgressWrapper>
                        <ArrowCircleRight
                            style={{ marginRight: 0, marginLeft: 'auto' }}
                            height={tabletView && !mobileView ? '60' : '40'}
                            width={tabletView && !mobileView ? '60' : '40'}
                            weight="fill"
                        />
                    </MobileDetailsItem>
                    <MobileDetailsItem
                        id="bottom-right"
                        onClick={redirectToSection}
                        complete={Object.keys(mobileCompletedSections).includes('Informationsmanagement')}
                        incomplete={Object.keys(mobileIncompleteSections).includes('Informationsmanagement')}
                    >
                        <InformationManagementIcon
                            className="mobileDetailsIcon"
                            height={tabletView && !mobileView ? '60' : '40'}
                            width={tabletView && !mobileView ? '60' : '40'}
                            viewBox="-5 0 80 80"
                        />
                        <MobileLabelProgressWrapper>
                            <MobileDetailsItemLabel>Informationsmanagement</MobileDetailsItemLabel>

                            {Object.keys(mobileCompletedSections).includes('Informationsmanagement') && (
                                <MobileDetailsItemProgress>100%</MobileDetailsItemProgress>
                            )}
                            {Object.keys(mobileIncompleteSections).includes('Informationsmanagement') && (
                                <MobileDetailsItemProgress>
                                    {formatPercentage(mobileIncompleteSections['Informationsmanagement'])}
                                </MobileDetailsItemProgress>
                            )}
                        </MobileLabelProgressWrapper>
                        <ArrowCircleRight
                            style={{ marginRight: 0, marginLeft: 'auto' }}
                            height={tabletView && !mobileView ? '60' : '40'}
                            width={tabletView && !mobileView ? '60' : '40'}
                            weight="fill"
                        />
                    </MobileDetailsItem>
                    <MobileDetailsButtonWrapper>
                        <Button
                            withArrowRight
                            rounded
                            variant="contained"
                            color="primary"
                            textColor="secondary"
                            size="xtra-small"
                            onClick={redirectToSummary}
                        >
                            Zur Gesamtauswertung
                        </Button>
                        <Button
                            rounded
                            variant="contained"
                            color="secondary"
                            textColor="secondary"
                            size="xtra-small"
                            onClick={downloadFileExport}
                        >
                            Export herunterladen
                        </Button>
                    </MobileDetailsButtonWrapper>
                </>
            )}
        </MobileCustomerDetailsContainer>
    ) : (
        <CustomerDetailsContainer>
            {queryLoading ? (
                <CircularLoader thick size="200" />
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <CustomerDetailsWrapper>
                        <DetailsSection align="right">
                            <DetailsSectionItem
                                id="top-left"
                                hovered={hoveredSectionId === 'top-left'}
                                complete={Object.keys(completedSections).includes('top-left')}
                                incomplete={Object.keys(incompleteSections).includes('top-left')}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onClick={redirectToSection}
                                style={{ transform: 'translate(130px, -50px)' }}
                            >
                                <DetailsSectionItemDetails className="details--left" align="right">
                                    <DetailsSectionLabel>Produktivität</DetailsSectionLabel>
                                    {Object.keys(completedSections).includes('top-left') && (
                                        <DetailsSectionProgress>100%</DetailsSectionProgress>
                                    )}
                                    {Object.keys(incompleteSections).includes('top-left') && (
                                        <DetailsSectionProgress>
                                            {formatPercentage(incompleteSections['top-left'])}
                                        </DetailsSectionProgress>
                                    )}
                                </DetailsSectionItemDetails>
                                <ProductivityIcon height="80" width="80" viewBox="0 0 35 35" />
                            </DetailsSectionItem>
                            <DetailsSectionItem
                                complete={Object.keys(completedSections).includes('left')}
                                incomplete={Object.keys(incompleteSections).includes('left')}
                                id="left"
                                hovered={hoveredSectionId === 'left'}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onClick={redirectToSection}
                            >
                                <DetailsSectionItemDetails className="details--left" align="right">
                                    <DetailsSectionLabel>Automatisierung</DetailsSectionLabel>
                                    {Object.keys(completedSections).includes('left') && (
                                        <DetailsSectionProgress>100%</DetailsSectionProgress>
                                    )}
                                    {Object.keys(incompleteSections).includes('left') && (
                                        <DetailsSectionProgress>
                                            {formatPercentage(incompleteSections['left'])}
                                        </DetailsSectionProgress>
                                    )}
                                </DetailsSectionItemDetails>
                                <AutomationIcon height="80" width="80" viewBox="0 0 80 80" />
                            </DetailsSectionItem>
                            <DetailsSectionItem
                                id="bottom-left"
                                hovered={hoveredSectionId === 'bottom-left'}
                                complete={Object.keys(completedSections).includes('bottom-left')}
                                incomplete={Object.keys(incompleteSections).includes('bottom-left')}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onClick={redirectToSection}
                                style={{ transform: 'translate(130px, 60px)' }}
                            >
                                <DetailsSectionItemDetails className="details--left" align="right">
                                    <DetailsSectionLabel>Mobiles Arbeiten</DetailsSectionLabel>
                                    {Object.keys(completedSections).includes('bottom-left') && (
                                        <DetailsSectionProgress>100%</DetailsSectionProgress>
                                    )}
                                    {Object.keys(incompleteSections).includes('bottom-left') && (
                                        <DetailsSectionProgress>
                                            {formatPercentage(incompleteSections['bottom-left'])}
                                        </DetailsSectionProgress>
                                    )}
                                </DetailsSectionItemDetails>
                                <MobileIcon height="80" width="80" viewBox="0 0 80 80" />
                            </DetailsSectionItem>
                        </DetailsSection>
                        <HexagonDark
                            onClick={redirectToSection}
                            handleMouseEnter={handleMouseEnter}
                            handleMouseLeave={handleMouseLeave}
                            height="320"
                            width="300"
                        />
                        <DetailsSection align="left">
                            <DetailsSectionItem
                                id="top-right"
                                hovered={hoveredSectionId === 'top-right'}
                                complete={Object.keys(completedSections).includes('top-right')}
                                incomplete={Object.keys(incompleteSections).includes('top-right')}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onClick={redirectToSection}
                                style={{ transform: 'translate(-140px, -50px)' }}
                            >
                                <SecurityIcon height="80" width="80" viewBox="-20 0 80 80" />
                                <DetailsSectionItemDetails className="details--right" align="left">
                                    <DetailsSectionLabel>Security</DetailsSectionLabel>
                                    {Object.keys(completedSections).includes('top-right') && (
                                        <DetailsSectionProgress>100%</DetailsSectionProgress>
                                    )}
                                    {Object.keys(incompleteSections).includes('top-right') && (
                                        <DetailsSectionProgress>
                                            {formatPercentage(incompleteSections['top-right'])}
                                        </DetailsSectionProgress>
                                    )}
                                </DetailsSectionItemDetails>
                            </DetailsSectionItem>
                            <DetailsSectionItem
                                id="right"
                                hovered={hoveredSectionId === 'right'}
                                complete={Object.keys(completedSections).includes('right')}
                                incomplete={Object.keys(incompleteSections).includes('right')}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onClick={redirectToSection}
                            >
                                <InfrastructureIcon height="80" width="80" viewBox="0 0 80 80" />
                                <DetailsSectionItemDetails className="details--right" align="left">
                                    <DetailsSectionLabel>Infrastruktur</DetailsSectionLabel>
                                    {Object.keys(completedSections).includes('right') && (
                                        <DetailsSectionProgress>100%</DetailsSectionProgress>
                                    )}
                                    {Object.keys(incompleteSections).includes('right') && (
                                        <DetailsSectionProgress>
                                            {formatPercentage(incompleteSections['right'])}
                                        </DetailsSectionProgress>
                                    )}
                                </DetailsSectionItemDetails>
                            </DetailsSectionItem>
                            <DetailsSectionItem
                                id="bottom-right"
                                hovered={hoveredSectionId === 'bottom-right'}
                                complete={Object.keys(completedSections).includes('bottom-right')}
                                incomplete={Object.keys(incompleteSections).includes('bottom-right')}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onClick={redirectToSection}
                                style={{ transform: 'translate(-130px, 60px)' }}
                            >
                                <InformationManagementIcon height="80" width="80" viewBox="0 0 80 80" />
                                <DetailsSectionItemDetails className="details--right" align="left">
                                    <DetailsSectionLabel>
                                        Informations-
                                        <br />
                                        management
                                    </DetailsSectionLabel>
                                    {Object.keys(completedSections).includes('bottom-right') && (
                                        <DetailsSectionProgress>100%</DetailsSectionProgress>
                                    )}
                                    {Object.keys(incompleteSections).includes('bottom-right') && (
                                        <DetailsSectionProgress>
                                            {formatPercentage(incompleteSections['bottom-right'])}
                                        </DetailsSectionProgress>
                                    )}
                                </DetailsSectionItemDetails>
                            </DetailsSectionItem>
                        </DetailsSection>
                    </CustomerDetailsWrapper>
                    {!!queryData?.customer?.check?.categories?.length && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                marginTop: 'auto',
                            }}
                        >
                            <Button
                                style={{ marginRight: 20 }}
                                size="xtra-small"
                                variant="contained"
                                withArrowRight
                                onClick={redirectToSummary}
                                textColor="white"
                                rounded
                            >
                                Zur Gesamtauswertung
                            </Button>
                            <Button
                                size="xtra-small"
                                variant="contained"
                                onClick={downloadFileExport}
                                textColor="white"
                                color="secondary"
                                rounded
                            >
                                <ExportButtonLabel style={{ opacity: loadingExport ? 0 : 1 }}>
                                    Export herunterladen
                                </ExportButtonLabel>
                                <ExportButtonLabel
                                    style={{
                                        position: 'absolute',
                                        opacity: loadingExport ? 1 : 0,
                                        whiteSpace: 'nowrap',
                                        left: 0,
                                        right: 0,
                                    }}
                                >
                                    Wird generiert...
                                </ExportButtonLabel>
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </CustomerDetailsContainer>
    );
};
