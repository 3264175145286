export const MenuIcon = ({ height = '192', width = '192', style, clickable, onClick }): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="#000000"
            viewBox="0 0 256 256"
            style={{ ...style, cursor: clickable ? 'pointer' : 'default' }}
            onClick={onClick}
        >
            <rect width="256" height="256" fill="none"></rect>
            <line
                x1="40"
                y1="128"
                x2="216"
                y2="128"
                stroke="#C00000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
            ></line>
            <line
                x1="40"
                y1="64"
                x2="216"
                y2="64"
                stroke="#C00000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
            ></line>
            <line
                x1="40"
                y1="192"
                x2="216"
                y2="192"
                stroke="#C00000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
            ></line>
        </svg>
    );
};
