export const AnswerHexagon = ({ height = '214', width = '214' }): JSX.Element => {
    return (
        <svg height={height} viewBox="0 0 214 214" width={width} xmlns="http://www.w3.org/2000/svg">
            <clipPath id="a">
                <path d="m0 0h214v214h-214z" />
            </clipPath>
            <g clipPath="url(#a)">
                <path d="m126.18 162.291-92.723-53.47v106.979z" fill="#ccc" transform="translate(-19.457 -55.351)" />
                <path d="m154.457 162.291 92.72-53.47v106.979z" fill="#b3b3b3" transform="translate(-47.733 -55.351)" />
                <path
                    d="m154.457 178.6 92.72 53.512-92.722 53.513z"
                    fill="#999"
                    transform="translate(-47.733 -71.657)"
                />
                <path
                    d="m154.457 145.985 92.72-53.47-92.722-53.47z"
                    fill="#ccc"
                    transform="translate(-47.733 -39.045)"
                />
                <path
                    d="m126.18 178.6-92.723 53.509 92.722 53.513z"
                    fill="#7f7f7f"
                    transform="translate(-19.457 -71.657)"
                />
                <path
                    d="m126.18 145.985-92.723-53.47 92.722-53.47z"
                    fill="#e6e6e6"
                    transform="translate(-19.457 -39.045)"
                />
            </g>
        </svg>
    );
};
