import styled from 'styled-components';
import { WizardStepProps, WizardStepSeparatorProps } from './Wizard.types';

export const WizardContainer = styled.div`
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: max-content;
    height: 44px;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    user-select: none;
`;

export const WizardStep = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem !important;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme, done }) => (done ? theme.colors.primary : theme.colors.textPrimary)};
    color: ${({ theme, active, done, answered }) =>
        active ? theme.colors.primary : done ? '#fff' : answered ? theme.colors.primary : theme.colors.textPrimary};
    background-color: ${({ theme, done }) => (done ? theme.colors.primary : '#fff')};
    flex-shrink: 0;
    flex-grow: 0;
    z-index: 15;
    transition: background-color 0.3s linear, color 0.3s linear;
    position: relative;
    cursor: ${({ answered }) => (answered ? 'pointer' : 'auto')};

    ::after {
        content: '${({ step }) => `Frage ${step}`}';
        opacity: ${({ active }) => (active ? 1 : 0)};
        position: absolute;
        right: -75px;
        bottom: -10px;
        font-size: 0.9rem !important;
        text-transform: uppercase;
        font-family: 'Gotham SSm A', 'Gotham SSm B' !important;
        width: max-content;
        color: ${({ theme }) => theme.colors.primary};
        transition: opacity 0.3s linear;
    }
`;

export const WizardStepSeparator = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.textPrimary};
`;

export const WizardStepIndicator = styled.div`
    position: absolute;
    top: 50%;
    transform: ${({ positionCurrentStep }) => `translateY(-50%) translateX(${positionCurrentStep}px)`};
    left: -4px;
    width: 48px;
    height: 48px;
    border-width: 2px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    background-color: #fff;
    z-index: 10;
    transition: transform 0.3s linear;

    ::before {
        content: '';
        display: block;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.primary};
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-100%);
        left: 0;
        width: ${({ positionCurrentStep }) => `${positionCurrentStep - 5 < 0 ? 0 : positionCurrentStep - 5}px`};
        transition: width 0.3s linear;
    }
`;
