import { useQuery } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Button, SectionLabel } from 'shared/components';
import { CircularLoader } from 'shared/components/CircularLoader/CircularLoader';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import { GET_CUSTOMERS } from './CustomersList.query';
import {
    CustomersList,
    CustomersListContainer,
    CustomersListdDeleteItem,
    CustomersListInnerWrapper,
    CustomersListItem,
    CustomersListWrapper,
    SectionLabelWrapper,
} from './CustomersList.styled';
import TrashIcon from '../../assets/img/trash.svg';
import { DeleteCustomerDialog } from 'components/DeleteCustomerDialog/DeleteCustomerDialog';
import { useState } from 'react';



export const CustomersListView = (): JSX.Element => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [currentCustomer, setCurrentCustomer] = useState<any>(null);
    const navigate = useNavigate();
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));
    const tabletView = useMediaQuery(theme.breakpoints.down('lg'));
    const xlView = useMediaQuery(theme.breakpoints.up('xl'));

    const { data, loading, refetch: refetchList } = useQuery(GET_CUSTOMERS);

    const redirectToCustomerCreation = () => {
        navigate('new');
    };

    const redirectToCustomerDetails = (customerId: number) => {
        navigate(`${customerId}/overview`);
    };

    const closeDialog = (submitted?: boolean) => {
        setDeleteDialogOpen(false);
        if (submitted) {
            refetchList();
        }
    };

    const openDialog = (customer: any) => {
        setCurrentCustomer(customer);
        setDeleteDialogOpen(true);
    };

    return (
        <>
            <CustomersListContainer>
                {!tabletView && (
                    <SectionLabelWrapper>
                        <SectionLabel>Kundenliste</SectionLabel>
                    </SectionLabelWrapper>
                )}
                {loading ? (
                    <CircularLoader thick size="200" />
                ) : (
                    <CustomersListWrapper>
                        <CustomersListInnerWrapper>
                            <CustomersList>
                                {data?.customers?.map((customer, i): any => (
                                    <>
                                        <CustomersListItem key={`${i} ${customer.id}`}>
                                            {' '}
                                            <span onClick={() => redirectToCustomerDetails(customer.id)}>
                                                {customer.company.name} / Erstellungsdatum {formatCreatedDate(customer)}{' '}
                                                / {customer.checkProgress}%{' '}
                                            </span>
                                            <CustomersListdDeleteItem
                                                onClick={(e: MouseEvent) => {
                                                    openDialog(customer);
                                                }}
                                            >
                                                <TrashIcon class="trash-icon" width="1.4rem" height="1.6rem" />
                                            </CustomersListdDeleteItem>
                                        </CustomersListItem>
                                    </>
                                ))}
                            </CustomersList>
                            <Button
                                variant="text"
                                size={tabletView ? (mobileView ? 'small' : 'medium') : 'large'}
                                color="primary"
                                onClick={redirectToCustomerCreation}
                            >
                                Kunden erstellen
                            </Button>
                        </CustomersListInnerWrapper>
                    </CustomersListWrapper>
                )}
            </CustomersListContainer>
            <DeleteCustomerDialog
                id={currentCustomer?.id}
                customerName={`${currentCustomer?.company.name} / Erstellungsdatum ${formatCreatedDate(
                    currentCustomer,
                )}`}
                onClose={closeDialog}
                open={deleteDialogOpen}
            ></DeleteCustomerDialog>
        </>
    );
};

function formatCreatedDate(customer: any) {
    if (!customer) {
        return '';
    }
    return format(parseISO(customer.created_at), 'dd-MM-yyyy');
}

