import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const SuccessDialogBackground = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${({ open }: any) => (open ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    z-index: 500;
`;

export const SuccessDialogContainer = styled.div`
    height: auto;
    width: 600px;
    padding: 2rem;
    padding-top: 1rem;
    background-color: ${({ theme }) => theme.colors.primary};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 85vw;
        padding: 1rem;
    }
`;

export const SuccessDialogButtons = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
`;

export const SuccessMessage = styled.div`
    color: ${({ theme })=> theme.colors.textSecondary};
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
 

    >span{
        margin: .5rem 0;
        font-size: 1.2rem;
    }
`;


