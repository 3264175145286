import { createContext, useEffect, useState } from 'react';
import { AuthContext as IAuthContext, AuthProviderProps, User } from './AuthContext.types';
import jwtDecode from 'jwt-decode';
import { useLazyQuery } from '@apollo/client';
import { CURRENT_USER } from './AuthContext.query';
import { client } from '../ApolloClient';

const defaultContext: IAuthContext = {
    loadingAuth: true,
    user: null,
    isAdmin: false,
    isReporter: false,
    setUserToken: (token: string) => undefined,
    logoutUser: () => undefined,
};

export const AuthContext = createContext<IAuthContext>(defaultContext);

const isValidToken = (token: string) => {
    if (!token) return false;

    let decodedToken: string;

    try {
        decodedToken = jwtDecode(token);
    } catch {
        return false;
    }

    const isTokenExpired = decodedToken?.exp - Date.now() / 1000 < 0;

    if (isTokenExpired) return false;

    return true;
};

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const [loadingAuth, setLoadingAuth] = useState<boolean>(defaultContext.loadingAuth);
    const [user, setUser] = useState<User | null>(defaultContext.user);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isReporter, setIsReporter] = useState<boolean>(false)
    const [token, setToken] = useState<string | null>(null);

    const [fetchUser, { loading: queryLoading, data: queryData, called: queryCalled }] = useLazyQuery(CURRENT_USER, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data?.currentUser) {
                if (
                    data?.currentUser.role.toLowerCase() === 'admin' ||
                    data?.currentUser.role.toLowerCase() === 'superadmin'
                ) {
                    setIsAdmin(true);
                    setUser(data.currentUser);
                } else if (data?.currentUser.role.toLowerCase() === 'reporter') {
                    setUser(data.currentUser);
                    setIsReporter(true);
                } else {
                    setUser(data.currentUser);
                    setIsAdmin(false);
                    setIsReporter(false);
                }
            } else {
                resetUser();
            }

            setLoadingAuth(false);
        },
        onError: (err) => {
            console.error('query err: ', err);
        },
    });

    const setUserToken = (token: string) => {
        if (isValidToken(token)) setToken(token);
    };

    const resetUser = () => {
        localStorage.removeItem('auth-token');
        setToken(null);
        setUser(null);
        setIsAdmin(false)
        setIsReporter(false)
        setLoadingAuth(false);

    };

    const logoutUser = () => {
        resetUser();
        client.clearStore();
    };

    useEffect(() => {
        if (token === null) {
            const localToken = localStorage.getItem('auth-token') || '';
            if (isValidToken(localToken)) {
                setToken(localToken);
            } else {
                resetUser();
            }
        }

        if (token) {
            if (isValidToken(token)) {
                localStorage.setItem('auth-token', token);
                fetchUser();
            } else {
                resetUser();
            }
        }
    }, [token]); //eslint-disable-line

    return (
        <AuthContext.Provider value={{ loadingAuth, user, setUserToken, logoutUser, isAdmin, isReporter }}>
            {children}
        </AuthContext.Provider>
    );
};
