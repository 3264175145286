import styled from "styled-components";

interface SurveyWizardProps{
 activated?: boolean;
 width?:number;
}

interface SurveyParagraphProps{
    size?: string;
    color?: string;
    weight?: string;
}


export const SurveyParagrapgh = styled.p<SurveyParagraphProps>`
font-weight:${({weight}) => weight ? weight : '400'};
font-size:${({size})=> size ? size : '1rem'};
line-height: 24px;
margin-bottom:2rem;

${({ theme }) => theme.breakpoints.down('md')} {
        margin-bottom: 2rem;
    }

`;

export const SurveyIntroWrapper = styled.div`
width:100%;
max-width: 740px;
display:flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
text-align: center;
padding:0 2.5rem;
${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 0 1.5rem;
    }
`;

export const SurveyOuter= styled.div`
 width: 100%;
 height:100vh;
 display: flex;
 justify-content: center;
 align-items: center;


`;

export const SurveyInner= styled.div`
    width:100%;
    padding:2rem 4.25rem 0;
    position:relative;
    //padding:4rem

    ${({ theme }) => theme.breakpoints.down('lg')} {
        padding: 2rem 2.5rem 0 ;
    }
    
    ${({ theme }) => theme.breakpoints.down('md')} {
        // ! before padding: 0 1.5rem 0 ;
        padding: 0 2rem
    }

  
`;

export const SurveyInnerInner = styled.div`
width:100%;
padding-top:1.5rem;
margin-top: auto;
margin-bottom: auto;

${({ theme }) => theme.breakpoints.down('sm')} {
    // ! last added ask lennart about this
    //margin-top:0;
    }


`;

export const SurveyContainer= styled.div`
 position:relative;
 width: calc(100% - 8.5rem);
 border-left:3px solid ${({theme})=> theme.colors.primary};
 min-height: calc(100vh - 12rem);
 display: flex;
flex-direction: column;

 ${({ theme }) => theme.breakpoints.down('md')} {
    min-height: calc(100vh - 10rem);
    width:100%;
    }
`;

export const SideBarWrapper = styled.div`
position:absolute;
right:4.25rem;
top:50px;

 ${({ theme }) => theme.breakpoints.down('md')} {
        display:none !important;
    }

${({ theme }) => theme.breakpoints.down('lg')} {
        position:absolute;
        right:45px;
        top:50px;
    }
`;

export const SurveyHeader= styled.div`
display:flex;
align-items: center;
width:100%;
position:absolute;
top:-5px;
left:-25px;

${({ theme }) => theme.breakpoints.down('md')} {
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;
    left:-19.5px;
    top:-15px;
};


`;

export const SurveyStep = styled.div`
    width:48px;
    height:48px;
    font-size: 40px;
    font-family: 'Tungsten A','Tungsten B' !important;
    display: flex;
    justify-content: center;
    align-items:center;
    line-height: 1;
    font-weight: 500;
    
    background-color: ${({theme})=> theme.colors.primary};
    color: ${({theme}) => theme.colors.textSecondary};
    border-radius:999px;
    z-index:1;

    ${({ theme }) => theme.breakpoints.down('md')} {
        width:34px;
        height:34px;
        border-radius: 999px;
        font-size:24px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

`;

export const StepTitle = styled.span`
 font-size:40px;
 line-height:40px;
 font-weight:500;
 margin-left: 2rem;
 font-family: 'Tungsten A', 'Tungsten B' !important;
 color: ${({theme})=> theme.colors.textBlack};

${({ theme }) => theme.breakpoints.down('md')} {
     font-size:30px;
     line-height:inherit;
     font-weight:500;
     margin-left: 0.5rem;
     font-family: 'Tungsten A', 'Tungsten B';
     color: ${({theme})=> theme.colors.textBlack};
    };

`;


export const SurveyFormWrapper=styled.div`
 width:100%;
 padding-left: 3.5rem;
 padding-bottom: 3.8rem;
 display: flex;
 flex-direction: column;
    height:100%;
    flex: 1 1 auto;

    ${({ theme }) => theme.breakpoints.down('md')} {
   width:100%;
   padding-left:1.6rem;
   padding-bottom:2.6rem
};
`;

export const ButtonContainer= styled.div`
    width:98%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5rem;
    // margin-top: auto;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        flex-direction: column-reverse;
        padding:0;
        margin-bottom:0rem;
        margin-top:2rem;
        //margin-top:0rem;
        height:100px;
        width:100%;
    };
   
`;

export const SurveyWizard= styled.div`
    display:flex;
    justify-content: flex-start;
    align-items: center;
    position:relative;
    width:100%;
    margin-left:0.5rem;
    position:absolute;
    left:-25px;
    bottom:-10px;
`;

export const SurveyWizardActiveBar= styled.span<SurveyWizardProps>`
    position:absolute;
    width: ${({width}) => `${width}%`};
    z-index: 2;
    background-color: ${({theme}) => theme.colors.primary};
    height:5px;
    transition: width 0.25s ease-in-out;
  
`

export const SurveyWizardBarGray= styled.span`
    position:absolute;
     width: 100%;
     z-index:0;
     border:2px solid #E2E4E4;

`;

export const SurveyWizardItemWrapper = styled.div`
    width:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SurveyWizardItem = styled.div<SurveyWizardProps>`
  width:34px;
  height:34px;
  border-radius: 999px;
  font-family: 'Tungsten A', 'Tungsten B' !important;
  font-size:24px;
  font-weight:373;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({activated, theme}) =>  activated ? theme.colors.primary : theme.colors.textSecondary};
  color: ${({activated, theme}) => activated ? theme.colors.textSecondary : theme.colors.primary };
  border: ${({activated, theme})=> activated ?  'none' : `1px solid ${theme.colors.primary}`};
  z-index: 3;

  .span{
    padding:1rem;
  }
`;



