import { PlusIconWrapper } from './PlusIcon.styled';
import { PlusIconProps } from './PlusIcon.types';

export const PlusIcon = ({
    width = '192',
    height = '192',
    clickable = false,
    onClick = () => undefined,
    marginTop = 0,
    marginLeft = 0,
}: PlusIconProps): JSX.Element => {
    return (
        <PlusIconWrapper
            width={width}
            height={height}
            fill="#000000"
            viewBox="0 0 256 256"
            marginTop={marginTop}
            marginLeft={marginLeft}
            clickable={clickable}
            onClick={onClick}
        >
            <rect width="256" height="256" fill="none"></rect>
            <line
                x1="40"
                y1="128"
                x2="216"
                y2="128"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
            ></line>
            <line
                x1="128"
                y1="40"
                x2="128"
                y2="216"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
            ></line>
        </PlusIconWrapper>
    );
};
