import styled from 'styled-components';
import {
    BenchmarkIndicatorLabelProps,
    BenchmarkIndicatorProps,
    ProgressContainerProps,
    BenchmarkArrowProps,
} from './CircularProgress.types';

export const ProgressContainer = styled.div`
    position: relative;
    display: inline-block;
    width: ${({ width }: ProgressContainerProps) => `${width}px`};
    height: ${({ height }: ProgressContainerProps) => `${height}px`};
    overflow: visible;
`;

export const CircularBase = styled.svg`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const ProgressGhost = styled.circle`
    stroke: ${({ color }) => color};
    fill: transparent;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
`;

export const ProgressCircle = styled.circle`
    stroke: ${({ color }) => color};
    fill: transparent;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transform: stroke-dashoffset 0.3s ease;
`;

export const ProgressText = styled.span`
    position: absolute;
    top: 0;
    left: -40px;
    font-size: 1rem !important;
    color: ${({ color }) => color};
`;

export const ProgressHeader = styled.span``;

export const BenchmarkIndicator = styled.div`
    height: 20px;
    width: 20px;
    background-color: transparent;
    overflow: visible;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -10px;
    margin-left: -10px;
    transform: ${({ positions, progress }: BenchmarkIndicatorProps) =>
        `translate(${positions.x}px, ${positions.y}px) rotate(${270 / (100 / progress) - 90}deg)`};
`;

export const BenchmarkIndicatorLabel = styled.span`
    position: absolute;
    top: 50%;
    left: 60px;
    text-transform: uppercase;
    font-size: 1rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ color }) => color};
    transform: ${({ progress }: BenchmarkIndicatorLabelProps) =>
        `translateY(-50%) rotate(${progress >= 70 ? '180deg' : '0deg'})`};
    transform-origin: ${({ progress }: BenchmarkIndicatorLabelProps) => (progress >= 70 ? '100 16px' : '0 16px')};
    svg {
        fill: ${({ color }) => color};
    }
`;

export const BenchmarkArrow = styled.div`
    transform-origin: 50 50;
    display: ${({ progress }: BenchmarkArrowProps) => (progress >= 70 ? 'inline-flex' : 'none')};
    padding-left: 0.5rem;
    transform: rotate(0);
    &:first-child {
        display: ${({ progress }: BenchmarkArrowProps) => (progress < 70 ? 'inline-flex' : 'none')};
        transform: rotate(180deg);
        padding-left: 0.5rem;
    }
`;

export const CircularLabel = styled.span`
    position: absolute;
    top: 0;
    left: 50%;
    color: ${({ color }) => color};
    padding-right: 1rem;
    font-size: 18px !important;
    transform: translate(-100%, -36%);
`;
