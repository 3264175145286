import styled from 'styled-components';

export const AnswerPoint = ({
    height = '43.856',
    width = '37.98',
    positions,
    labelPositions,
    saveAnswer,
    answer,
    label,
}): JSX.Element => (
    <>
        <StyledAnswerPoint
            answer={answer}
            onClick={() => saveAnswer(answer)}
            positions={positions}
            height={height}
            viewBox="0 0 37.98 43.856"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m288.3 112.986v-21.351l-18.485-10.675-18.49 10.675v21.35l18.49 10.675z"
                fill="#fff"
                stroke="#000"
                transform="translate(-250.825 -80.383)"
            />
        </StyledAnswerPoint>
        <AnswerLabel labelPositions={labelPositions}>{label}</AnswerLabel>
    </>
);

const StyledAnswerPoint = styled.svg`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ positions }) => `translate(calc(-50% + ${positions.x}px), calc(-50% + ${positions.y}px))`};
    cursor: pointer;
`;

const AnswerLabel = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ labelPositions }) =>
        `translate(calc(-50% + ${labelPositions.x}px), calc(-50% + ${labelPositions.y}px))`};
    color: ${({ theme }) => theme.colors.textPrimary};
    font-family: 'Tungsten A', 'Tungsten B' !important;
    font-weight: 500;
    font-size: 1.5rem !important;
    pointer-events: none;
    user-select: none;
    text-align: center;
`;
