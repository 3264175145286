import styled from 'styled-components';

export const StyledFooter = styled.div`
    display: none;
    height: 100px;
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    border-top: 5px solid ${({ theme }) => theme.colors.primary};
    margin: 0 !important;
    background-color: #fff;

    ${({ theme }) => theme.breakpoints.down('lg')} {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
        height: 80px;
        svg {
            transform: scale(0.9);
        }
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        svg {
            transform: scale(0.7);
        }
    }
`;
