import { gql } from '@apollo/client';

export const DELETE_CUSTOMER = gql`
    mutation DELETE_CUSTOMER($id: ID!) {
        deleteCustomer(customerId: $id) {
            message
            ok
            errors
        }
    }
`;
