import { gql } from '@apollo/client';

export const GET_CUSTOMERS = gql`
    query GET_CUSTOMERS {
        customers {
            id
            checkProgress
            created_at
            company {
                id
                name
            }
        }
    }
`;
