import styled from 'styled-components';

interface SideBarCircleLabelProps{
    survey?: boolean;
    noMargin?: boolean;
    width?: string;
}

export const SidebarCircleWrapper = styled.div<SideBarCircleLabelProps>`
    position: relative;
    margin-top: '45px';
`;

export const SidebarCircleIcon = styled.div`
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
     svg {
        * {
            fill: ${({ theme }) => theme.colors.primary};
        }
    } 
`;

export const SidebarCircleLabel = styled.span`
    display: block;
`;

export const SidebarCircleScore = styled.span<SideBarCircleLabelProps>`
    color: ${({ theme }) => theme.colors.primary};
    display: block;
    line-height: 1;
    margin-top: 1.5rem;
    font-size: 2.5rem !important;
    font-weight: 700;

    ${({ survey }) =>  survey && `&::after{ content: ' %';} `}
       
    
`;

export const SidebarCircleScoreLabel = styled.span`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1rem !important;
    display: block;
`;


export const SideBarCircleSurveyWrapper = styled.div<SideBarCircleLabelProps>`
    width: ${({width}) => width ? width : '100%'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
`;

export const SidebarCircleSurveyScore = styled.span<SideBarCircleLabelProps>`
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
    margin-top: 0%.1rem;
    margin-left:1rem;
    font-size: 2.5rem !important;
    font-weight: 500;
    font-family: 'Tungsten A', 'Tungsten B' !important;

    ${({ survey }) =>  survey && `&::after{ content: '%';} `}
       
    
`;

export const SideBarCircleSurveyIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;