import styled from "styled-components";


export const SurveyCheckBoxWrapper = styled.div`
position:relative;
cursor:pointer;
padding: 0 1rem;
`;


export const SurveyCheckBoxInner= styled.div`
position:relative;
cursor:pointer;
`;


export const CheckboxInput = styled.input`
position:absolute;
left:0;
top:2px;
width:1.5rem;
height:1.5rem;
vertical-align:top;
background-color: transparent;
background-repeat: no-repeat;
background-position: center;
background-size: 12px 10px;
border: 1px solid #C00000;
background-color: white;
appearance: none;
cursor:pointer;
${({ theme }) => theme.breakpoints.down('md')} {
    width:1.25rem;
    height:1.25rem;
}
&:checked{
    cursor:pointer;
    border-color:#C00000;
    background-color: #C00000;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}

`;

export const CheckBoxLabel = styled.label`
padding-left: 2rem;
display:block;
cursor:pointer;
align-items:center;
font-weight:400;
font-size:16px;
line-height: 1.5;
color: '#000000';
a {
    color: ${({ theme }) => theme.colors.primary};
}
${({ theme }) => theme.breakpoints.down('md')} {
    font-size:12px;
    padding-left: 1.75rem;
}

`;