import { gql } from '@apollo/client';

export const LOGIN = gql`
    mutation LOGIN($email: String!, $password: String!) {
        logIn(email: $email, password: $password) {
            accessToken
        }
    }
`;


export const SAVE_QUICK_CHECK_ANSWERS= gql`
    mutation saveQuickCheckAnswers($partnerId:String!, $resultInPercent:Float $answers:QuickCheckAnswers!){
        saveQuickCheckAnswers(partnerId: $partnerId, resultInPercent:$resultInPercent, answers:$answers ){
            ok
            message
            errors
            token
        }
    }

`;


export const SEND_QUICK_CHECK_MEETING_REQUEST = gql`
    mutation sendQuickCheckMeetingRequest($partnerToken:String!, $resultToken:String!, $form:QuickCheckForm!){
        sendQuickCheckMeetingRequest(partnerToken:$partnerToken, resultToken:$resultToken, form:$form){
            ok
            message
            errors
        }
    }

`;
