import { SectionLabelWrapper } from 'components/CustomersList/CustomersList.styled';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, SectionLabel } from 'shared/components';
import { useAuthContext } from 'shared/hooks/useAuthContext';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import { AdminSettingsInner, StyledAdminSettings } from './AdminSettings.styled';

export const AdminSettings = (): JSX.Element => {
    const { isReporter , isAdmin } = useAuthContext()
    const navigate = useNavigate();
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));
    const tabletView = useMediaQuery(theme.breakpoints.down('lg'));
    const xlView = useMediaQuery(theme.breakpoints.up('xl'));

    return (
        <StyledAdminSettings>
            {!tabletView && (
                    <SectionLabelWrapper>
                        <SectionLabel>Administration</SectionLabel>
                    </SectionLabelWrapper>
                )}
            <AdminSettingsInner>
               {!isReporter && <Button
                    style={{ alignSelf: 'center' }}
                    variant="text"
                    color="primary"
                    size={mobileView ? 'small' : 'large'}
                    onClick={() => navigate('/admin/create-account')}
                >
                    Neuen Benutzer anlegen
                </Button>}
                <Button
                    style={{ alignSelf: 'center' }}
                    variant="text"
                    color="primary"
                    size={mobileView ? 'small' : 'large'}
                    onClick={() => navigate('/admin/reports')}
                >
                    Reporting erstellen
                </Button>
                {!isReporter && <Button
                    style={{ alignSelf: 'center' }}
                    variant="text"
                    color="primary"
                    size={mobileView ? 'small' : 'large'}
                    onClick={() => navigate('/admin/user-managment')}
                >
                    Benutzerverwaltung
                </Button> }
            </AdminSettingsInner>
        </StyledAdminSettings>
    );
};
