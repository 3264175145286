import { useEffect, useState } from 'react';

export const useMediaQuery = (queryInput) => {
    const query = queryInput.replace(/^@media( ?)/m, '');
    const matchMedia = window.matchMedia;

    const [match, setMatch] = useState<boolean>(() => matchMedia(query).matches);

    useEffect(() => {
        let active = true;

        const queryList = matchMedia(query);
        const updateMatch = () => {
            if (active) {
                setMatch(queryList.matches);
            }
        };

        updateMatch();
        queryList.addListener(updateMatch);
        return () => {
            active = false;
            queryList.removeListener(updateMatch);
        };
    }, [query, matchMedia]);

    return match;
};
