import { forwardRef, useEffect, useRef, useState } from 'react';
import { SelectArrow, SelectClickAway, SelectContainer, SelectError, SelectField, SelectLabel } from './Select.styled';
import { SelectProps } from './Select.types';
import CaretLeft from '../../../assets/img/caret-left.svg';

export const Select = forwardRef(
    (
        {
            label = '',
            placeholder = 'Bitte auswählen',
            options = [],
            error = false,
            errorMessage = '',
            textAlign = 'left',
            dialogRed,
            ...props
            
        }: SelectProps,
        ref,
    ) => {
        const [open, setOpen] = useState<boolean>(false);
        const clickAwayRef = useRef();


        const handleChange = (e) => {
            setOpen(false);
            props.onChange(e);
        };

        const openSelect = () => {
            setOpen(true);
        };

        const closeSelect = () => {
            setOpen(false);
        };

        useEffect(() => {
            const handleClickAway = (e) => {
                if (!clickAwayRef?.current.contains(e.target)) {
                    closeSelect();
                }
                if (clickAwayRef?.current.contains(e.target) && e.target.tagName === 'option') {
                    closeSelect();
                }
            };

            document.addEventListener('click', handleClickAway);

            return () => document.removeEventListener('click', handleClickAway);
        }, []);

        return (
            <SelectContainer open={open} dialogRed={dialogRed}>
                <SelectLabel error={error}>{label}_</SelectLabel>
                <SelectClickAway ref={clickAwayRef}>
                    <SelectField
                        {...props}
                        ref={ref}
                        onClick={open ? closeSelect : openSelect}
                        onChange={handleChange}
                        dialogRed={dialogRed}
                    >
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </SelectField>
                    <CaretLeft
                        className="selectArrow"
                        open={open}
                        height="28"
                        width="28"
                        onClick={open ? closeSelect : openSelect}
                    />
                </SelectClickAway>
                <SelectError visible={error && !!errorMessage}>{errorMessage}</SelectError>
            </SelectContainer>
        );
    },
);

Select.displayName = 'Select';
