import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from 'shared/hooks/useAuthContext';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import { MenuIcon } from '../MenuIcon/MenuIcon';
import { MobileMenu } from '../MobileMenu/MobileMenu';
import { SectionLabel } from '../SectionLabel/SectionLabel.styled';
import { GET_HEADER_CATEGORY, GET_HEADER_CUSTOMER } from './Header.query';
import { HeaderContainer, HeaderContainerCanon, HeaderContainerDigi, MobileHeaderContainer, SurveyMobileHeaderContainer } from './Header.styled';
import { DigiCheckQuickLogo } from 'shared/DigiCheckQuickLogo/DigiCheckQuickLogo';

export const Header = ({survey}): JSX.Element => {
    const { isAdmin , isReporter } = useAuthContext()
    const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
    const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
    const [pathname, setPathname] = useState<string>('');
    const [questionCategory, setQuestionCategory] = useState<string>('');
    const [questionNumber, setQuestionNumber] = useState<number | null>(null);

    const location = useLocation();

    const [getCustomer, { data: customerData }] = useLazyQuery(GET_HEADER_CUSTOMER);
    const [getCategory, { data: categoryData }] = useLazyQuery(GET_HEADER_CATEGORY);

    useEffect(() => {
        setPathname(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        if (/\/customers\/[0-9]+\/overview$/.test(pathname)) {
            const customerId = pathname.split('/')[2];

            getCustomer({ variables: { customerId } });
        }

        if (/\/customers\/[0-9]+\/summary\/[0-9]+$/.test(pathname)) {
            const categoryId = pathname.split('/')[4];

            getCategory({ variables: { categoryId } });
        }

        if (/\/customers\/[0-9]+\/summary$/.test(pathname)) {
            const customerId = pathname.split('/')[2];

            getCustomer({ variables: { customerId } });
        }
    }, [pathname, getCustomer, getCategory]);

    const openMobileMenu = () => {
        setMobileMenuOpen(true);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    const handleQuestionChange = (e: Event) => {
        const eventDetail = (e as CustomEvent)?.detail;
        setQuestionCategory(eventDetail?.category ?? '');
        setQuestionNumber(eventDetail?.number ?? null);
    };

    useEffect(() => {
        window.addEventListener('question-change', handleQuestionChange);

        return () => {
            window.removeEventListener('question-change', handleQuestionChange);
        };
    }, []);

    return mobileView ? (
        survey ? (
            <>
                <SurveyMobileHeaderContainer>
                    <DigiCheckQuickLogo width="200"/>
                </SurveyMobileHeaderContainer>
            </>
        ) : (
        <>
            <MobileHeaderContainer>
                <SectionLabel header red>
                    {/\/dashboard$/.test(pathname) && 'Dashboard'}
                    {/\/admin$/.test(pathname) && (isAdmin || isReporter) && 'Administration'}
                    {/\/admin\/create-account$/.test(pathname) && (isAdmin) && 'Neuen Benutzer anlegen'}
                    {/\/admin\/reports$/.test(pathname) && (isAdmin || isReporter) && 'Reporting erstellen'}
                    {/\/admin\/user-managment$/.test(pathname) && (isAdmin) && 'Benutzerverwaltung'}
                    
                    {/\/customers$/.test(pathname) && 'Kundenliste'}
                    {/\/customers\/new$/.test(pathname) && 'Kunden anlegen'}
                    {/\/customers\/[0-9]+\/overview$/.test(pathname) &&
                        `Übersicht: ${customerData?.customer?.company?.name ?? ''}, ${
                            customerData?.customer?.name ?? ''
                        }`}
                    {/\/customers\/[0-9]+\/summary\/[0-9]+$/.test(pathname) &&
                        `Auswertung: ${categoryData?.category?.name ?? ''}`}
                    {/\/customers\/[0-9]+\/summary$/.test(pathname) &&
                        `Entwicklungsfelder: ${customerData?.customer?.company?.name ?? ''}, ${
                            customerData?.customer?.name ?? ''
                        }`}
                    {/\/customers\/[0-9]+\/questionnaire\/[0-9]+$/.test(pathname) &&
                        `
                        ${questionCategory === '(Prozess-) Automatisierung' ? 'Automatisierung' : questionCategory} | ${
                                questionNumber !== null
                                    ? questionNumber > 0
                                        ? `Frage ${questionNumber}`
                                        : 'Einleitung'
                                    : ''
                            }
                    `}
                        {/\/reset-password/.test(pathname) && 'Passwort zurücksetzen'}
                    </SectionLabel>
                    <MenuIcon
                        height="40"
                        width="40"
                        style={{ marginLeft: 'auto', position: 'relative' }}
                        clickable
                        onClick={openMobileMenu}
                    />
                </MobileHeaderContainer>
                <MobileMenu onClose={closeMobileMenu} open={mobileMenuOpen} />
            </>
        )
    ) : (
        <HeaderContainer>
            <HeaderContainerCanon>
                <svg height="37" viewBox="0 0 130 37" width="130">
                    <path d="m0 0h130v37h-130z" fill="#fff" />
                    <g fill="#c00000" transform="translate(0 4.774)">
                        <path d="m21.477 24.079a10.911 10.911 0 0 1 -11.077-10.739 10.911 10.911 0 0 1 11.077-10.74 11.279 11.279 0 0 1 5.869 1.63l-5.869 9.11 11.05-6.186a14.72 14.72 0 0 0 -5.009-4.667 21.111 21.111 0 0 0 -10.403-2.487c-5.815 0-10.915 2.045-13.759 5.112a12.055 12.055 0 0 0 -3.356 8.228 12.054 12.054 0 0 0 3.356 8.228c2.852 3.075 7.875 5.112 13.591 5.112s10.737-2.04 13.591-5.112q.234-.253.454-.513l-.433-1.567a11.167 11.167 0 0 1 -9.082 4.591" />
                        <path
                            d="m111.114 34.185-4.635-17.063a6.532 6.532 0 0 0 -6.321-4.784 6.611 6.611 0 0 0 -2.239.388l-9.983 3.584h10.264l1.756 6.462a9.766 9.766 0 0 0 -6.346-2.325c-4.819 0-8.725 3.186-8.725 7.117s3.906 7.116 8.725 7.116a10.433 10.433 0 0 0 8.427-4.246l1.018 3.75zm-14.144-2.649a3.972 3.972 0 1 1 4.03-3.971 3.972 3.972 0 0 1 -4.03 3.971"
                            transform="translate(-53.477 -8.165)"
                        />
                        <path
                            d="m169.142 12.339a4.052 4.052 0 0 0 -1.7.372l-6.407 2.947a4.011 4.011 0 0 0 -3.972-3.319 4.052 4.052 0 0 0 -1.7.372l-7.827 3.6h5.5v17.874h8.054v-15.885a2.014 2.014 0 0 1 4.027 0v15.885h8.053v-17.874a4 4 0 0 0 -4.027-3.972"
                            transform="translate(-92.936 -8.166)"
                        />
                        <path
                            d="m303.623 12.339a4.055 4.055 0 0 0 -1.7.372l-6.407 2.947a4.012 4.012 0 0 0 -3.972-3.319 4.057 4.057 0 0 0 -1.7.372l-7.827 3.6h5.5v17.874h8.054v-15.885a2.014 2.014 0 1 1 4.027 0v15.885h8.053v-17.874a4 4 0 0 0 -4.027-3.972"
                            transform="translate(-177.651 -8.166)"
                        />
                        <path
                            d="m247.551 23.593a11.411 11.411 0 1 1 -11.41-11.254 11.411 11.411 0 0 1 11.41 11.254m-10.933-7.836a2.521 2.521 0 0 0 -3.083-1.757 2.479 2.479 0 0 0 -1.78 3.041l3.908 14.388a2.52 2.52 0 0 0 3.083 1.756 2.479 2.479 0 0 0 1.78-3.041z"
                            transform="translate(-141.535 -8.166)"
                        />
                    </g>
                </svg>
            </HeaderContainerCanon>
            <HeaderContainerDigi>
                {survey && (
                    <svg width="103" height="40" viewBox="0 0 103 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 24.7999V0.210763H5.6611C9.31796 0.210763 11.0409 1.96713 11.0409 5.58524V19.4254C11.0409 23.0435 9.31796 24.7999 5.6611 24.7999H0ZM3.76234 3.51273V21.4979H5.13367C6.61047 21.4979 7.27855 20.7953 7.27855 19.32V5.69062C7.27855 4.21527 6.61047 3.51273 5.13367 3.51273H3.76234Z"
                            fill="#C00000"
                        />
                        <path
                            d="M13.1146 3.89913V0.421528H16.8067V3.89913H13.1146ZM13.185 24.7999V5.51498H16.7363V24.7999H13.185Z"
                            fill="#C00000"
                        />
                        <path
                            d="M29.0453 5.51498V24.6945C29.0453 27.5047 27.7091 28.8044 24.8961 28.8044H22.6106C20.0789 28.8044 18.8834 27.6452 18.8834 25.116V23.9919H22.1183V24.835C22.1183 25.5727 22.47 25.9942 23.2084 25.9942H24.439C25.1774 25.9942 25.5291 25.5727 25.5291 24.835V21.6033C24.6148 22.3761 23.56 22.9381 22.1887 22.9381H22.1535C19.9734 22.9381 18.8131 21.5681 18.8131 19.32V8.8872C18.8131 6.63906 19.9734 5.30422 22.1535 5.30422H22.1887C23.6655 5.30422 24.7203 5.97164 25.5291 6.67418V5.51498H29.0453ZM25.4939 19.1795V9.16822C24.9665 8.78182 24.3335 8.53593 23.6303 8.53593C22.7864 8.53593 22.3645 8.8872 22.3645 9.73026V18.5823C22.3645 19.4254 22.7864 19.7767 23.6303 19.7767C24.3335 19.7767 24.9665 19.5308 25.4939 19.1795Z"
                            fill="#C00000"
                        />
                        <path
                            d="M31.1077 3.89913V0.421528H34.7998V3.89913H31.1077ZM31.1781 24.7999V5.51498H34.7294V24.7999H31.1781Z"
                            fill="#C00000"
                        />
                        <path
                            d="M47.841 14.7183H51.533V20.4441C51.533 23.5001 50.021 25.0106 46.9619 25.0106H45.0983C42.0392 25.0106 40.5273 23.5001 40.5273 20.4441V4.56655C40.5273 1.51047 42.0392 0 45.0983 0H46.9268C49.9859 0 51.4627 1.47535 51.4627 4.53142V9.55462H47.7706V4.74218C47.7706 3.75862 47.3135 3.30196 46.3642 3.30196H45.7312C44.7467 3.30196 44.2896 3.75862 44.2896 4.74218V20.2684C44.2896 21.252 44.7467 21.7087 45.7312 21.7087H46.4345C47.3839 21.7087 47.841 21.252 47.841 20.2684V14.7183Z"
                            fill="black"
                        />
                        <path
                            d="M57.4084 10.5733H61.0301V0.210763H64.7924V24.7999H61.0301V13.9807H57.4084V24.7999H53.646V0.210763H57.4084V10.5733Z"
                            fill="black"
                        />
                        <path
                            d="M67.0378 0.210763H75.4064V3.61811H70.8002V10.5733H75.2306V13.9807H70.8002V21.3925H75.4767V24.7999H67.0378V0.210763Z"
                            fill="black"
                        />
                        <path
                            d="M84.72 14.7183H88.412V20.4441C88.412 23.5001 86.9 25.0106 83.8409 25.0106H81.9773C78.9182 25.0106 77.4062 23.5001 77.4062 20.4441V4.56655C77.4062 1.51047 78.9182 0 81.9773 0H83.8058C86.8649 0 88.3417 1.47535 88.3417 4.53142V9.55462H84.6496V4.74218C84.6496 3.75862 84.1925 3.30196 83.2432 3.30196H82.6102C81.6257 3.30196 81.1686 3.75862 81.1686 4.74218V20.2684C81.1686 21.252 81.6257 21.7087 82.6102 21.7087H83.3135C84.2629 21.7087 84.72 21.252 84.72 20.2684V14.7183Z"
                            fill="black"
                        />
                        <path
                            d="M101.988 24.7999H97.9442L94.2874 12.7512V24.7999H90.525V0.210763H94.2874V12.0838L98.1903 0.210763H102.129L97.909 12.2945L101.988 24.7999Z"
                            fill="black"
                        />
                        <path
                            d="M72.5879 37.0533L74.5391 38.7642L74.0469 39.2213L72.1074 37.5279L72.5879 37.0533ZM74.6445 33.0045V33.9185C74.6445 34.5162 74.5664 35.0572 74.4102 35.5416C74.2539 36.022 74.0312 36.4341 73.7422 36.7779C73.4531 37.1216 73.1035 37.3853 72.6934 37.5689C72.2871 37.7525 71.832 37.8443 71.3281 37.8443C70.832 37.8443 70.3789 37.7525 69.9688 37.5689C69.5625 37.3853 69.2109 37.1216 68.9141 36.7779C68.6211 36.4341 68.3945 36.022 68.2344 35.5416C68.0781 35.0572 68 34.5162 68 33.9185V33.0045C68 32.4068 68.0781 31.8677 68.2344 31.3873C68.3945 30.9029 68.6211 30.4888 68.9141 30.1451C69.207 29.8013 69.5566 29.5377 69.9629 29.3541C70.3691 29.1705 70.8203 29.0787 71.3164 29.0787C71.8203 29.0787 72.2754 29.1705 72.6816 29.3541C73.0918 29.5377 73.4434 29.8013 73.7363 30.1451C74.0293 30.4888 74.2539 30.9029 74.4102 31.3873C74.5664 31.8677 74.6445 32.4068 74.6445 33.0045ZM73.9297 33.9185V32.9927C73.9297 32.4888 73.8711 32.0357 73.7539 31.6334C73.6367 31.231 73.4648 30.8873 73.2383 30.6021C73.0156 30.317 72.7422 30.0982 72.418 29.9459C72.0977 29.7935 71.7305 29.7173 71.3164 29.7173C70.9141 29.7173 70.5527 29.7935 70.2324 29.9459C69.9121 30.0982 69.6387 30.317 69.4121 30.6021C69.1855 30.8873 69.0117 31.231 68.8906 31.6334C68.7734 32.0357 68.7148 32.4888 68.7148 32.9927V33.9185C68.7148 34.4263 68.7754 34.8834 68.8965 35.2896C69.0176 35.692 69.1914 36.0377 69.418 36.3267C69.6445 36.6119 69.918 36.8306 70.2383 36.983C70.5625 37.1353 70.9258 37.2115 71.3281 37.2115C71.7422 37.2115 72.1094 37.1353 72.4297 36.983C72.7539 36.8306 73.0273 36.6119 73.25 36.3267C73.4727 36.0377 73.6406 35.692 73.7539 35.2896C73.8711 34.8834 73.9297 34.4263 73.9297 33.9185Z"
                            fill="black"
                        />
                        <path
                            d="M81.6406 29.1959H82.3613V34.9732C82.3613 35.6138 82.2266 36.147 81.957 36.5728C81.6875 36.9986 81.3242 37.317 80.8672 37.5279C80.4141 37.7388 79.9121 37.8443 79.3613 37.8443C78.7949 37.8443 78.2852 37.7388 77.832 37.5279C77.3789 37.317 77.0195 36.9986 76.7539 36.5728C76.4922 36.147 76.3613 35.6138 76.3613 34.9732V29.1959H77.0762V34.9732C77.0762 35.4693 77.1738 35.8853 77.3691 36.2213C77.5645 36.5572 77.834 36.8091 78.1777 36.9771C78.5215 37.1451 78.916 37.2291 79.3613 37.2291C79.8027 37.2291 80.1953 37.1451 80.5391 36.9771C80.8828 36.8091 81.1523 36.5572 81.3477 36.2213C81.543 35.8853 81.6406 35.4693 81.6406 34.9732V29.1959Z"
                            fill="black"
                        />
                        <path d="M85.2676 29.1959V37.7271H84.5469V29.1959H85.2676Z" fill="black" />
                        <path
                            d="M92.9023 35.067H93.623C93.5645 35.6412 93.4062 36.1373 93.1484 36.5552C92.8906 36.9693 92.5391 37.2877 92.0938 37.5103C91.6484 37.733 91.1113 37.8443 90.4824 37.8443C89.998 37.8443 89.5586 37.7525 89.1641 37.5689C88.7734 37.3853 88.4375 37.1255 88.1562 36.7896C87.875 36.4498 87.6582 36.0435 87.5059 35.5709C87.3535 35.0982 87.2773 34.5728 87.2773 33.9947V32.9283C87.2773 32.3502 87.3535 31.8267 87.5059 31.358C87.6582 30.8853 87.877 30.4791 88.1621 30.1392C88.4473 29.7994 88.7891 29.5377 89.1875 29.3541C89.5859 29.1705 90.0352 29.0787 90.5352 29.0787C91.1406 29.0787 91.6641 29.19 92.1055 29.4127C92.5469 29.6314 92.8945 29.9478 93.1484 30.3619C93.4062 30.7759 93.5645 31.2759 93.623 31.8619H92.9023C92.8477 31.4009 92.7266 31.0103 92.5391 30.69C92.3516 30.3697 92.0918 30.1255 91.7598 29.9576C91.4316 29.7857 91.0234 29.6998 90.5352 29.6998C90.1367 29.6998 89.7812 29.7759 89.4688 29.9283C89.1562 30.0806 88.8906 30.2994 88.6719 30.5845C88.4531 30.8658 88.2852 31.2037 88.168 31.5982C88.0547 31.9927 87.998 32.4322 87.998 32.9166V33.9947C87.998 34.4634 88.0527 34.8951 88.1621 35.2896C88.2715 35.6841 88.4316 36.0259 88.6426 36.315C88.8574 36.6041 89.1191 36.8287 89.4277 36.9888C89.7363 37.149 90.0879 37.2291 90.4824 37.2291C90.9863 37.2291 91.4062 37.149 91.7422 36.9888C92.082 36.8248 92.3457 36.5826 92.5332 36.2623C92.7207 35.942 92.8438 35.5435 92.9023 35.067Z"
                            fill="black"
                        />
                        <path
                            d="M96.1016 29.1959V37.7271H95.3809V29.1959H96.1016ZM101.375 29.1959L97.6953 33.1041L95.8438 34.9263L95.7734 34.1236L97.2617 32.5767L100.473 29.1959H101.375ZM100.777 37.7271L97.1855 33.3443L97.6191 32.7642L101.65 37.7271H100.777Z"
                            fill="black"
                        />
                    </svg>
                )}

                {!survey && (
                    <svg height="37" viewBox="0 0 134 37" width="134">
                        <path d="m0 0h134v37h-134z" fill="#fff" />
                        <path
                            d="m1.472-.619v-31.581h7.406q7.038 0 7.038 6.9v17.778q0 6.9-7.038 6.9zm4.922-27.34v23.1h1.794a2.476 2.476 0 0 0 2.806-2.8v-17.5a2.476 2.476 0 0 0 -2.806-2.8zm12.236.5v-4.421h4.83v4.421zm.092 26.84v-24.769h4.646v24.769zm20.746-24.769v24.633q0 5.279-5.428 5.279h-2.99q-4.876 0-4.876-4.737v-1.444h4.232v1.083q0 1.489 1.426 1.489h1.61q1.426 0 1.426-1.489v-4.151a6.757 6.757 0 0 1 -4.368 1.715h-.046a4.2 4.2 0 0 1 -3.243-1.218 4.861 4.861 0 0 1 -1.127-3.429v-13.354a4.861 4.861 0 0 1 1.127-3.429 4.2 4.2 0 0 1 3.243-1.218h.046a6.66 6.66 0 0 1 4.37 1.76v-1.489zm-4.646 17.55v-12.862a4.155 4.155 0 0 0 -2.438-.812q-1.656 0-1.656 1.534v11.378q0 1.534 1.656 1.534a4.32 4.32 0 0 0 2.438-.772zm7.314-19.625v-4.421h4.83v4.421zm.092 26.844v-24.769h4.646v24.769z"
                            fill="#c00000"
                            transform="translate(-1.472 32.476)"
                        />
                        <path
                            d="m16.79-13.626h4.83v7.333q0 5.849-5.98 5.849h-2.44q-5.98 0-5.98-5.849v-20.334q0-5.849 5.98-5.849h2.392q5.934 0 5.934 5.8v6.433h-4.826v-6.157a1.628 1.628 0 0 0 -1.84-1.845h-.83a1.649 1.649 0 0 0 -1.886 1.845v19.883a1.649 1.649 0 0 0 1.886 1.844h.92a1.628 1.628 0 0 0 1.84-1.845zm12.51-5.308h4.74v-13.272h4.922v31.492h-4.922v-13.856h-4.74v13.856h-4.92v-31.492h4.92zm12.6-13.272h10.954v4.364h-6.026v8.908h5.8v4.364h-5.8v9.493h6.118v4.363h-11.04zm23.138 18.58h4.83v7.333q0 5.849-5.98 5.849h-2.432q-5.98 0-5.98-5.849v-20.334q0-5.849 5.98-5.849h2.392q5.934 0 5.934 5.8v6.433h-4.83v-6.157a1.628 1.628 0 0 0 -1.84-1.845h-.828a1.649 1.649 0 0 0 -1.884 1.845v19.883a1.649 1.649 0 0 0 1.886 1.845h.92a1.628 1.628 0 0 0 1.84-1.845zm22.592 12.912h-5.29l-4.784-15.431v15.431h-4.922v-31.492h4.922v15.206l5.106-15.206h5.152l-5.52 15.476z"
                            transform="translate(46.186 32.476)"
                        />
                    </svg>
                )}
            </HeaderContainerDigi>
        </HeaderContainer>
    );
};
