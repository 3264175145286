import React from 'react'
import { DigiCheckLogoWrapper } from './DigiCheckQuickLogo.styled'
export const DigiCheckQuickLogo = ({width, height, surveyPage}: any) => {


  if(surveyPage){
    return (
    <DigiCheckLogoWrapper>
      <svg width="495" height="83" viewBox="0 0 495 83" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M33.0372 57.7952C30.8727 57.8104 28.7264 57.3995 26.7209 56.5859C24.7154 55.7724 22.8899 54.5722 21.3488 53.0538C19.8076 51.5354 18.5809 49.7286 17.7388 47.7366C16.8966 45.7446 16.4555 43.6064 16.4406 41.444C16.4557 39.2818 16.897 37.1437 17.7392 35.1519C18.5814 33.16 19.8081 31.3534 21.3493 29.8352C22.8904 28.317 24.7158 27.1169 26.7212 26.3035C28.7267 25.49 30.8728 25.0792 33.0372 25.0944C36.1424 25.088 39.1878 25.9474 41.8307 27.5761L33.0372 41.444L49.5933 32.0246C47.6862 29.0988 45.1149 26.6638 42.0884 24.9178C37.312 22.3198 31.9388 21.0143 26.5016 21.1308C17.7891 21.1308 10.1478 24.2442 5.88661 28.9142C2.69253 32.3103 0.897101 36.7843 0.858337 41.444C0.89733 46.1033 2.69276 50.5767 5.88661 53.9723C10.1597 58.6543 17.6857 61.7557 26.2499 61.7557C34.8142 61.7557 42.3371 58.6499 46.6132 53.9723C46.847 53.7159 47.0737 53.4554 47.2934 53.191L46.6447 50.8051C45.1017 52.9727 43.0606 54.7388 40.6928 55.9551C38.325 57.1715 35.6996 57.8025 33.0372 57.7952Z" fill="#C00000"/>
          <path d="M87.9182 60.7678L80.9736 35.2278C80.3993 33.1619 79.1612 31.342 77.4499 30.0481C75.7386 28.7542 73.649 28.0581 71.5029 28.067C70.3595 28.0657 69.2245 28.2622 68.1482 28.6478L53.1907 34.0124H68.5692L71.2002 43.6847C68.5464 41.4312 65.175 40.1972 61.6921 40.2047C54.4718 40.2047 48.6194 44.9735 48.6194 50.8575C48.6194 56.7414 54.4718 61.5088 61.6921 61.5088C64.1485 61.515 66.5718 60.9428 68.7654 59.8386C70.9591 58.7344 72.8615 57.1292 74.3182 55.1533L75.8434 60.7663L87.9182 60.7678ZM66.7263 56.8028C65.5531 56.7857 64.4111 56.4225 63.4439 55.7588C62.4766 55.0951 61.7274 54.1606 61.2903 53.0728C60.8532 51.9849 60.7478 50.7923 60.9873 49.6447C61.2267 48.4972 61.8004 47.446 62.6362 46.6232C63.472 45.8005 64.5326 45.2429 65.6847 45.0206C66.8368 44.7984 68.0289 44.9213 69.1113 45.3739C70.1937 45.8266 71.1179 46.5888 71.7679 47.5647C72.4179 48.5406 72.7646 49.6867 72.7644 50.859C72.7534 52.4466 72.1115 53.9649 70.98 55.0798C69.8485 56.1947 68.3201 56.815 66.7308 56.8043" fill="#C00000"/>
          <path d="M116.252 28.067C115.373 28.0662 114.504 28.2561 113.705 28.6238L104.106 33.0349C103.861 31.6381 103.129 30.3729 102.04 29.4636C100.95 28.5543 99.574 28.0596 98.1545 28.067C97.2752 28.0662 96.4061 28.2561 95.6074 28.6238L83.8803 34.0124H92.1209V60.7663H104.188V36.9895C104.206 36.2018 104.531 35.4523 105.095 34.9014C105.659 34.3506 106.416 34.0421 107.205 34.0421C107.994 34.0421 108.751 34.3506 109.315 34.9014C109.879 35.4523 110.204 36.2018 110.222 36.9895V60.7663H122.288V34.0124C122.276 32.4247 121.635 30.9064 120.503 29.7915C119.372 28.6766 117.843 28.0563 116.254 28.067" fill="#C00000"/>
          <path d="M191.924 28.067C191.044 28.0663 190.175 28.2563 189.377 28.6238L179.777 33.0349C179.532 31.6382 178.8 30.3732 177.711 29.464C176.621 28.5547 175.245 28.0599 173.826 28.067C172.946 28.0664 172.077 28.2564 171.279 28.6238L159.552 34.0124H167.792V60.7663H179.859V36.9895C179.877 36.2018 180.203 35.4523 180.766 34.9014C181.33 34.3506 182.088 34.0421 182.876 34.0421C183.665 34.0421 184.422 34.3506 184.986 34.9014C185.55 35.4523 185.875 36.2018 185.893 36.9895V60.7663H197.959V34.0124C197.948 32.4247 197.306 30.9064 196.174 29.7915C195.043 28.6766 193.514 28.0563 191.925 28.067" fill="#C00000"/>
          <path d="M161.504 44.9121C161.551 48.2999 160.588 51.6251 158.737 54.4646C156.887 57.3042 154.234 59.53 151.114 60.8588C147.994 62.1876 144.549 62.5595 141.217 61.9271C137.885 61.2947 134.817 59.6866 132.403 57.3074C129.988 54.9282 128.336 51.8853 127.657 48.5658C126.979 45.2462 127.303 41.7999 128.59 38.6653C129.877 35.5306 132.069 32.8491 134.885 30.9619C137.702 29.0747 141.017 28.0671 144.408 28.067C148.911 28.0364 153.242 29.7938 156.448 32.9528C159.654 36.1118 161.473 40.4137 161.504 44.9121ZM145.123 33.1831C144.855 32.225 144.22 31.4113 143.355 30.9188C142.49 30.4263 141.465 30.2949 140.504 30.5532C140.028 30.6749 139.581 30.8896 139.189 31.1849C138.797 31.4801 138.468 31.8501 138.22 32.2734C137.972 32.6966 137.81 33.1648 137.745 33.6509C137.679 34.1369 137.71 34.6312 137.837 35.105L143.692 56.6411C143.96 57.5991 144.595 58.4128 145.46 58.9051C146.325 59.3973 147.35 59.5283 148.311 59.2695C148.787 59.1478 149.234 58.9332 149.626 58.6379C150.018 58.3426 150.347 57.9727 150.595 57.5494C150.843 57.1261 151.005 56.6579 151.071 56.1719C151.136 55.6858 151.105 55.1916 150.978 54.7177L145.123 33.1831Z" fill="#C00000"/>
          <path d="M245.959 0.513184V82.8377" stroke="#4A4E52" strokeWidth="2"/>
          <path d="M293.959 51.8469V3.64963H305.055C312.223 3.64963 315.6 7.09229 315.6 14.1842V41.3124C315.6 48.4042 312.223 51.8469 305.055 51.8469H293.959ZM301.333 10.1218V45.3747H304.021C306.916 45.3747 308.226 43.9976 308.226 41.1058V14.3907C308.226 11.4989 306.916 10.1218 304.021 10.1218H301.333Z" fill="#C00000"/>
          <path d="M319.665 10.8792V4.06275H326.902V10.8792H319.665ZM319.803 51.8469V14.0465H326.764V51.8469H319.803Z" fill="#C00000"/>
          <path d="M350.891 14.0465V51.6403C350.891 57.1486 348.272 59.6962 342.758 59.6962H338.278C333.316 59.6962 330.972 57.424 330.972 52.4666V50.2633H337.313V51.9158C337.313 53.3617 338.002 54.1879 339.45 54.1879H341.862C343.309 54.1879 343.999 53.3617 343.999 51.9158V45.5813C342.207 47.096 340.139 48.1977 337.451 48.1977H337.382C333.109 48.1977 330.835 45.5124 330.835 41.1058V20.6564C330.835 16.2498 333.109 13.6334 337.382 13.6334H337.451C340.346 13.6334 342.413 14.9416 343.999 16.3186V14.0465H350.891ZM343.93 40.8304V21.2072C342.896 20.4498 341.655 19.9679 340.277 19.9679C338.623 19.9679 337.796 20.6564 337.796 22.3089V39.6599C337.796 41.3124 338.623 42.0009 340.277 42.0009C341.655 42.0009 342.896 41.5189 343.93 40.8304Z" fill="#C00000"/>
          <path d="M354.933 10.8792V4.06275H362.17V10.8792H354.933ZM355.071 51.8469V14.0465H362.032V51.8469H355.071Z" fill="#C00000"/>
          <path d="M387.732 32.086H394.969V43.3091C394.969 49.2993 392.005 52.26 386.009 52.26H382.356C376.36 52.26 373.397 49.2993 373.397 43.3091V12.1874C373.397 6.1972 376.36 3.23651 382.356 3.23651H385.94C391.936 3.23651 394.831 6.12835 394.831 12.1186V21.9646H387.594V12.5317C387.594 10.6038 386.698 9.70872 384.838 9.70872H383.597C381.667 9.70872 380.771 10.6038 380.771 12.5317V42.9648C380.771 44.8927 381.667 45.7878 383.597 45.7878H384.975C386.836 45.7878 387.732 44.8927 387.732 42.9648V32.086Z" fill="black"/>
          <path d="M406.485 23.9613H413.584V3.64963H420.959V51.8469H413.584V30.6401H406.485V51.8469H399.111V3.64963H406.485V23.9613Z" fill="black"/>
          <path d="M425.36 3.64963H441.764V10.3284H432.735V23.9613H441.419V30.6401H432.735V45.1681H441.901V51.8469H425.36V3.64963Z" fill="black"/>
          <path d="M460.019 32.086H467.256V43.3091C467.256 49.2993 464.292 52.26 458.296 52.26H454.643C448.647 52.26 445.683 49.2993 445.683 43.3091V12.1874C445.683 6.1972 448.647 3.23651 454.643 3.23651H458.227C464.223 3.23651 467.118 6.12835 467.118 12.1186V21.9646H459.881V12.5317C459.881 10.6038 458.985 9.70872 457.124 9.70872H455.884C453.954 9.70872 453.058 10.6038 453.058 12.5317V42.9648C453.058 44.8927 453.954 45.7878 455.884 45.7878H457.262C459.123 45.7878 460.019 44.8927 460.019 42.9648V32.086Z" fill="black"/>
          <path d="M493.866 51.8469H485.94L478.772 28.2302V51.8469H471.398V3.64963H478.772V26.922L486.422 3.64963H494.142L485.871 27.3351L493.866 51.8469Z" fill="black"/>
          <path d="M436.239 75.8649L440.063 79.2186L439.099 80.1144L435.297 76.7952L436.239 75.8649ZM440.27 67.9288V69.7205C440.27 70.8919 440.117 71.9524 439.811 72.9018C439.504 73.8436 439.068 74.6514 438.501 75.3251C437.935 75.9989 437.25 76.5158 436.446 76.8756C435.649 77.2355 434.757 77.4154 433.77 77.4154C432.797 77.4154 431.909 77.2355 431.105 76.8756C430.309 76.5158 429.62 75.9989 429.038 75.3251C428.464 74.6514 428.019 73.8436 427.706 72.9018C427.399 71.9524 427.246 70.8919 427.246 69.7205V67.9288C427.246 66.7573 427.399 65.7007 427.706 64.7589C428.019 63.8095 428.464 62.9979 429.038 62.3241C429.612 61.6503 430.297 61.1335 431.094 60.7736C431.89 60.4138 432.774 60.2338 433.747 60.2338C434.734 60.2338 435.626 60.4138 436.423 60.7736C437.227 61.1335 437.916 61.6503 438.49 62.3241C439.064 62.9979 439.504 63.8095 439.811 64.7589C440.117 65.7007 440.27 66.7573 440.27 67.9288ZM438.869 69.7205V67.9058C438.869 66.9181 438.754 66.0299 438.524 65.2413C438.295 64.4527 437.958 63.7789 437.514 63.2199C437.077 62.661 436.541 62.2322 435.906 61.9336C435.278 61.635 434.558 61.4857 433.747 61.4857C432.958 61.4857 432.25 61.635 431.622 61.9336C430.994 62.2322 430.458 62.661 430.014 63.2199C429.57 63.7789 429.229 64.4527 428.992 65.2413C428.762 66.0299 428.647 66.9181 428.647 67.9058V69.7205C428.647 70.7158 428.766 71.6117 429.003 72.408C429.241 73.1966 429.581 73.8742 430.026 74.4408C430.47 74.9997 431.006 75.4285 431.633 75.7271C432.269 76.0257 432.981 76.175 433.77 76.175C434.581 76.175 435.301 76.0257 435.929 75.7271C436.564 75.4285 437.1 74.9997 437.537 74.4408C437.973 73.8742 438.302 73.1966 438.524 72.408C438.754 71.6117 438.869 70.7158 438.869 69.7205Z" fill="black"/>
          <path d="M453.983 60.4635H455.396V71.7878C455.396 73.0435 455.132 74.0886 454.603 74.9232C454.075 75.7577 453.363 76.3818 452.467 76.7952C451.579 77.2087 450.595 77.4154 449.516 77.4154C448.405 77.4154 447.406 77.2087 446.518 76.7952C445.63 76.3818 444.925 75.7577 444.405 74.9232C443.892 74.0886 443.635 73.0435 443.635 71.7878V60.4635H445.036V71.7878C445.036 72.7602 445.228 73.5756 445.611 74.2341C445.993 74.8925 446.522 75.3864 447.196 75.7156C447.869 76.0449 448.643 76.2095 449.516 76.2095C450.381 76.2095 451.15 76.0449 451.824 75.7156C452.498 75.3864 453.026 74.8925 453.409 74.2341C453.792 73.5756 453.983 72.7602 453.983 71.7878V60.4635Z" fill="black"/>
          <path d="M461.092 60.4635V77.1857H459.68V60.4635H461.092Z" fill="black"/>
          <path d="M476.057 71.9715H477.47C477.355 73.0971 477.045 74.0695 476.54 74.8887C476.034 75.7003 475.345 76.3243 474.472 76.7608C473.6 77.1972 472.547 77.4154 471.314 77.4154C470.365 77.4154 469.503 77.2355 468.73 76.8756C467.964 76.5158 467.306 76.0066 466.755 75.3481C466.203 74.682 465.778 73.8857 465.48 72.9592C465.181 72.0328 465.032 71.003 465.032 69.8698V67.7795C465.032 66.6463 465.181 65.6203 465.48 64.7015C465.778 63.7751 466.207 62.9788 466.766 62.3126C467.325 61.6465 467.995 61.1335 468.776 60.7736C469.557 60.4138 470.437 60.2338 471.417 60.2338C472.604 60.2338 473.63 60.4521 474.495 60.8885C475.361 61.3173 476.042 61.9375 476.54 62.7491C477.045 63.5607 477.355 64.5407 477.47 65.6892H476.057C475.95 64.7857 475.713 64.0201 475.345 63.3922C474.978 62.7644 474.469 62.2858 473.818 61.9566C473.175 61.6197 472.375 61.4513 471.417 61.4513C470.636 61.4513 469.94 61.6006 469.327 61.8992C468.715 62.1978 468.194 62.6266 467.765 63.1855C467.336 63.7368 467.007 64.3991 466.778 65.1724C466.555 65.9457 466.444 66.8071 466.444 67.7565V69.8698C466.444 70.7886 466.552 71.6346 466.766 72.408C466.98 73.1813 467.294 73.8512 467.708 74.4178C468.129 74.9844 468.642 75.4247 469.247 75.7386C469.852 76.0525 470.541 76.2095 471.314 76.2095C472.302 76.2095 473.125 76.0525 473.783 75.7386C474.45 75.417 474.966 74.9423 475.334 74.3145C475.701 73.6866 475.943 72.9056 476.057 71.9715Z" fill="black"/>
          <path d="M482.328 60.4635V77.1857H480.916V60.4635H482.328ZM492.665 60.4635L485.452 68.124L481.823 71.6959L481.685 70.1224L484.602 67.0904L490.896 60.4635H492.665ZM491.493 77.1857L484.453 68.5949L485.303 67.4579L493.205 77.1857H491.493Z" fill="black"/>
      </svg>
    </DigiCheckLogoWrapper>)
  }

  return (
  <svg width={width ? width : '495'} height={height ? height : '83'} viewBox="0 0 495 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.0372 57.7952C30.8727 57.8104 28.7264 57.3995 26.7209 56.5859C24.7154 55.7724 22.8899 54.5722 21.3488 53.0538C19.8076 51.5354 18.5809 49.7286 17.7388 47.7366C16.8966 45.7446 16.4555 43.6064 16.4406 41.444C16.4557 39.2818 16.897 37.1437 17.7392 35.1519C18.5814 33.16 19.8081 31.3534 21.3493 29.8352C22.8904 28.317 24.7158 27.1169 26.7212 26.3035C28.7267 25.49 30.8728 25.0792 33.0372 25.0944C36.1424 25.088 39.1878 25.9474 41.8307 27.5761L33.0372 41.444L49.5933 32.0246C47.6862 29.0988 45.1149 26.6638 42.0884 24.9178C37.312 22.3198 31.9388 21.0143 26.5016 21.1308C17.7891 21.1308 10.1478 24.2442 5.88661 28.9142C2.69253 32.3103 0.897101 36.7843 0.858337 41.444C0.89733 46.1033 2.69276 50.5767 5.88661 53.9723C10.1597 58.6543 17.6857 61.7557 26.2499 61.7557C34.8142 61.7557 42.3371 58.6499 46.6132 53.9723C46.847 53.7159 47.0737 53.4554 47.2934 53.191L46.6447 50.8051C45.1017 52.9727 43.0606 54.7388 40.6928 55.9551C38.325 57.1715 35.6996 57.8025 33.0372 57.7952Z" fill="#C00000"/>
    <path d="M87.9182 60.7678L80.9736 35.2278C80.3993 33.1619 79.1612 31.342 77.4499 30.0481C75.7386 28.7542 73.649 28.0581 71.5029 28.067C70.3595 28.0657 69.2245 28.2622 68.1482 28.6478L53.1907 34.0124H68.5692L71.2002 43.6847C68.5464 41.4312 65.175 40.1972 61.6921 40.2047C54.4718 40.2047 48.6194 44.9735 48.6194 50.8575C48.6194 56.7414 54.4718 61.5088 61.6921 61.5088C64.1485 61.515 66.5718 60.9428 68.7654 59.8386C70.9591 58.7344 72.8615 57.1292 74.3182 55.1533L75.8434 60.7663L87.9182 60.7678ZM66.7263 56.8028C65.5531 56.7857 64.4111 56.4225 63.4439 55.7588C62.4766 55.0951 61.7274 54.1606 61.2903 53.0728C60.8532 51.9849 60.7478 50.7923 60.9873 49.6447C61.2267 48.4972 61.8004 47.446 62.6362 46.6232C63.472 45.8005 64.5326 45.2429 65.6847 45.0206C66.8368 44.7984 68.0289 44.9213 69.1113 45.3739C70.1937 45.8266 71.1179 46.5888 71.7679 47.5647C72.4179 48.5406 72.7646 49.6867 72.7644 50.859C72.7534 52.4466 72.1115 53.9649 70.98 55.0798C69.8485 56.1947 68.3201 56.815 66.7308 56.8043" fill="#C00000"/>
    <path d="M116.252 28.067C115.373 28.0662 114.504 28.2561 113.705 28.6238L104.106 33.0349C103.861 31.6381 103.129 30.3729 102.04 29.4636C100.95 28.5543 99.574 28.0596 98.1545 28.067C97.2752 28.0662 96.4061 28.2561 95.6074 28.6238L83.8803 34.0124H92.1209V60.7663H104.188V36.9895C104.206 36.2018 104.531 35.4523 105.095 34.9014C105.659 34.3506 106.416 34.0421 107.205 34.0421C107.994 34.0421 108.751 34.3506 109.315 34.9014C109.879 35.4523 110.204 36.2018 110.222 36.9895V60.7663H122.288V34.0124C122.276 32.4247 121.635 30.9064 120.503 29.7915C119.372 28.6766 117.843 28.0563 116.254 28.067" fill="#C00000"/>
    <path d="M191.924 28.067C191.044 28.0663 190.175 28.2563 189.377 28.6238L179.777 33.0349C179.532 31.6382 178.8 30.3732 177.711 29.464C176.621 28.5547 175.245 28.0599 173.826 28.067C172.946 28.0664 172.077 28.2564 171.279 28.6238L159.552 34.0124H167.792V60.7663H179.859V36.9895C179.877 36.2018 180.203 35.4523 180.766 34.9014C181.33 34.3506 182.088 34.0421 182.876 34.0421C183.665 34.0421 184.422 34.3506 184.986 34.9014C185.55 35.4523 185.875 36.2018 185.893 36.9895V60.7663H197.959V34.0124C197.948 32.4247 197.306 30.9064 196.174 29.7915C195.043 28.6766 193.514 28.0563 191.925 28.067" fill="#C00000"/>
    <path d="M161.504 44.9121C161.551 48.2999 160.588 51.6251 158.737 54.4646C156.887 57.3042 154.234 59.53 151.114 60.8588C147.994 62.1876 144.549 62.5595 141.217 61.9271C137.885 61.2947 134.817 59.6866 132.403 57.3074C129.988 54.9282 128.336 51.8853 127.657 48.5658C126.979 45.2462 127.303 41.7999 128.59 38.6653C129.877 35.5306 132.069 32.8491 134.885 30.9619C137.702 29.0747 141.017 28.0671 144.408 28.067C148.911 28.0364 153.242 29.7938 156.448 32.9528C159.654 36.1118 161.473 40.4137 161.504 44.9121ZM145.123 33.1831C144.855 32.225 144.22 31.4113 143.355 30.9188C142.49 30.4263 141.465 30.2949 140.504 30.5532C140.028 30.6749 139.581 30.8896 139.189 31.1849C138.797 31.4801 138.468 31.8501 138.22 32.2734C137.972 32.6966 137.81 33.1648 137.745 33.6509C137.679 34.1369 137.71 34.6312 137.837 35.105L143.692 56.6411C143.96 57.5991 144.595 58.4128 145.46 58.9051C146.325 59.3973 147.35 59.5283 148.311 59.2695C148.787 59.1478 149.234 58.9332 149.626 58.6379C150.018 58.3426 150.347 57.9727 150.595 57.5494C150.843 57.1261 151.005 56.6579 151.071 56.1719C151.136 55.6858 151.105 55.1916 150.978 54.7177L145.123 33.1831Z" fill="#C00000"/>
    <path d="M245.959 0.513184V82.8377" stroke="#4A4E52" strokeWidth="2"/>
    <path d="M293.959 51.8469V3.64963H305.055C312.223 3.64963 315.6 7.09229 315.6 14.1842V41.3124C315.6 48.4042 312.223 51.8469 305.055 51.8469H293.959ZM301.333 10.1218V45.3747H304.021C306.916 45.3747 308.226 43.9976 308.226 41.1058V14.3907C308.226 11.4989 306.916 10.1218 304.021 10.1218H301.333Z" fill="#C00000"/>
    <path d="M319.665 10.8792V4.06275H326.902V10.8792H319.665ZM319.803 51.8469V14.0465H326.764V51.8469H319.803Z" fill="#C00000"/>
    <path d="M350.891 14.0465V51.6403C350.891 57.1486 348.272 59.6962 342.758 59.6962H338.278C333.316 59.6962 330.972 57.424 330.972 52.4666V50.2633H337.313V51.9158C337.313 53.3617 338.002 54.1879 339.45 54.1879H341.862C343.309 54.1879 343.999 53.3617 343.999 51.9158V45.5813C342.207 47.096 340.139 48.1977 337.451 48.1977H337.382C333.109 48.1977 330.835 45.5124 330.835 41.1058V20.6564C330.835 16.2498 333.109 13.6334 337.382 13.6334H337.451C340.346 13.6334 342.413 14.9416 343.999 16.3186V14.0465H350.891ZM343.93 40.8304V21.2072C342.896 20.4498 341.655 19.9679 340.277 19.9679C338.623 19.9679 337.796 20.6564 337.796 22.3089V39.6599C337.796 41.3124 338.623 42.0009 340.277 42.0009C341.655 42.0009 342.896 41.5189 343.93 40.8304Z" fill="#C00000"/>
    <path d="M354.933 10.8792V4.06275H362.17V10.8792H354.933ZM355.071 51.8469V14.0465H362.032V51.8469H355.071Z" fill="#C00000"/>
    <path d="M387.732 32.086H394.969V43.3091C394.969 49.2993 392.005 52.26 386.009 52.26H382.356C376.36 52.26 373.397 49.2993 373.397 43.3091V12.1874C373.397 6.1972 376.36 3.23651 382.356 3.23651H385.94C391.936 3.23651 394.831 6.12835 394.831 12.1186V21.9646H387.594V12.5317C387.594 10.6038 386.698 9.70872 384.838 9.70872H383.597C381.667 9.70872 380.771 10.6038 380.771 12.5317V42.9648C380.771 44.8927 381.667 45.7878 383.597 45.7878H384.975C386.836 45.7878 387.732 44.8927 387.732 42.9648V32.086Z" fill="black"/>
    <path d="M406.485 23.9613H413.584V3.64963H420.959V51.8469H413.584V30.6401H406.485V51.8469H399.111V3.64963H406.485V23.9613Z" fill="black"/>
    <path d="M425.36 3.64963H441.764V10.3284H432.735V23.9613H441.419V30.6401H432.735V45.1681H441.901V51.8469H425.36V3.64963Z" fill="black"/>
    <path d="M460.019 32.086H467.256V43.3091C467.256 49.2993 464.292 52.26 458.296 52.26H454.643C448.647 52.26 445.683 49.2993 445.683 43.3091V12.1874C445.683 6.1972 448.647 3.23651 454.643 3.23651H458.227C464.223 3.23651 467.118 6.12835 467.118 12.1186V21.9646H459.881V12.5317C459.881 10.6038 458.985 9.70872 457.124 9.70872H455.884C453.954 9.70872 453.058 10.6038 453.058 12.5317V42.9648C453.058 44.8927 453.954 45.7878 455.884 45.7878H457.262C459.123 45.7878 460.019 44.8927 460.019 42.9648V32.086Z" fill="black"/>
    <path d="M493.866 51.8469H485.94L478.772 28.2302V51.8469H471.398V3.64963H478.772V26.922L486.422 3.64963H494.142L485.871 27.3351L493.866 51.8469Z" fill="black"/>
    <path d="M436.239 75.8649L440.063 79.2186L439.099 80.1144L435.297 76.7952L436.239 75.8649ZM440.27 67.9288V69.7205C440.27 70.8919 440.117 71.9524 439.811 72.9018C439.504 73.8436 439.068 74.6514 438.501 75.3251C437.935 75.9989 437.25 76.5158 436.446 76.8756C435.649 77.2355 434.757 77.4154 433.77 77.4154C432.797 77.4154 431.909 77.2355 431.105 76.8756C430.309 76.5158 429.62 75.9989 429.038 75.3251C428.464 74.6514 428.019 73.8436 427.706 72.9018C427.399 71.9524 427.246 70.8919 427.246 69.7205V67.9288C427.246 66.7573 427.399 65.7007 427.706 64.7589C428.019 63.8095 428.464 62.9979 429.038 62.3241C429.612 61.6503 430.297 61.1335 431.094 60.7736C431.89 60.4138 432.774 60.2338 433.747 60.2338C434.734 60.2338 435.626 60.4138 436.423 60.7736C437.227 61.1335 437.916 61.6503 438.49 62.3241C439.064 62.9979 439.504 63.8095 439.811 64.7589C440.117 65.7007 440.27 66.7573 440.27 67.9288ZM438.869 69.7205V67.9058C438.869 66.9181 438.754 66.0299 438.524 65.2413C438.295 64.4527 437.958 63.7789 437.514 63.2199C437.077 62.661 436.541 62.2322 435.906 61.9336C435.278 61.635 434.558 61.4857 433.747 61.4857C432.958 61.4857 432.25 61.635 431.622 61.9336C430.994 62.2322 430.458 62.661 430.014 63.2199C429.57 63.7789 429.229 64.4527 428.992 65.2413C428.762 66.0299 428.647 66.9181 428.647 67.9058V69.7205C428.647 70.7158 428.766 71.6117 429.003 72.408C429.241 73.1966 429.581 73.8742 430.026 74.4408C430.47 74.9997 431.006 75.4285 431.633 75.7271C432.269 76.0257 432.981 76.175 433.77 76.175C434.581 76.175 435.301 76.0257 435.929 75.7271C436.564 75.4285 437.1 74.9997 437.537 74.4408C437.973 73.8742 438.302 73.1966 438.524 72.408C438.754 71.6117 438.869 70.7158 438.869 69.7205Z" fill="black"/>
    <path d="M453.983 60.4635H455.396V71.7878C455.396 73.0435 455.132 74.0886 454.603 74.9232C454.075 75.7577 453.363 76.3818 452.467 76.7952C451.579 77.2087 450.595 77.4154 449.516 77.4154C448.405 77.4154 447.406 77.2087 446.518 76.7952C445.63 76.3818 444.925 75.7577 444.405 74.9232C443.892 74.0886 443.635 73.0435 443.635 71.7878V60.4635H445.036V71.7878C445.036 72.7602 445.228 73.5756 445.611 74.2341C445.993 74.8925 446.522 75.3864 447.196 75.7156C447.869 76.0449 448.643 76.2095 449.516 76.2095C450.381 76.2095 451.15 76.0449 451.824 75.7156C452.498 75.3864 453.026 74.8925 453.409 74.2341C453.792 73.5756 453.983 72.7602 453.983 71.7878V60.4635Z" fill="black"/>
    <path d="M461.092 60.4635V77.1857H459.68V60.4635H461.092Z" fill="black"/>
    <path d="M476.057 71.9715H477.47C477.355 73.0971 477.045 74.0695 476.54 74.8887C476.034 75.7003 475.345 76.3243 474.472 76.7608C473.6 77.1972 472.547 77.4154 471.314 77.4154C470.365 77.4154 469.503 77.2355 468.73 76.8756C467.964 76.5158 467.306 76.0066 466.755 75.3481C466.203 74.682 465.778 73.8857 465.48 72.9592C465.181 72.0328 465.032 71.003 465.032 69.8698V67.7795C465.032 66.6463 465.181 65.6203 465.48 64.7015C465.778 63.7751 466.207 62.9788 466.766 62.3126C467.325 61.6465 467.995 61.1335 468.776 60.7736C469.557 60.4138 470.437 60.2338 471.417 60.2338C472.604 60.2338 473.63 60.4521 474.495 60.8885C475.361 61.3173 476.042 61.9375 476.54 62.7491C477.045 63.5607 477.355 64.5407 477.47 65.6892H476.057C475.95 64.7857 475.713 64.0201 475.345 63.3922C474.978 62.7644 474.469 62.2858 473.818 61.9566C473.175 61.6197 472.375 61.4513 471.417 61.4513C470.636 61.4513 469.94 61.6006 469.327 61.8992C468.715 62.1978 468.194 62.6266 467.765 63.1855C467.336 63.7368 467.007 64.3991 466.778 65.1724C466.555 65.9457 466.444 66.8071 466.444 67.7565V69.8698C466.444 70.7886 466.552 71.6346 466.766 72.408C466.98 73.1813 467.294 73.8512 467.708 74.4178C468.129 74.9844 468.642 75.4247 469.247 75.7386C469.852 76.0525 470.541 76.2095 471.314 76.2095C472.302 76.2095 473.125 76.0525 473.783 75.7386C474.45 75.417 474.966 74.9423 475.334 74.3145C475.701 73.6866 475.943 72.9056 476.057 71.9715Z" fill="black"/>
    <path d="M482.328 60.4635V77.1857H480.916V60.4635H482.328ZM492.665 60.4635L485.452 68.124L481.823 71.6959L481.685 70.1224L484.602 67.0904L490.896 60.4635H492.665ZM491.493 77.1857L484.453 68.5949L485.303 67.4579L493.205 77.1857H491.493Z" fill="black"/>
</svg>
)
}

