import { Navigate, Route, Routes, useLocation, } from 'react-router-dom';
import { RequireAuth } from 'shared/components/RequireAuth';
import { useAuthContext } from 'shared/hooks/useAuthContext';

import { AdminSettings } from './AdminSettings/AdminSettings';
import { CreateAccount } from './CreateAccount/CreateAccount';
import { CreateCustomer } from './CreateCustomer/CreateCustomer';
import { CustomerDetails } from './CustomerDetails/CustomerDetails';
import { CustomersListView } from './CustomersList/CustomersList';
import { DashboardView } from './DashboardView/DashboardView';
import { ErrorPage } from './ErrorPage/ErrorPage';
import { Login } from './Login/Login';
import { PDF } from './PDF/PDF';
import { AdminRoute } from './PrivateRoutes/AdminRoute';
import { Questionnaire } from './Questionnaire/Questionnaire';
import { Reports } from './Reports/Reports';
import { RequestResetPassword } from './ResetPassword/RequestResetPassword';
import { ResetPassword } from './ResetPassword/ResetPassword';
import { CategorySummary } from './Summary/CategorySummary';
import { Summary } from './Summary/Summary';
import { UserManagment } from './UserManagment/UserManagment';

export const Router = (): JSX.Element => {
    const { isReporter } = useAuthContext()
    const location = useLocation()

    return (
        <Routes>
            <Route path="/" element={<Navigate to="login" />} />
            <Route path="login" element={<Login />} />
            <Route path="reset-password">
                <Route path="" element={<RequestResetPassword />} />
                <Route path=":resetToken" element={<ResetPassword />} />
            </Route>
            <Route element={<RequireAuth />}>
                <Route path="dashboard" element={<DashboardView />} />
                <Route path="admin" element={<AdminRoute />}>
                    <Route path="" element={<AdminSettings />} />
                    <Route
                        path="create-account"
                        element={
                            !isReporter ? (
                                <CreateAccount />
                            ) : (
                                <Navigate to="/admin" replace state={{ from: location }} />
                            )
                        }
                    />
                    <Route path="reports" element={<Reports />} />
                    <Route
                        path="user-managment"
                        element={
                            !isReporter ? (
                                <UserManagment />
                            ) : (
                                <Navigate to="/admin" replace state={{ from: location }} />
                            )
                        }
                    />
                </Route>
                <Route path="customers">
                    <Route path="" element={<CustomersListView />} />
                    <Route path="new" element={<CreateCustomer />} />
                    <Route path=":customerId">
                        <Route path="overview" element={<CustomerDetails />} />
                        <Route path="questionnaire/:categoryId" element={<Questionnaire />} />
                        <Route path="summary">
                            <Route path="" element={<Summary />} />
                            <Route path=":categoryId" element={<CategorySummary />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
            <Route path="*" element={<ErrorPage error="Seite nicht gefunden" />} />
        </Routes>
    );
};
