import { gql } from '@apollo/client';

export const GET_CUSTOMER = gql`
    query GET_CUSTOMER($customerId: ID!) {
        customer(customerId: $customerId) {
            id
            name
            company {
                id
                name
            }
            check {
                id
                customer_id
                categories {
                    id
                    name
                    questions {
                        id
                        answer {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const GET_FILE_EXPORT = gql`
    query GET_FILE_EXPORT($customerId: ID!) {
        fileExport(customerId: $customerId)
    }
`;
