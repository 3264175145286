import React, { useRef } from 'react'
import { TextInputProps } from './TextInput.types'
import { useFormContext } from 'react-hook-form'
import { TextInputError, TextInputInner, TextInputStyled, TextInputWrapper } from './TextInput.styled';
import { InputFormErrors } from 'components/SurveyForms/SurveyForm.styled';

export const TextInput = ({name, id, type, validate, required, placeholder, disabled, onChange, onBlur, defaultValue}: TextInputProps) => {
  

    const { register, formState: {errors} } = useFormContext();
    const fieldRef: any = useRef(null);
    const { ref, ...rest } = register(name, {
        required: required,
        validate: validate,
        onChange: onChange,
        onBlur: onBlur
    });
  
  
  
    return (
    <TextInputWrapper>
        <TextInputInner>
            <TextInputStyled
            id={name}
            type={type || 'text'}
            placeholder={ required ? `${placeholder}*` : `${placeholder} (optional)` }
            disabled={disabled}
            defaultValue={defaultValue}
            ref={(e)=> {
                ref(e);
                fieldRef.current = e
            }}
            {...rest}
            />

        </TextInputInner>
       { errors?.[name] && <InputFormErrors>{ errors[name]?.message}</InputFormErrors>}
    </TextInputWrapper>
  )
}


