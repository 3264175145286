import styled from 'styled-components';
import { theme } from 'utils/theme/theme';
export const TextFilterContainer = styled.div`
    overflow-y: visible !important;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: right;
    position: relative;
    min-height: fit-content;
    margin: 2.5rem 0;

    .icon {
        margin-right: 1rem;
    }

    ${theme.breakpoints.down('md')} {
        width: 90vw;
        padding: 0.5rem;
        overflow: auto;
    }
`;

export const StyledTextFilter = styled.input`
    font-size: 1.5rem !important;
    height: 40px;
    width: 100%;
    text-align: ${({ textAlign }: StyledTextFilterProps) => textAlign};
    outline: none;
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${({ theme, error }: StyledTextFilterdProps) =>
        error ? theme.colors.error : theme.colors.secondary};
    color: ${({ theme, error }: StyledTextFilterProps) => (error ? theme.colors.error : theme.colors.textPrimary)};
`;

export const TextFilterLabel = styled.label`
    font-weight: 600;
    font-size: 2.5rem !important;
    text-transform: uppercase;
    text-align: right;
    user-select: none;
    width: 30%;
    padding-right: 50px;
    color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.secondary)};

    ${({ theme }) => theme.breakpoints.down('md')} {
        display: none;
    }
`;


export const TextFilterdError = styled.span`
    visibility: ${({ visible }: TextFilterErrorProps) => (visible ? 'visible' : 'hidden')};
    color: ${({ theme }: TextFilterErrorProps) => theme.colors.error};
    position: absolute;
    top: 50px;
    right: 0;
    font-size: 0.8rem !important;
`;
