
import styled from 'styled-components';
import { Columns } from './Table.types';
import { TableCell } from './TableCell';
import { TableRow } from './TableRow';

interface TableHeadProps {
    columns: Columns[];
}
//ivo
export const StyledTableHead = styled.thead`
    display: table-header-group;
    user-select: none;
    color: black;
    position: sticky;
    top: 0;
    min-width: 100%;
    background-color: white;
    z-index: 10;
    font-size: 22px !important;
`;

export const TableHead = ({ columns }: TableHeadProps): JSX.Element => {
    return (
        <StyledTableHead>
            <TableRow variante="head">
                {columns?.map((col, i) => {
                    return (
                        <TableCell type="head" key={i}>
                            {col.label}
                        </TableCell>
                    );
                })}
            </TableRow>
        </StyledTableHead>
    );
};
