import { useQuery } from '@apollo/client';
import { GET_CUSTOMER } from 'components/CustomerDetails/CustomerDetails.query';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, SummaryCircles } from 'shared/components';
import { CircularLoader } from 'shared/components/CircularLoader/CircularLoader';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import { GET_CATEGORY_SUMMARY } from './CategorySummary.query';
import {
    ButtonInnerWrapper,
    ButtonWrapper,
    NavigationButtonsWrapper,
    SectionButtonsWrapper,
    SummaryContainer,
    SummaryHeader,
    SummaryHeaderSubtitle,
    SummaryHeaderTitle,
    SummaryWrapper,
} from './CategorySummary.styled';

export const CategorySummary = () => {
    const [section, setSection] = useState('general');
    const [categoryIndex, setCategoryIndex] = useState<number>(0);
    const navigate = useNavigate();
    const { customerId, categoryId } = useParams();
    const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

    const { data: summaryData, loading: summaryLoading } = useQuery(GET_CATEGORY_SUMMARY, {
        variables: { customerId },
    });

    const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER, {
        variables: { customerId: customerId },
    });

    useEffect(() => {
        const catIndex = summaryData?.check?.categories?.findIndex((cat) => cat.id === categoryId);

        setCategoryIndex(catIndex);
    }, [summaryData?.check?.categories, categoryId]);

    console.log(customerData);

    const redirectToCustomerDetails = () => {
        navigate(`/customers/${customerId}/overview`);
    };

    const redirectToSummary = () => {
        navigate(`/customers/${customerId}/summary`);
    };

    return (
        <SummaryContainer>
            {summaryLoading || customerLoading ? (
                <CircularLoader thick size={mobileView ? 150 : 200} />
            ) : (
                <>
                    {!mobileView && (
                        <SummaryHeader>
                            <SummaryHeaderTitle>Auswertung</SummaryHeaderTitle>
                            <SummaryHeaderSubtitle>
                                {customerData?.customer?.company?.name}, {customerData?.customer?.name}
                            </SummaryHeaderSubtitle>
                        </SummaryHeader>
                    )}
                    <SummaryWrapper>
                        {section === 'general' && (
                            <SummaryCircles
                                score={summaryData?.check?.categories[categoryIndex]?.score}
                                width={240}
                                benchnmarkScore={summaryData?.check?.categories[categoryIndex]?.allBenchmark}
                                color={theme.colors.iconGreen}
                                categoryLabel="Durchschnitt"
                            />
                        )}

                        {section === 'sector' && (
                            <SummaryCircles
                                score={summaryData?.check?.categories[categoryIndex]?.score}
                                width={240}
                                benchnmarkScore={summaryData?.check?.categories[categoryIndex]?.sectorBenchmark}
                                color={theme.colors.iconBlue}
                                categoryLabel="Branche"
                            />
                        )}
                        {section === 'size' && (
                            <SummaryCircles
                                score={summaryData?.check?.categories[categoryIndex]?.score}
                                width={240}
                                benchnmarkScore={summaryData?.check?.categories[categoryIndex]?.sizeBenchmark}
                                color={theme.colors.iconBrown}
                                categoryLabel="Grössenklasse"
                            />
                        )}
                        {section === 'company' && (
                            <SummaryCircles
                                score={summaryData?.check?.categories[categoryIndex]?.score}
                                width={240}
                                benchnmarkScore={summaryData?.check?.categories[categoryIndex]?.companyBenchmark}
                                color={theme.colors.iconPurple}
                                categoryLabel="Unternehmen"
                            />
                        )}
                    </SummaryWrapper>
                    <ButtonWrapper>
                        <SectionButtonsWrapper>
                            <Button
                                variant={section === 'general' ? 'contained' : 'outlined'}
                                withArrowRight={!mobileView}
                                rounded
                                size="xtra-small"
                                onClick={() => setSection('general')}
                                color="primary"
                                textColor="secondary"
                                width={mobileView ? 200 : 210}
                            >
                                Gesamt
                            </Button>
                            <Button
                                variant={section === 'sector' ? 'contained' : 'outlined'}
                                color={theme.colors.iconBlue}
                                textColor="secondary"
                                withArrowRight={!mobileView}
                                rounded
                                size="xtra-small"
                                onClick={() => setSection('sector')}
                                width={mobileView ? 200 : 210}
                            >
                                Branche
                            </Button>
                            <Button
                                variant={section === 'size' ? 'contained' : 'outlined'}
                                color={theme.colors.iconBrown}
                                textColor="secondary"
                                withArrowRight={!mobileView}
                                rounded
                                size="xtra-small"
                                onClick={() => setSection('size')}
                                width={mobileView ? 200 : 210}
                            >
                                Größenklasse
                            </Button>
                            {!!summaryData?.check?.categories[categoryIndex]?.companyBenchmark && (
                                <Button
                                    variant={section === 'company' ? 'contained' : 'outlined'}
                                    color={theme.colors.iconPurple}
                                    textColor="secondary"
                                    withArrowRight={!mobileView}
                                    rounded
                                    size="xtra-small"
                                    onClick={() => setSection('company')}
                                    width={mobileView ? 200 : 210}
                                >
                                    Unternehmen
                                </Button>
                            )}
                        </SectionButtonsWrapper>
                        <NavigationButtonsWrapper>
                            <Button
                                variant="contained"
                                withArrowRight={!mobileView}
                                rounded
                                color="secondary"
                                textColor="secondary"
                                size="xtra-small"
                                onClick={redirectToSummary}
                                width={mobileView ? 200 : 'auto'}
                            >
                                Gesamtauswertung
                            </Button>
                            <Button
                                variant="contained"
                                withArrowRight={!mobileView}
                                rounded
                                color="secondary"
                                textColor="secondary"
                                size="xtra-small"
                                onClick={redirectToCustomerDetails}
                                width={mobileView ? 200 : 'auto'}
                            >
                                Gesamtübersicht
                            </Button>
                        </NavigationButtonsWrapper>
                    </ButtonWrapper>
                </>
            )}
        </SummaryContainer>
    );
};
