import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
    mutation UPDATE_USER($userId: ID!, $name: String, $role: Role!, $email: String!, $active: Boolean) {
        updateUser(userId: $userId, name: $name, email: $email, role: $role, active: $active) {
            message
            ok
            errors
        }
    }
`;
