import { gql } from '@apollo/client';

export const GET_SUMMARY = gql`
    query GET_SUMMARY($customerId: ID!) {
        check(customerId: $customerId) {
            id
            categories {
                id
                name
                score
                sizeBenchmark
                sectorBenchmark
                allBenchmark
                questions {
                    id
                    label
                    name
                    answer {
                        id
                        score
                        relevance
                    }
                }
            }
        }
    }
`;

export const GET_PAINPOINTS = gql`
    query GET_PAINPOINTS($customerId: ID!) {
        painpoints(customerId: $customerId) {
            id
            score
            relevance
            generalScore
            scoringResult
            questionLabel
        }
    }
`;
