import styled from 'styled-components';

export const InnerLayout = styled.div`
    padding: 0;
    display: flex;
    padding: 0 3rem 0 3rem;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 0 2rem 0 2rem;
    }
`;
