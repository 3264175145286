import { gql } from '@apollo/client';

export const GET_CATEGORY_SUMMARY = gql`
    query GET_CATEGORY_SUMMARY($customerId: ID!) {
        check(customerId: $customerId) {
            id
            categories {
                id
                score
                sizeBenchmark
                sectorBenchmark
                allBenchmark
                companyBenchmark
            }
        }
    }
`;
