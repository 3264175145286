import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;
    position: relative;
    padding-bottom: 100px;
    overflow-y: auto;
    padding-right: 10px;

    ${theme.breakpoints.down('lg')} {
        border-top: none;
    }
`;

export const SummaryHeader = styled.div`
    text-align: right;
    margin-bottom: 2rem;
    padding-top: 45px;
    color: ${({ theme }) => theme.colors.secondary};
`;

export const SummaryHeaderTitle = styled.span`
    display: block;
    font-weight: 300;
    font-size: 2rem !important;
    color: ${({ theme }) => theme.colors.secondary};
`;

export const SummaryHeaderSubtitle = styled.span`
    display: block;
    font-weight: 200;
    font-size: 1.5rem !important;
    color: ${({ theme }) => theme.colors.secondary};
`;

export const SummaryWrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 50px 300px 200px 50px;
    align-items: center;
    justify-content: center;
`;

export const CircleHeader = styled.span`
    position: absolute;
    top: -25%;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1.75rem !important;
`;

export const CirclesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 33%);
    width: 100%;
    align-items: center;
    justify-content: center;

    ${({ theme }) => theme.breakpoints.down('lg')} {
        grid-template-columns: repeat(2, 50%);
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        grid-template-columns: repeat(1, 100%);
    }
`;

export const CirclesWrapper = styled.div`
    display: flex;
    justify-content: center;
    transform: scale(0.6);
    width: 100%;
    text-align: center;
`;

export const PainpointsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

export const PainpointsHeader = styled.span`
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 300;
`;

export const PainpointsDivider = styled.div`
    width: 100%;
    border-top-width: 4px;
    border-top-style: dashed;
    border-top-color: ${({ theme }) => theme.colors.primary};
`;

export const PainpointsWrapper = styled.div`
    display: grid;
    grid-template-columns: 10% 70% 25%;
    align-items: center;
    height: 350px;
    border-bottom-width: 3px;
    border-bottom-style: dashed;
    border-bottom-color: ${({ theme }) => theme.colors.primary};

    ${({ theme }) => theme.breakpoints.down('md')} {
        padding-top: 2rem;
        grid-template-columns: 100%;
        height: auto;
    }
`;

export const PainpointRank = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.secondary};
    border-style: solid;
    border-radius: 100%;
    width: 44px;
    height: 44px;
    background-color: #fff;
    position: relative;
    margin-bottom: 10%;

    ::after {
        content: '';
        background-color: transparent;
        border-width: 2px;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.primary};
        border-radius: 100%;
        position: absolute;
        top: -5px;
        left: -5px;
        width: 48px;
        height: 48px;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
        margin-bottom: 0;
    }
`;

export const PDFPainpointRank = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.secondary};
    border-style: solid;
    border-radius: 100%;
    width: 44px;
    height: 44px;
    background-color: #fff;
    position: relative;
    margin-bottom: 10%;

    ::after {
        content: '';
        background-color: transparent;
        border-width: 2px;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.primary};
        border-radius: 100%;
        position: absolute;
        top: -5px;
        left: -5px;
        width: 48px;
        height: 48px;
    }
`;

export const PainpointLabel = styled.span`
    font-size: 1.5rem !important;
    font-weight: 400 !important;
    font-family: 'Gotham SSm A', 'Gotham SSm B' !important;
    color: ${({ theme }) => theme.colors.secondary};

    ${({ theme }) => theme.breakpoints.down('md')} {
        font-size: 1.25rem !important;
    }
`;

export const PDFPainpointLabel = styled.span`
    font-size: 1.5rem !important;
    font-weight: 400 !important;
    width: calc(100% - 40px);
    font-family: 'Gotham SSm A', 'Gotham SSm B' !important;
    color: ${({ theme }) => theme.colors.secondary};
`;

export const PainpointInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    padding: 2.5rem 0;

    ${({ theme }) => theme.breakpoints.down('md')} {
        padding-top: 1rem;
        padding-bottom: 0;
    }
`;

export const PainpointHexagonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;

    ${({ theme }) => theme.breakpoints.down('md')} {
        margin-top: auto;
        margin-bottom: 0;
    }
`;

export const PainpointHexagonName = styled.span`
    font-size: 1.25rem !important;
    font-weight: 300 !important;
    font-family: 'Gotham SSm A', 'Gotham SSm B' !important;
    color: ${({ theme }) => theme.colors.primary};
`;

export const PainpointHexagonLabel = styled.span`
    font-size: 1.25rem !important;
    font-weight: 300 !important;
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.secondary};
    font-family: 'Gotham SSm A', 'Gotham SSm B' !important;

    ${({ theme }) => theme.breakpoints.only('xs')} {
        display: none !important;
    }
`;

export const PDFPainpointHexagonLabel = styled.span`
    font-size: 1.25rem !important;
    font-weight: 300 !important;
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.secondary};
    font-family: 'Gotham SSm A', 'Gotham SSm B' !important;
`;

export const PainpointCirclesWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transform: scale(0.5);

    ${({ theme }) => theme.breakpoints.down('md')} {
        justify-content: center;
        transform: scale(0.6);
    }
`;

export const PainpointRankWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const PainpointHexagonsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    ${({ theme }) => theme.breakpoints.down('md')} {
        padding-top: 1rem;
        grid-template-columns: 1fr;
        grid-row-gap: 0.8rem;
    }
`;
