import { SidebarSummaryWrapper, SidebarSummaryLabel, SidebarSummaryIcon } from './SidebarSummary.styled';

export const SidebarSummary = ({ score, label, icon }: any) => {
    return (
        <div>
            <SidebarSummaryWrapper>
                <SidebarSummaryIcon>{icon}</SidebarSummaryIcon>
            </SidebarSummaryWrapper>
            <SidebarSummaryLabel>{label}</SidebarSummaryLabel>
        </div>
    );
};
