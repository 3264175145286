import { useEffect } from 'react';
import styled from 'styled-components';

export const ImportanceSelector = ({
    height = '43.856',
    width = '37.98',
    positions = { x: 0, y: 0 },
    selectedImportance,
}) => {
    useEffect(() => {
        console.log('positions: ', positions);
        console.log('importance: ', selectedImportance);
    }, [positions, selectedImportance]);

    return (
        <>
            <StyledImportanceSelector
                height={height}
                viewBox="0 0 37.98 43.856"
                width={width}
                xmlns="http://www.w3.org/2000/svg"
                positions={positions}
            >
                <path
                    d="m288.3 112.986v-21.351l-18.485-10.675-18.49 10.675v21.35l18.49 10.675z"
                    fill="#C00000"
                    transform="translate(-250.825 -80.383)"
                />
            </StyledImportanceSelector>
            <ImportanceSelectorLabel positions={positions}>{selectedImportance}</ImportanceSelectorLabel>
        </>
    );
};

const StyledImportanceSelector = styled.svg`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ positions }) => `translate(calc(-50% + ${positions.x}px), calc(-50% + ${positions.y}px))`};
    transition: transform 0.3s ease-out;
`;

const ImportanceSelectorLabel = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ positions }) => `translate(calc(-50% + ${positions.x}px), calc(-50% + ${positions.y}px))`};
    color: #fff;
    font-family: 'Tungsten A', 'Tungsten B' !important;
    font-weight: 500;
    font-size: 1.5rem !important;
    pointer-events: none;
    user-select: none;
    transition: transform 0.3s ease-out;
`;
