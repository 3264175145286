import { forwardRef, useEffect, useState } from 'react';
import { CheckboxBox, CheckboxError, CheckboxLabel, CheckboxWrapper } from './Checkbox.styled';
import { CheckboxProps } from './Checkbox.types';
import Checkmark from '../../../assets/img/checkmark.svg';

export const Checkbox = forwardRef(
    (
        { label = '', placeholder = '', error = false, defaultValue, errorMessage = '', dialogRed, ...props }: CheckboxProps,
        ref,
    ) => {
        const [isChecked, setIsChecked] = useState<any>(false);
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
            setIsChecked(e.target.checked);
            props.onChange(e);
            //console.log('checkbox',e.target.value)
        };
      useEffect(() => {
            setIsChecked(defaultValue);
            //console.log(defaultValue)
        }, [defaultValue])

       //console.log(defaultValue)
        return (
            <CheckboxWrapper>
                <input {...props} type="checkbox" ref={ref} onChange={handleChange} />
                <CheckboxBox error={error} isChecked={isChecked} dialogRed={dialogRed}>
                    {isChecked && <Checkmark height="30" width="32" />}
                </CheckboxBox>
                <CheckboxLabel dialogRed={dialogRed} error={error}>{label}</CheckboxLabel>
                {error && !!errorMessage && <CheckboxError>{errorMessage}</CheckboxError>}
            </CheckboxWrapper>
        );
    },
);

Checkbox.displayName = 'Checkbox';
