import { gql } from '@apollo/client';

export const GET_CHECK = gql`
    query GET_CHECK($customerId: ID!) {
        check(customerId: $customerId) {
            id
            categories {
                id
                relevance
                score
                questions {
                    id
                    answer {
                        id
                        score
                        relevance
                        notes
                    }
                }
            }
        }
    }
`;

export const GET_CATEGORY = gql`
    query GET_CATEGORY($categoryId: ID!) {
        category(categoryId: $categoryId) {
            id
            name
            description
            introduction
            questions {
                id
                name
                label
                tooltip
            }
        }
    }
`;
