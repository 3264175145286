import { useQuery } from '@apollo/client';
import { GET_CUSTOMER } from 'components/CustomerDetails/CustomerDetails.query';
import { PDF } from 'components/PDF/PDF';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, ImportancePoint, SummaryCircles } from 'shared/components';
import { CircularLoader } from 'shared/components/CircularLoader/CircularLoader';
import { PainpointHexagon } from 'shared/components/PainpointHexagon';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import { GET_PAINPOINTS, GET_SUMMARY } from './Summary.query';
import {
    CircleHeader,
    CirclesContainer,
    CirclesWrapper,
    PainpointCirclesWrapper,
    PainpointHexagonLabel,
    PainpointHexagonName,
    PainpointHexagonsContainer,
    PainpointHexagonWrapper,
    PainpointInnerWrapper,
    PainpointLabel,
    PainpointRank,
    PainpointRankWrapper,
    PainpointsContainer,
    PainpointsHeader,
    PainpointsWrapper,
    SummaryContainer,
    SummaryHeader,
    SummaryHeaderSubtitle,
    SummaryHeaderTitle,
} from './Summary.styled';

const scoreLabels = {
    1: 'Gar nicht umgesetzt',
    2: 'Umsetzung begonnen',
    3: 'Umsetzung in Arbeit',
    4: 'Fortgeschritten',
    5: 'Fast vollständig',
    6: 'Vollständig',
};
const relevanceLabels = [];

export const Summary = (): JSX.Element => {
    const { customerId } = useParams();
    const tabletView = useMediaQuery(theme.breakpoints.down('lg'));
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));

    const mmRef = useRef();
    const pdfRef = useRef();

    const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER, { variables: { customerId } });
    const { data: summaryData, loading: summaryLoading } = useQuery(GET_SUMMARY, { variables: { customerId } });
    const { data: painpointsData, loading: painpointsLoading } = useQuery(GET_PAINPOINTS, {
        variables: { customerId },
    });

    const handlePDFDownload = async () => {
        const pxHeight = mmRef.current.offsetHeight;
        const pxWidth = mmRef.current.offsetWidth;
        const canvas = await html2canvas(pdfRef.current, {
            width: pxWidth * 210,
            scale: 1,
        });
        const data = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'A4');
        const imgProps = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        pdf.internal.pageSize.height = (imgProps.height * pdfWidth) / imgProps.width;
        const pdfHeight = pdf.internal.pageSize.height;

        pdf.addImage(data, 'PNG', 0, 0, 210, pdfHeight);
        const blob = pdf.output('blob');

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `Gesamtauswertung_${customerData?.customer?.company?.name}_${customerData?.customer?.name}.pdf`,
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // const handlePDFDownload = async () => {
    //     const HTML_Width = pdfRef.current.clientWidth;
    //     const HTML_Height = pdfRef.current.clientHeight;
    //     const top_left_margin = 0;
    //     const PDF_Width = HTML_Width + top_left_margin * 2;
    //     const PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    //     const canvas_image_width = HTML_Width;
    //     const canvas_image_height = HTML_Height;

    //     const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    //     const canvas = await html2canvas(pdfRef.current, { allowTaint: true });
    //     canvas.getContext('2d');

    //     console.log(canvas.height + '  ' + canvas.width);

    //     const imgData = canvas.toDataURL('image/jpeg', 1.0);
    //     const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
    //     pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

    //     for (let i = 1; i <= totalPDFPages; i++) {
    //         pdf.addPage([PDF_Width, PDF_Height], 'p');
    //         pdf.addImage(
    //             imgData,
    //             'JPG',
    //             top_left_margin,
    //             -(PDF_Height * i) + top_left_margin * 4,
    //             canvas_image_width,
    //             canvas_image_height,
    //         );
    //     }

    //     pdf.save('HTML-Document.pdf');
    // };

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <>
            <SummaryContainer>
                {customerLoading ? (
                    <CircularLoader thick size={200} />
                ) : (
                    <>
                        {!tabletView && (
                            <SummaryHeader>
                                <SummaryHeaderTitle>Entwicklungsfelder</SummaryHeaderTitle>
                                <SummaryHeaderSubtitle>
                                    {customerData?.customer?.company?.name}, {customerData?.customer?.name}
                                </SummaryHeaderSubtitle>
                            </SummaryHeader>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            rounded
                            textColor="white"
                            size="xtra-small"
                            onClick={handlePDFDownload}
                            style={{ marginLeft: 'auto', marginBottom: 20 }}
                        >
                            PDF herunterladen
                        </Button>
                        <CirclesContainer>
                            {summaryData?.check?.categories?.map((cat) => (
                                <CirclesWrapper>
                                    <CircleHeader>
                                        {cat?.name === 'Informationsmanagement' ? 'Informations-management' : cat?.name}
                                    </CircleHeader>
                                    <SummaryCircles
                                        score={cat?.score}
                                        width={240}
                                        benchnmarkScore={cat?.allBenchmark}
                                        color="#819E87"
                                        categoryLabel="Durchschnitt"
                                        benchmarkIndicator={false}
                                    />
                                </CirclesWrapper>
                            ))}
                        </CirclesContainer>
                        <PainpointsContainer>
                            <PainpointsHeader>Entwicklungsfelder</PainpointsHeader>
                            {painpointsData?.painpoints?.map((painpoint, idx) => (
                                <PainpointsWrapper>
                                    <PainpointRankWrapper>
                                        <PainpointRank>{idx + 1}</PainpointRank>
                                    </PainpointRankWrapper>
                                    <PainpointInnerWrapper>
                                        <PainpointLabel>{painpoint.questionLabel}</PainpointLabel>
                                        <PainpointHexagonsContainer>
                                            <PainpointHexagonWrapper>
                                                <PainpointHexagonName>Umsetzung:&nbsp;</PainpointHexagonName>
                                                <PainpointHexagon num={painpoint.score} />
                                                <PainpointHexagonLabel>
                                                    {scoreLabels[painpoint.score]}
                                                </PainpointHexagonLabel>
                                            </PainpointHexagonWrapper>
                                            <PainpointHexagonWrapper>
                                                <PainpointHexagonName>Relevanz:&nbsp;</PainpointHexagonName>
                                                <PainpointHexagon num={painpoint.relevance} />
                                                <PainpointHexagonLabel></PainpointHexagonLabel>
                                            </PainpointHexagonWrapper>
                                        </PainpointHexagonsContainer>
                                    </PainpointInnerWrapper>
                                    <PainpointCirclesWrapper>
                                        <SummaryCircles
                                            score={painpoint.scoringResult}
                                            width={240}
                                            benchnmarkScore={painpoint.generalScore}
                                            color="#819E87"
                                            categoryLabel="Durchschnitt"
                                            benchmarkIndicator={false}
                                        />
                                    </PainpointCirclesWrapper>
                                </PainpointsWrapper>
                            ))}
                        </PainpointsContainer>
                    </>
                )}
            </SummaryContainer>
            <PDF
                customerData={customerData}
                summaryData={summaryData}
                painpointsData={painpointsData}
                mmRef={mmRef}
                pdfRef={pdfRef}
            />
        </>
    );
};
