import {
    Bar,
    DialogBackground,
    DialogButtonsContainer,
    DialogContainer,
    DialogForm,
    DownloadSuccess,
    ProgressBar,
} from './DownloadDialog.styled';
import { theme } from 'utils/theme/theme';
import { Button } from 'shared/components';

export const DowloadDialog = ({ open, progress, isDownloaded, onClose, downloadFile, errorMessage }: any) => {
    console.log('download dialog', isDownloaded);

    return (
        <DialogBackground open={open}>
            <DialogContainer>
                {!errorMessage && (
                    <DialogForm>
                        <h1 className="title">
                            {!isDownloaded ? 'Datei wird heruntergeladen...' : 'Download abgeschlossen'}
                        </h1>
                        <ProgressBar>
                            <Bar progress={progress}>
                                <h1>{progress}%</h1>
                            </Bar>
                        </ProgressBar>
                        {isDownloaded && (
                            <DownloadSuccess>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        downloadFile();
                                    }}
                                >
                                    Falls der Download nicht automatisch startet, klicken Sie hier.
                                </a>
                                <Button
                                    variant="contained"
                                    color="white"
                                    size="xtra-small"
                                    textColor={theme.colors.primary}
                                    rounded
                                    type="submit"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    Schließen
                                </Button>
                            </DownloadSuccess>
                        )}
                    </DialogForm>
                )}
                {errorMessage && (
                    <DialogForm>
                        <p style={{ marginBottom: '2rem', fontSize: '2.5rem', color: `${theme.colors.textSecondary}` }}>
                            {errorMessage}
                        </p>
                        <Button
                            variant="contained"
                            color="white"
                            size="xtra-small"
                            textColor={theme.colors.primary}
                            rounded
                            type="submit"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Schließen
                        </Button>
                    </DialogForm>
                )}
            </DialogContainer>
        </DialogBackground>
    );
};
