import styled from 'styled-components';

export const SidebarSummaryWrapper = styled.div`
    position: relative;
    margin-top: 45px;
`;

export const SidebarSummaryIcon = styled.div`
    svg {
        * {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }
`;

export const SidebarSummaryLabel = styled.span`
    display: block;
`;

export const SidebarSummaryScore = styled.span`
    color: ${({ theme }) => theme.colors.primary};
    display: block;
    line-height: 1;
    margin-top: 1.5rem;
    font-size: 2.5rem !important;
    font-weight: 700;
`;

export const SidebarSummaryScoreLabel = styled.span`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1rem !important;
    display: block;
`;
