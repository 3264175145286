import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const TableContainer = styled.div`
    width: 100%;
    overflow: auto !important;
    position: relative;
    min-height: 20vh;
    max-height: 50vh;
    border-bottom: 1px solid gray;
    overflow-y: scroll !important;

    ${({ theme }) => theme.breakpoints.down('xs')} {
        width: 85vw;
        overflow: auto !important;
    }

    ${theme.breakpoints.down('md')} {
        width: 90vw;
        overflow: auto !important;
        overflow-y: scroll !important;
    }
`;

interface Props {
    sticky: boolean;
}

export const StyledTable = styled.table`
    min-width: 600px;
    display: table;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: auto;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 85vw;
    }

    ${theme.breakpoints.down('lg')} {
        width: 90vw;
        padding: 0.5rem;
    }
`;

export const HeaderCellWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: inherit;
    width: 100%;
`;

export const ChildrenWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: inherit;
`;
// ivo
export const StyledBodyCell = styled.td`
    display: table-cell;
    vertical-align: inherit;
    padding: 0 16px;
    text-align: center;

    div {
        display: inline-block;
    }
`;
