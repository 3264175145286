import { NoteIconWrapper } from './NoteIcon.styled';
import { NoteIconProps } from './NoteIcon.types';

export const NoteIcon = ({
    height = '28',
    width = '28',
    backgroundColor,
    style,
    onClick,
    clickable = false,
}: NoteIconProps): JSX.Element => {
    return (
        <NoteIconWrapper
            height={height}
            viewBox="0 0 28 28"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            onClick={onClick}
            clickable={clickable}
        >
            <clipPath id="note-a">
                <path d="m0-.342h17v17h-17z" />
            </clipPath>
            <circle
                cx="14"
                cy="14"
                fill={backgroundColor === 'red' ? '#c00000' : backgroundColor === 'grey' ? '#666' : '#fff'}
                r="14"
            />
            <g clipPath="url(#note-a)" transform="translate(6 5.342)">
                <path
                    d="m5.145 15.666 11.339-11.34a.6.6 0 0 0 0-.841l-3.311-3.312a.6.6 0 0 0 -.841 0l-11.34 11.34-.269 1.392-.723 3.753 3.753-.723zm8.028-13.866 1.687 1.687a.6.6 0 0 1 0 .841l-1.5 1.5-2.532-2.528 1.5-1.5a.6.6 0 0 1 .841 0m-10.398 13.153-1.322.255.251-1.322.006-.03 1.094 1.092zm-.547-3.048 7.791-7.791 2.528 2.528-7.79 7.791a.6.6 0 0 1 -.841 0l-1.687-1.687a.6.6 0 0 1 0-.841"
                    fill={backgroundColor === 'red' || backgroundColor === 'grey' ? '#fff' : '#c00000'}
                    transform="translate(.202 -.201)"
                />
            </g>
        </NoteIconWrapper>
    );
};
