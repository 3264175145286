import styled from 'styled-components';
import { MobileWizardSeparatorProps, MobileWizardStepProps } from './MobileWizard.types';

export const MobileWizardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 44px;
    height: calc(70vh - 200px);
    position: fixed;
    top: 5rem;
    left: -10px;
`;

export const MobileWizardStep = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem !important;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme, active }: MobileWizardStepProps) =>
        active ? theme.colors.primary : theme.colors.textPrimary};
    background-color: ${({ theme, active }: MobileWizardStepProps) => (active ? theme.colors.primary : '#fff')};
    color: ${({ theme, active }: MobileWizardStepProps) => (active ? '#fff' : theme.colors.primary)};
    flex-shrink: 0;
    flex-grow: 0;
    z-index: 15;
    transition: background-color 0.3s linear, color 0.3s linear;
`;

export const MobileWizardStepSeparator = styled.div`
    height: 100%;
    width: 2px;
    background-color: ${({ theme }: MobileWizardSeparatorProps) => theme.colors.textPrimary};
`;
