import { useEffect, useRef, useState } from 'react';
import { WizardContainer, WizardStep, WizardStepIndicator, WizardStepSeparator } from './Wizard.styled';
import { WizardProps } from './Wizard.types';

export const Wizard = ({ steps, activeStep = 1, changeStep, answeredSteps = 0 }: WizardProps) => {
    const [positionCurrentStep, setPositionCurrentStep] = useState<number>(0);
    const currentStepRef = useRef();

    const handleStepClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
        changeStep(e.target.dataset.step);
    };

    useEffect(() => {
        setPositionCurrentStep(currentStepRef.current.offsetLeft);
        console.log('useeffect...');
    }, [activeStep]);

    return (
        <WizardContainer>
            {steps.map((step) => (
                <>
                    <WizardStep
                        ref={step === activeStep || (step === 1 && activeStep === 0) ? currentStepRef : null}
                        key={`wizard-step-${step}`}
                        active={step === activeStep}
                        done={step < activeStep}
                        answered={step <= answeredSteps + 1}
                        step={step}
                        data-step={step}
                        onClick={step <= answeredSteps + 1 ? handleStepClick : (e) => e.preventDefault()}
                    >
                        {step}
                    </WizardStep>
                    {steps.indexOf(step) < steps.length - 1 ? <WizardStepSeparator active={step < activeStep} /> : null}
                </>
            ))}
            <WizardStepIndicator positionCurrentStep={positionCurrentStep} activeStep={activeStep} />
        </WizardContainer>
    );
};
