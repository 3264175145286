import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { theme } from 'utils/theme/theme';
import { AuthContext } from './AuthContext/AuthContext';

// react-router-dom v6 makes previous PrivateRoute components impossible to use, so this wrapper is used instead

export const RequireAuth = (): JSX.Element | null => {
    const { user, loadingAuth } = useContext(AuthContext);

    if (loadingAuth) return <div style={{ display: 'flex', flexGrow: 1 }}></div>;

    if (!loadingAuth && !user) {
        return <Navigate to="/login" replace />;
    }

    return <Outlet />;
};
