import styled from 'styled-components';

export const ImportanceCenterLabel = styled.span`
    font-size: 1.5rem !important;
    color: ${({ theme }) => theme.colors.textPrimary};
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
