import { MagnifyingGlass } from 'phosphor-react';
import { StyledTextFilter, TextFilterContainer, TextFilterLabel } from './Filter.styled';

export const TextFilter = ({
    name,
    label,
    value,
    placeholder,
    onChange,
    error = false,
    ...props
}: any): JSX.Element => {
    return (
        <>
            <TextFilterContainer>
                {!!label && <TextFilterLabel error={error}>{label}</TextFilterLabel>}
                <MagnifyingGlass className="icon" size={35} />
                <StyledTextFilter {...props} onChange={onChange} placeholder={placeholder} value={value} />
            </TextFilterContainer>
        </>
    );
};
