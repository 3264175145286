import { gql } from '@apollo/client';

export const SAVE_RESPONSE = gql`
    mutation SAVE_RESPONSE(
        $customerId: ID!
        $categoryId: ID!
        $questionId: ID
        $answerType: String!
        $importance: Int
        $answer: Int
        $notes: String
    ) {
        saveResponse(
            customerId: $customerId
            categoryId: $categoryId
            questionId: $questionId
            answerType: $answerType
            importance: $importance
            answer: $answer
            notes: $notes
        ) {
            ok
            message
            errors
        }
    }
`;
