import styled from 'styled-components';
import { StyledTextFieldProps, TextFieldErrorProps } from './TextField.types';

export const TextFieldContainer = styled.div`
    overflow-y: visible !important;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
    margin-bottom: 40px;
    padding-bottom: 10px;
    position: relative;
    min-height: fit-content;
`;

export const StyledTextField = styled.input`
    display: flex;
    flex-grow: 1;
    font-size: 1.5rem !important;
    padding-bottom: 5px;
    height: 40px;
    text-align: ${({ textAlign }: StyledTextFieldProps) => textAlign};
    outline: none;
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${({ theme, error }: StyledTextFieldProps) =>
        error ? theme.colors.error : theme.colors.secondary};
    color: ${({ theme, error }: StyledTextFieldProps) => (error ? theme.colors.error : theme.colors.textPrimary)};
`;

export const TextFieldLabel = styled.label`
    font-weight: 600;
    font-size: 2.5rem !important;
    text-transform: uppercase;
    text-align: right;
    user-select: none;
    width: 30%;
    padding-right: 50px;

    color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.secondary)};

    ${({ theme }) => theme.breakpoints.down('md')} {
        display: none;
    }
`;

export const TextFieldError = styled.span`
    visibility: ${({ visible }: TextFieldErrorProps) => (visible ? 'visible' : 'hidden')};
    color: ${({ theme, dialogRed }: TextFieldErrorProps) =>
        dialogRed ? theme.colors.textSecondary : theme.colors.error};
    position: absolute;
    top: 50px;
    right: 0;
    font-size: 0.8rem !important;
`;
