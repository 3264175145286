import styled from 'styled-components';

export const AddCompanyDialogBackground = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    z-index: 500;
`;

export const AddCompanyDialogContainer = styled.div`
    height: 250px;
    width: 600px;
    padding: 2rem;
    padding-top: 1rem;
    background-color: ${({ theme }) => theme.colors.primary};
    position: relative;
    display: flex;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 85vw;
        padding: 1rem;
    }
`;

export const AddCompanyDialogForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    label {
        color: #fff;
    }

    input {
        background-color: transparent;
        color: #fff;
        border-color: #fff;

        ::placeholder {
            color: #fff;
        }
    }
`;

export const AddCompanyDialogCloseButton = styled.div`
    position: absolute;
    top: -22px;
    right: -22px;
    height: 44px;
    width: 44px;
    background-color: ${({ theme }) => theme.colors.textSecondary};
    border-radius: 50%;
    cursor: pointer;

    ::before {
        content: '';
        width: 4px;
        height: 28px;
        transform: translate(-50%, -50%) rotate(-45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 8px;
    }

    ::after {
        content: '';
        width: 4px;
        height: 28px;
        transform: translate(-50%, -50%) rotate(45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 8px;
    }
`;
