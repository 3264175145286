import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import HexagonLight from '../../../assets/img/hexagon-light.svg';
import ProductivityIcon from '../../../assets/img/productivity-icon.svg';
import InfrastructureIcon from '../../../assets/img/infrastructure-icon.svg';
import SecurityIcon from '../../../assets/img/security-icon.svg';
import MobileIcon from '../../../assets/img/mobile-icon.svg';
import InformationManagementIcon from '../../../assets/img/information-management-icon.svg';
import AutomationIcon from '../../../assets/img/automation-icon.svg';
import { SidebarCircle } from '../SidebarCircle/SidebarCircle';
import { SidebarSummary } from '../SidebarSummary/SidebarSummary';
import { SidebarContainer, SidebarIcon, SidebarSubtitle, SidebarTitle } from './Sidebar.styled';
import { useAuthContext } from 'shared/hooks/useAuthContext';

const labelsForCategories = {
    1: 'Infrastruktur',
    2: 'Security',
    3: 'Produktivität',
    4: 'Mobiles Arbeiten',
    5: 'Informationsmanagement',
    6: 'Automatisierung',
};

const iconsForCategories = {
    1: <InfrastructureIcon height="80" width="80" />,
    2: <SecurityIcon height="80" width="80" viewBox="-14 0 80 80" />,
    3: <ProductivityIcon height="80" width="80" viewBox="0 0 35 35" />,
    4: <MobileIcon height="80" width="80" viewBox="-15 0 80 80" />,
    5: <InformationManagementIcon height="80" width="80" viewBox="-10 0 85 85" />,
    6: <AutomationIcon height="80" width="80" viewBox="-5 0 85 85" />,
};

const getLabelForCategory = (path) => {
    const sanitized = path.replace(/\/+$/, '');
    const splitPath = sanitized.split('/');

    const catId = splitPath[splitPath.length - 1];

    return labelsForCategories[catId];
};

const getIconForCategory = (path) => {
    const sanitized = path.replace(/\/+$/, '');
    const splitPath = sanitized.split('/');
    const catId = splitPath[splitPath.length - 1];

    return iconsForCategories[catId];
};

export const Sidebar = (): JSX.Element => {
    const [pathname, setPathname] = useState<string>('');
    const [score, setScore] = useState<number>(0);
    const location = useLocation();
    const { user } = useAuthContext();

    const handleChangeEvent = (e) => {
        if (e?.detail?.value !== undefined) setScore(e.detail.value);
    };

    useEffect(() => {
        setPathname(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        window.addEventListener('progress-change', handleChangeEvent);

        return () => window.removeEventListener('progress-change', handleChangeEvent);
    }, []);

    return (
        <SidebarContainer>
            {!pathname.includes('reset-password') && user  && (
                <>
                    {pathname.includes('/questionnaire') ? (
                        <SidebarCircle
                            score={score}
                            label={getLabelForCategory(pathname)}
                            icon={getIconForCategory(pathname)}
                        />
                    ) : pathname.includes('/summary') ? (
                        /summary$/.test(pathname) ? (
                            <></>
                        ) : (
                            <SidebarSummary label={getLabelForCategory(pathname)} icon={getIconForCategory(pathname)} />
                        )
                    ) : (
                        !pathname.includes('/loader') && (
                            <SidebarIcon>
                                <HexagonLight />
                            </SidebarIcon>
                        )
                    )}
                    {/\/customers$/.test(pathname) && (
                        <>
                            <SidebarTitle>Kunden</SidebarTitle>
                            <SidebarSubtitle>Alle</SidebarSubtitle>
                        </>
                    )}
                    {pathname.includes('/overview') && (
                        <>
                            <SidebarTitle>Übersicht</SidebarTitle>
                            <SidebarSubtitle>Bereiche</SidebarSubtitle>
                        </>
                    )}
                </>
            )}
        </SidebarContainer>
    );
};
