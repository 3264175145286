import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const CustomersListContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;
    position: relative;

    ${theme.breakpoints.down('lg')} {
        border-top: none;
    }
`;

export const SectionLabelWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 40px;

    ${({ theme }) => theme.breakpoints.up('xl')} {
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }

    > * {
        position: inherit;
    }
`;

export const CustomersListWrapper = styled.div`
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;

    ${({ theme }) => theme.breakpoints.down('xl')} {
        grid-template-columns: 1fr;
        justify-items: center;
    }
`;

export const CustomersListLabel = styled.span`
    font-size: 2.5rem !important;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Tungsten A', 'Tungsten B' !important;
`;

export const CustomersList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: calc((8 * (20px + 1.75rem)) + 1.75rem);
    height: calc((8 * (20px + 1.75rem) + 1.75rem));
    overflow-y: auto;
    padding-right: 20px;

    li::last-child {
        margin-bottom: 0;
    }

    ${({ theme }) => theme.breakpoints.down('lg')} {
        max-width: 85vw;
        max-height: calc((8 * (20px + 1.5rem)));
        height: calc((8 * (20px + 1.5rem)));
    }
`;

export const CustomersListItem = styled.li`
    font-family: 'Gotham SSm A', 'Gotham SSM B' !important;
    font-size: 1.75rem !important;
    font-weight: 200;
    color: ${({ theme }) => theme.colors.textPrimary};
    margin-bottom: 15px;
    cursor: pointer;
    
    

    span:hover {
        color: ${({ theme }) => theme.colors.primary};
    }

    ${({ theme }) => theme.breakpoints.down('lg')} {
        font-size: 1.5rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
        font-size: 1.3rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 1.15rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
        font-size: 1rem !important;
    }
`;


export const CustomersListdDeleteItem = styled.span`
    font-family: 'Gotham SSm A', 'Gotham SSM B' !important;
    font-size: 1.75rem !important;
    font-weight: 200;
    color: ${({ theme }) => theme.colors.textPrimary};
    margin-bottom: 15px;
    cursor: pointer;
    margin-left: 1rem;

    .trash-icon {
        position: relative;
        top: 0%;
        transform: translateY(10%);
        

        &:hover {
            fill: ${({ theme }) => theme.colors.primary};
            
        }
    }

    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }

    ${({ theme }) => theme.breakpoints.down('lg')} {
        font-size: 1.5rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
        font-size: 1.3rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 1.15rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
        font-size: 1rem !important;
    }
`;

export const CustomersListInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: max-content;

    > button {
        margin-top: 70px;
        align-self: center;

        ${({ theme }) => theme.breakpoints.down('lg')} {
            margin-top: 20px;
        }
    }

    ${({ theme }) => theme.breakpoints.down('lg')} {
        width: 100%;
        align-items: center;
    }
`;
