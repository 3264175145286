import { ApolloProvider } from '@apollo/client';
import { Layout } from 'components/Layout/Layout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider, Footer, Header, Menu, Sidebar, ThemeProvider } from 'shared/components';
import { client } from 'shared/components/ApolloClient';
import { Content } from 'shared/components/Content';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import { InnerLayout } from './Layout/InnerLayout';
import { RequireAuth } from 'shared/components/RequireAuth';
import { Survey } from './Survey/Survey';
import { DefaultPage } from './DefaultPage/DefaultPage';
import { UserProvider } from 'shared/components/UserContext/UserContext';
import SurveyErrorPage from './SurveyErrorPage/SurveyErrorPage';
import { ErrorPage } from './ErrorPage/ErrorPage';

export const App = (): JSX.Element => {


    return (
        <ApolloProvider client={client}>
            <AuthProvider>
                <ThemeProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="quick-check/:partnerId" element={<Survey />} />
                            <Route path="quick-check/" element={<SurveyErrorPage/>} />
                            <Route path="*" element={<DefaultPage/>} />
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </AuthProvider>
        </ApolloProvider>
    );
};
