import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import { StyledTable, TableContainer } from './Table.styled';
import { TableProps } from './Table.types';
import { TableBody } from './TableBody';
import { TableHead } from './TableHead';

export const Table = ({ style, columns, rows, loading, handleUser }: TableProps): JSX.Element => {
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));
    const tabletView = useMediaQuery(theme.breakpoints.down('lg'));
    const xlView = useMediaQuery(theme.breakpoints.up('xl'));
    return (
        <TableContainer>
            <StyledTable style={style}>
                <TableHead columns={columns} />
                <TableBody rows={rows} handleUser={handleUser} />
            </StyledTable>
        </TableContainer>
    );
};
