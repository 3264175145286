import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Button, SectionLabel } from 'shared/components';
import { CREATE_ADMIN_REPORT } from './Reports.mutation';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import FileSaver from 'file-saver';
import { DowloadDialog } from 'components/DownloadDialog/DownloadDialog';
import { DownloadItemButton, ReportsInner, StyledReports } from './Reports.styled';
import { SectionLabelWrapper } from 'components/CustomersList/CustomersList.styled';
import { FileArrowDown } from 'phosphor-react';

export const Reports = (): JSX.Element => {
    const [progress, setProgress] = useState<number>(0);
    const [dialogDownload, setDialogDownload] = useState<boolean>(false);
    const [isDownloaded, setIsDownloaded] = useState<boolean>(false);
    const [dataBlob, setDataBlob] = useState<BlobPart[] | null>(null);
    const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
    const [errorMessage, setErrorMessage] = useState<any>();
    let sliceSize, byteCharacters, bytesLength, slicesCount, byteArrays, begin, end, bytes, blob: Blob;

    const [createAdminReport, { loading, error }] = useMutation(CREATE_ADMIN_REPORT, {
        onCompleted: (data) => {
            if (data.createAdminFullReport.ok) {
                handleDownload(data.createAdminFullReport.message);
            }
        },
        onError: (err) => {
            if (err) {
                setErrorMessage(err.message);
                setProgress(0);
            }
        },
    });

    let intervalId: any;

    const handleprogressBar = () => {
        let progress = 0;
        intervalId = setInterval(() => {
            progress += 4;

            if (progress > 100) {
                setProgress(100);
                clearInterval(intervalId);
            } else {
                setProgress(progress);
            }
        }, 1000);
    };

    const convertBase64ToExcel = (data: string): BlobPart[] => {
        sliceSize = 1024;
        byteCharacters = window.atob(data);
        bytesLength = byteCharacters.length;
        console.log('bytes length', bytesLength);
        slicesCount = Math.ceil(bytesLength / sliceSize);
        byteArrays = new Array(slicesCount);
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            begin = sliceIndex * sliceSize;
            end = Math.min(begin + sliceSize, bytesLength);
            bytes = new Array(end - begin);

            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return byteArrays;
    };

    const downloadFile = (blob: BlobPart[] | null = null) => {
        let blobToSet = dataBlob;
        if (blob) {
            setDataBlob(blob);
            blobToSet = blob;
        }
        //console.log(blobToSet);
        if (blobToSet) {
            FileSaver.saveAs(new Blob(blobToSet, { type: 'application/vnd.ms-excel' }), 'Digi_Check_Report.xlsx');
        }
    };

    const handleDownload = (data: string) => {
        console.log('got it');
        const blob = convertBase64ToExcel(data);
        if (blob) {
            downloadFile(blob);
            setProgress(100);
            clearInterval(intervalId);
            setIsDownloaded(true);
        }
    };

    const onClose = () => {
        setIsDownloaded(false);
        setDialogDownload(false);
        setProgress(0);
        clearInterval(intervalId);
        setDataBlob(null);
        setErrorMessage(null);
    };

    const startDownload = () => {
        setProgress(0);
        setDialogDownload(true);
        createAdminReport();
        handleprogressBar();
    };

    return (
        <>
            <StyledReports>
                {!mobileView && (
                    <SectionLabelWrapper>
                        <SectionLabel>Reporting erstellen</SectionLabel>
                    </SectionLabelWrapper>
                )}
                <ReportsInner>
                    <DownloadItemButton onClick={startDownload}>
                        Digi_Check_Report.xlsx
                        <FileArrowDown className="icon" />
                    </DownloadItemButton>
                </ReportsInner>
            </StyledReports>
            <DowloadDialog
                open={dialogDownload}
                progress={progress}
                isDownloaded={isDownloaded}
                downloadFile={downloadFile}
                onClose={onClose}
                errorMessage={errorMessage}
            />
        </>
    );
};
