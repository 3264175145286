import { Cloud, CloudCheck, CloudSlash } from 'phosphor-react';
import { theme } from 'utils/theme/theme';
import { ErrorPageContainer, ErrorPageInner } from './ErrorPage.styled';


export const ErrorPage = ({ error }: any): JSX.Element => {
    return (
        <ErrorPageContainer>
            <ErrorPageInner>
                <CloudSlash size={55} color={theme.colors.primary} />
                {error === 'Seite nicht gefunden' ? (
                    <>
                        <p style={{ fontSize: '1rem', color: `${theme.colors.primary}` }}>
                            <strong>404</strong>
                        </p>
                        <p>{error}</p>
                    </>
                ) : (
                    <p>{error}</p>
                )}
            </ErrorPageInner>
        </ErrorPageContainer>
    );
};
