import styled from "styled-components";

export const LayoutSurvey = styled.div`
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    font-family:  'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #fff;
    * {
        font-family:  'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        //font-family: 'Tungsten A', 'Tungsten B';
        box-sizing: border-box;
        vertical-align: baseline;
    }

    input {
        font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        //font-family: 'Gotham SSm A', 'Gotham SSm B';
        font-weight: 200;
    }

    select {
        //font-family: 'Gotham SSm A', 'Gotham SSm B';
        font-weight: 200;
    }

    button {
        //font-family:  'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-family: 'Gotham SSm A', 'Gotham SSm B';
        font-weight: 200;
    }s
`;
   
