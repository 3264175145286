import React, { useEffect, useState } from 'react'
import { RadioButtonProps } from './RadioButton.types'
import { useController, useFormContext } from 'react-hook-form'
import { RadioInput, RadioLabel, RadioRowOuter, RadioRowStyled, RadioWrapper } from './RadioButton.styled';
import { InputFormErrors } from 'components/SurveyForms/SurveyForm.styled';

export const RadioButton = ({id, name, required, disabled, options, validate, row , onChange, checked, value, control, inline}: RadioButtonProps):JSX.Element => {
  
    const { register, formState:{ errors}, } = useFormContext();
    const { ref, ...rest} = register(name, {required: required, validate: validate});

    return(
        <RadioRowOuter>
            <RadioRow required={row} inline={inline}>
                {options?.map((option,index) =>(
                    <RadioWrapper key={option?.value} inline={inline}>
                        <RadioInput
                        {...register(name, {required: required})}
                            type="radio"
                            id={name+"_"+index}
                            value={option?.value}
                            disabled={disabled}
                            {...rest}
                        />
                        <RadioLabel htmlFor={name+"_"+index}>
                            {option?.label}
                        </RadioLabel>
                    </RadioWrapper>
                ))}
            </RadioRow>
            {!!errors[name] && <InputFormErrors>{errors[name]?.message}</InputFormErrors>}
        </RadioRowOuter>
    )
}

const RadioRow = ({ children, required, inline}: any): JSX.Element => {
    
    if(!required) return children;

    return (
        <RadioRowStyled inline={inline}>
            {children}
        </RadioRowStyled>
    )
}
