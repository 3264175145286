import React from 'react'
import { FormInputColumn, FormInputRow, FormInputRowInner, FormLabel, FormParagraph, FormParagraphWrapper,  FormResultContainer, FormWrapper, FormWrapperInner, RequiredNote } from './SurveyForm.styled';
import { RadioButton } from 'shared/components/RadioButton/RadioButton';

import { TextInput } from 'shared/components/TextInput/TextInput';
import { requiredErrorMessage } from 'utils/Errors/errors';

import { emailValidator, phoneValidator } from 'utils/Validators/validators';
import { SurveyCheckBox } from 'shared/components/SurveyCheckBox/SurveyCheckBox';
import { RangeBarResult } from 'shared/components/RangeInput/RangeBarResult';
import { SurveyParagrapgh } from 'components/Survey/Survey.styled';


export const SurveyForm = ({ step, surveyResult }: SurveyFormProps) => {


  const options = [
    { value: 0, label: 'Nein, trifft nicht zu.' },
    { value: 10, label: 'Trifft teilweise zu.' },
    { value: 20, label: 'Ja, trifft zu.' },
  ];

  const genderOptions = [{label:'Frau', value:'Frau'},{label:'Herr', value:'Herr'},{label:'Divers', value:'Divers'}]


  return (
    <>
      {step?.id == 0 && step?.text?.map((item: string, index:number) => <SurveyParagrapgh key={index}>{item}</SurveyParagrapgh>)}
 
      {(step?.id >= 1 && step?.id <= 3) &&(
        <FormWrapper >
          {step?.questions?.map((item: any, index: any) => {
            return (
              <FormInputColumn key={step?.key + `_${index}`} >
                <FormLabel>
                  {item}
                </FormLabel>
                <RadioButton row name={step?.key + `_${index + 1}`} options={options} required={requiredErrorMessage} />
              </FormInputColumn>
            )
          })}
        </FormWrapper>)
      }


      {step?.id === 4 && (
        <FormWrapper >
                {step?.textResult?.filter((item: any) => ( surveyResult >= item?.rangeValue?.min && surveyResult <= item?.rangeValue?.max)).map((item: any, index: number) => {
                return <div key={`${item.result}_${item}`}>
                <FormResultContainer>
                    <FormParagraphWrapper >
                    <FormParagraph>{item?.result}</FormParagraph>
                    <FormParagraph>{step?.text}</FormParagraph>
                    </FormParagraphWrapper>
                </FormResultContainer>
                <RangeBarResult color={item?.hexCode} label={item?.label} />
                </div>})}
        </FormWrapper>
      )
      }
      {step?.id === 5 && (
        <FormWrapper >
          <FormParagraph>{step?.text}</FormParagraph>
          <FormWrapperInner>
            <FormInputRow>
              <FormInputRowInner>
                <RequiredNote>Pflichtfelder*</RequiredNote>
                <RadioButton name="salutation" row options={genderOptions} required={requiredErrorMessage} inline />
              </FormInputRowInner>

              <TextInput
                name="name"
                placeholder='Name'
                required={requiredErrorMessage}
              />
            </FormInputRow>

            <FormInputRow>
              <TextInput
                name="company"
                placeholder='Unternehmen'
                required={requiredErrorMessage}
              />

              <TextInput
                name="location"
                placeholder="Standort"
                required={requiredErrorMessage}
              />
            </FormInputRow>
            <FormInputRow>
              <TextInput
                name="phoneNumber"
                placeholder='Telefon'
                validate={{ isValidNumber: (value: any) => value ? phoneValidator(value) : true }}
              />
              <TextInput
                name="email"
                placeholder='E-Mail'
                required={requiredErrorMessage}
                validate={{ isValideEmail: (value: any) => emailValidator(value) }}
              />
            </FormInputRow>
            <FormInputRow>
              <SurveyCheckBox
                name='consent'
                label={step?.dataConsent}
                required={requiredErrorMessage}
              />
            </FormInputRow>
          </FormWrapperInner>
        </FormWrapper>
      )
      }
    </>
  )
}


