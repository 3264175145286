import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: white;
    background-color: transparent;
    margin: 2rem 0;
    height: 100px;
`;

export const PaginationInner = styled.div`
    width: 40%;
    display: flex;
    justify-content: center;
`;

export const PaginationButton = styled.button`
    border: none;
    display: flex;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    pointer-events: ${(props)=>  props.disabled ? 'none' : null };

`;

export const PaginationItem = styled.span`
    padding: 0 1rem;
    height: 3rem;
    text-align: center;
    margin: auto 0.2rem;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    align-items: center;
    line-height: 1.43;
    font-size: 0.5rem;
    border: none;
    cursor: pointer;

    color: ${(props) => (props.isSelected ? theme.colors.primary : theme.colors.secondary)};
`;
