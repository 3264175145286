import {
    SidebarCircleScore,
    SidebarCircleScoreLabel,
    SidebarCircleWrapper,
    SidebarCircleIcon,
    SidebarCircleLabel,
    SideBarCircleSurveyWrapper,
    SidebarCircleSurveyScore,
    SideBarCircleSurveyIcon,
} from './SidebarCircle.styled';
import { CircularProgress } from '../CircularProgress/CircularProgress';

export const SidebarCircle = ({ score, label, icon, survey , width}: any) => {

    if( survey ){
        return (
            <SideBarCircleSurveyWrapper width={width}>
                <SidebarCircleWrapper>
                    <CircularProgress size={140} strokeWidth={10} progress={score} color="#C00000" ghostColor="#E2E4E4" />
                    <SideBarCircleSurveyIcon>{icon}</SideBarCircleSurveyIcon>
                </SidebarCircleWrapper>
                <SidebarCircleSurveyScore survey>{score}</SidebarCircleSurveyScore>
        </SideBarCircleSurveyWrapper>
  
        )
    }

    return (
        <div>
            <SidebarCircleWrapper>
                <CircularProgress size={140} strokeWidth={10} progress={score} color="#C00000" ghostColor="#E2E4E4" />
                <SidebarCircleIcon>{icon}</SidebarCircleIcon>
            </SidebarCircleWrapper>
            <SidebarCircleLabel>{label}</SidebarCircleLabel>
            <div>
                <SidebarCircleScore>{score}</SidebarCircleScore>
                <SidebarCircleScoreLabel>Punkte</SidebarCircleScoreLabel>
            </div>
        </div>
    );
};
