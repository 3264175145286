import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const UsersListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: right;
    position: relative;
    width: 100%;

    ${theme.breakpoints.down('lg')} {
        border-top: none;
    }

    h1 {
        padding: 1rem;
    }
`;

export const EditUserItem = styled.span`
    font-family: 'Gotham SSm A', 'Gotham SSM B' !important;
    font-size: 1.75rem !important;
    font-weight: 200;
    color: ${({ theme }) => theme.colors.textPrimary};
    cursor: pointer;
    margin-left: 1rem;

    .icon {
        position: relative;
        top: 0%;
        transform: translateY(10%);

        &:hover {
            fill: ${({ theme }) => theme.colors.primary};
        }
    }

    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }

    ${({ theme }) => theme.breakpoints.down('lg')} {
        font-size: 1.5rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
        font-size: 1.3rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 1.15rem !important;
    }

    ${({ theme }) => theme.breakpoints.down('xs')} {
        font-size: 1rem !important;
    }
`;

export const UserListWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;
