import { useEffect, useMemo, useState } from 'react';
import { convertAngleToRadians } from 'shared/helpers';
import {
    BenchmarkIndicator,
    BenchmarkIndicatorLabel,
    BenchmarkArrow,
    CircularBase,
    ProgressCircle,
    ProgressContainer,
    ProgressGhost,
    ProgressText,
    CircularLabel,
} from './CircularProgress.styled';
import { CircularProgressProps } from './CircularProgress.types';

export const CircularProgress = ({
    size = 150,
    progress = 50,
    strokeWidth = 6,
    benchmarkIndicator = false,
    color = 'black',
    label,
    ghostColor,
}: CircularProgressProps): JSX.Element => {
    const circumference = (size / 2 - 5) * 1.5 * Math.PI;
    const dashOffset = useMemo(
        () => circumference + circumference / 3 - (progress / 100) * circumference,
        [circumference, progress],
    );
    const positions = useMemo(() => convertAngleToRadians(size / 2 - 5, 270 / (100 / progress)), [size, progress]);

    return (
        <ProgressContainer width={size} height={size}>
            {benchmarkIndicator && (
                <BenchmarkIndicator color={color} positions={positions} progress={progress}>
                    <BenchmarkIndicatorLabel color={color} progress={progress}>
                        <BenchmarkArrow progress={progress}>
                            <svg height="16" viewBox="0 0 16 16" width="16">
                                <clipPath id="a">
                                    <path d="m0 0h16v16h-16z" />
                                </clipPath>
                                <g clipPath="url(#a)">
                                    <path
                                        d="m7.132 1.519a1 1 0 0 1 1.736 0l6.277 10.981a1 1 0 0 1 -.868 1.5h-12.554a1 1 0 0 1 -.868-1.5z"
                                        transform="matrix(0 1 -1 0 15 0)"
                                    />
                                </g>
                            </svg>
                        </BenchmarkArrow>
                        Benchmark
                        <BenchmarkArrow progress={progress}>
                            <svg height="16" viewBox="0 0 16 16" width="16">
                                <clipPath id="a">
                                    <path d="m0 0h16v16h-16z" />
                                </clipPath>
                                <g clipPath="url(#a)">
                                    <path
                                        d="m7.132 1.519a1 1 0 0 1 1.736 0l6.277 10.981a1 1 0 0 1 -.868 1.5h-12.554a1 1 0 0 1 -.868-1.5z"
                                        transform="matrix(0 1 -1 0 15 0)"
                                    />
                                </g>
                            </svg>
                        </BenchmarkArrow>
                    </BenchmarkIndicatorLabel>
                </BenchmarkIndicator>
            )}
            <CircularLabel color={color}>{label}</CircularLabel>
            <CircularBase
                width={size}
                height={size}
                strokeLinecap="round"
                strokeDasharray={`${circumference} ${circumference}`}
            >
                <ProgressGhost
                    r={size / 2 - 5}
                    cx={size / 2}
                    cy={size / 2}
                    strokeDashoffset={circumference - 1 * circumference}
                    strokeWidth={1}
                    color={ghostColor ? ghostColor : color}
                />
                <ProgressCircle
                    r={size / 2 - 5}
                    cx={size / 2}
                    cy={size / 2}
                    strokeDashoffset={dashOffset}
                    strokeDasharray={circumference + circumference / 3}
                    strokeWidth={strokeWidth}
                    color={color}
                />
            </CircularBase>
            <ProgressText></ProgressText>
        </ProgressContainer>
    );
};
