import { useMutation, useQuery } from '@apollo/client';
import { NoteDialog } from 'components/NoteDialog/NoteDialog';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AnswerHexagon, AnswerPoint, AnswerSelector, Button, NoteIcon } from 'shared/components';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import {
    ImportanceCenterLabel,
    ImportanceHexagon,
    ImportancePoint,
    ImportanceSelector,
    SectionLabel,
    Wizard,
} from 'shared/components';
import { CircularLoader } from 'shared/components/CircularLoader/CircularLoader';
import { MobileWizard } from 'shared/components/MobileWizard/MobileWizard';
import { convertAngleToRadians } from 'shared/helpers';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import { SAVE_RESPONSE } from './Questionnaire.mutation';
import { GET_CATEGORY, GET_CHECK } from './Questionnaire.query';
import {
    HexagonWrapper,
    IntroductionAnswerSection,
    IntroductionDetails,
    IntroductionDetailsText,
    IntroductionHeader,
    IntroductionWrapper,
    QuestionAnswerSection,
    QuestionAnswerSectionLeft,
    QuestionAnswerSectionRight,
    QuestionLabel,
    QuestionnaireContainer,
    QuestionnaireTitleWrapper,
    QuestionnaireWrapper,
    QuestionSection,
    QuestionText,
    QuestionTooltip,
} from './Questionnaire.styled';

const calcHelper = {
    1: 5,
    2: 4,
    3: 3,
    4: 2,
    5: 1,
    6: 6,
};

const answerCalcHelper = {
    1: 5,
    2: 4,
    3: 3,
    4: 2,
    5: 1,
    6: 6,
};

export const Questionnaire = (): JSX.Element => {
    const params = useParams();
    const navigate = useNavigate();
    const mobileView = useMediaQuery(theme.breakpoints.down('md'));
    const [activeStep, setActiveStep] = useState<number>(0);
    const [answeredSteps, setAnsweredSteps] = useState<number>(0);
    const [selectedImportance, setSelectedImportance] = useState<number | null>(null);
    const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
    const [currentNotes, setCurrentNotes] = useState<string | null>(null);
    const [noteDialogOpen, setNoteDialogOpen] = useState<boolean>(false);

    if (!params?.categoryId || Number.isNaN(Number.parseInt(params.categoryId))) {
        return <Navigate to="../../overview" replace />;
    }

    const {
        data: checkData,
        loading: checkLoading,
        refetch: refetchCheck,
    } = useQuery(GET_CHECK, {
        variables: { customerId: params.customerId },
    });
    const { data: categoryData, loading: categoryLoading } = useQuery(GET_CATEGORY, {
        variables: { categoryId: params.categoryId },
    });

    const [saveResponse, { loading: saveResponseLoading }] = useMutation(SAVE_RESPONSE, {
        onCompleted: async () => {
            await refetchCheck();
        },
    });

    useEffect(() => {
        const questionChangedEvent = new CustomEvent('question-change', {
            detail: {
                category: categoryData?.category?.name,
                number: activeStep,
            },
        });

        window.dispatchEvent(questionChangedEvent);
    }, [activeStep, categoryData]);

    useEffect(() => {
        if (!checkLoading && checkData) {
            const categoryIndex = checkData?.check?.categories?.findIndex((cat: any) => cat.id === params.categoryId);

            const progressChangeEvent = new CustomEvent('progress-change', {
                detail: {
                    value: checkData?.check?.categories[categoryIndex]?.score
                        ? checkData?.check?.categories[categoryIndex]?.score
                        : 0,
                },
            });

            window.dispatchEvent(progressChangeEvent);
            if (categoryIndex !== -1) {
                setAnsweredSteps(
                    checkData?.check?.categories[categoryIndex].questions.filter(
                        (question: any) => question.answer !== null,
                    ).length,
                );
                if (activeStep === 0) {
                    setSelectedImportance(checkData?.check?.categories[categoryIndex]?.relevance);
                } else {
                    setSelectedImportance(
                        checkData?.check?.categories[categoryIndex]?.questions[activeStep - 1]?.answer?.relevance
                            ? checkData?.check?.categories[categoryIndex]?.questions[activeStep - 1]?.answer?.relevance
                            : checkData?.check?.categories[categoryIndex]?.relevance
                            ? checkData?.check?.categories[categoryIndex]?.relevance
                            : null,
                    );
                    setSelectedAnswer(
                        checkData?.check?.categories[categoryIndex]?.questions[activeStep - 1]?.answer?.score,
                    );
                    setCurrentNotes(
                        checkData?.check?.categories[categoryIndex]?.questions[activeStep - 1]?.answer?.notes,
                    );
                }
            }
        }
    }, [activeStep, checkData?.check?.categories, params.categoryId, checkLoading, checkData]);

    const nextStep = async (overview: boolean) => {
        const catIndex = checkData?.check?.categories?.findIndex((cat: any) => cat.id === params.categoryId);
        if (activeStep === 0) {
            if (checkData?.check?.categories[catIndex]?.relevance !== selectedImportance) {
                const res = await saveResponse({
                    variables: {
                        customerId: params.customerId,
                        categoryId: params.categoryId,
                        questionId: null,
                        answerType: 'introduction',
                        importance: selectedImportance,
                        answer: selectedAnswer,
                        notes: null,
                    },
                });
            }
        } else {
            if (
                checkData?.check?.categories[catIndex]?.questions[activeStep - 1]?.answer?.relevance !==
                    selectedImportance ||
                checkData?.check?.categories[catIndex]?.questions[activeStep - 1]?.answer?.score !== selectedAnswer ||
                checkData?.check?.categories[catIndex]?.questions[activeStep - 1]?.answer?.notes !== currentNotes
            ) {
                const questionId = checkData?.check?.categories[catIndex]?.questions[activeStep - 1]?.id;

                await saveResponse({
                    variables: {
                        customerId: params.customerId,
                        categoryId: params.categoryId,
                        questionId,
                        answerType: 'question',
                        importance: selectedImportance,
                        answer: selectedAnswer,
                        notes: currentNotes,
                    },
                });
            }
        }

        if (activeStep < categoryData?.category?.questions?.length) {
            setActiveStep(activeStep + 1);
            setSelectedImportance(null);
            setSelectedAnswer(null);
            setCurrentNotes(null);
        } else {
            if (overview) {
                navigate(`/customers/${params.customerId}/overview`);
            } else {
                navigate(`/customers/${params.customerId}/summary/${params.categoryId}`);
            }
        }
    };

    const prevStep = () => {
        setActiveStep(activeStep - 1);
    };

    const saveImportance = (importance: number) => {
        setSelectedImportance(importance);
    };

    const redirectToCustomerDetails = () => {
        navigate(`/customers/${params.customerId}/overview`);
    };

    const saveAnswer = (answer: number) => {
        setSelectedAnswer(answer);
    };

    const saveNotes = (notes?: string): void => {
        if (!notes || notes === '') {
            setCurrentNotes(null);
        } else {
            setCurrentNotes(notes);
        }
    };

    const openNoteDialog = (): void => {
        setNoteDialogOpen(true);
    };

    const closeNoteDialog = (): void => {
        setNoteDialogOpen(false);
    };

    const changeStep = (step: number): void => {
        setActiveStep(Number(step));
    };

    return (
        <>
            <QuestionnaireContainer>
                {categoryLoading ? (
                    <CircularLoader thick size={mobileView ? 150 : 200} />
                ) : (
                    <>
                        <QuestionnaireWrapper>
                            <QuestionnaireTitleWrapper>
                                {!mobileView && (
                                    <SectionLabel>
                                        {activeStep === 0 ? 'Beschreibung' : `Frage ${activeStep}`}
                                        {activeStep > 0 && (
                                            <>
                                                {' '}
                                                <NoteIcon
                                                    backgroundColor={currentNotes ? 'red' : 'grey'}
                                                    clickable
                                                    onClick={openNoteDialog}
                                                />
                                            </>
                                        )}
                                    </SectionLabel>
                                )}
                                {activeStep > 0 && (
                                    <QuestionLabel>
                                        {activeStep > 0 ? categoryData?.category?.questions[activeStep - 1]?.name : ''}
                                    </QuestionLabel>
                                )}
                            </QuestionnaireTitleWrapper>

                            <QuestionSection activeStep={activeStep}>
                                <QuestionText>
                                    {activeStep === 0
                                        ? categoryData?.category?.introduction
                                        : categoryData?.category?.questions[activeStep - 1]?.label}
                                    {categoryData?.category?.questions[activeStep - 1]?.tooltip && (
                                        <Tippy content={categoryData?.category?.questions[activeStep - 1]?.tooltip}>
                                            <QuestionTooltip>
                                                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m256 8c-136.957 0-248 111.083-248 248 0 136.997 111.043 248 248 248s248-111.003 248-248c0-136.917-111.043-248-248-248zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12z" />
                                                </svg>
                                            </QuestionTooltip>
                                        </Tippy>
                                    )}
                                </QuestionText>
                                {activeStep === 0 ? (
                                    <IntroductionAnswerSection>
                                        {checkLoading ? (
                                            <div></div>
                                        ) : (
                                            <>
                                                <HexagonWrapper>
                                                    <ImportanceHexagon />
                                                    <ImportancePoint
                                                        importance={5}
                                                        saveImportance={saveImportance}
                                                        positions={convertAngleToRadians(214 / 2, 1 * 60)}
                                                    />
                                                    <ImportancePoint
                                                        importance={4}
                                                        saveImportance={saveImportance}
                                                        positions={convertAngleToRadians(214 / 2, 2 * 60)}
                                                    />
                                                    <ImportancePoint
                                                        importance={3}
                                                        saveImportance={saveImportance}
                                                        positions={convertAngleToRadians(214 / 2, 3 * 60)}
                                                    />
                                                    <ImportancePoint
                                                        importance={2}
                                                        saveImportance={saveImportance}
                                                        positions={convertAngleToRadians(214 / 2, 4 * 60)}
                                                    />
                                                    <ImportancePoint
                                                        importance={1}
                                                        saveImportance={saveImportance}
                                                        positions={convertAngleToRadians(214 / 2, 5 * 60)}
                                                    />
                                                    <ImportancePoint
                                                        importance={6}
                                                        saveImportance={saveImportance}
                                                        positions={convertAngleToRadians(214 / 2, 6 * 60)}
                                                    />
                                                    <ImportanceSelector
                                                        selectedImportance={selectedImportance}
                                                        positions={
                                                            selectedImportance
                                                                ? convertAngleToRadians(
                                                                      215 / 2,
                                                                      calcHelper[selectedImportance] * 60,
                                                                  )
                                                                : { x: 0, y: 0 }
                                                        }
                                                    />
                                                </HexagonWrapper>
                                                <IntroductionWrapper intro={activeStep === 0}>
                                                    <IntroductionHeader>
                                                        Relevanz für Ihre Unternehmung
                                                    </IntroductionHeader>
                                                    <IntroductionDetails>
                                                        <IntroductionDetailsText>
                                                            1 = nicht relevant
                                                        </IntroductionDetailsText>
                                                        <IntroductionDetailsText
                                                            style={{ marginLeft: mobileView ? 10 : 0 }}
                                                        >
                                                            6 = sehr relevant
                                                        </IntroductionDetailsText>
                                                    </IntroductionDetails>
                                                </IntroductionWrapper>
                                                <Button
                                                    style={{
                                                        marginTop: 'auto',
                                                        marginBottom: 40,
                                                        height: 40,
                                                        width: 250,
                                                    }}
                                                    variant="contained"
                                                    withArrowRight
                                                    rounded
                                                    size="xtra-small"
                                                    onClick={() => {
                                                        nextStep(false);
                                                    }}
                                                    color="primary"
                                                    textColor="secondary"
                                                    disabled={activeStep === 0 && !selectedImportance}
                                                >
                                                    {saveResponseLoading || categoryLoading ? (
                                                        <CircularLoader color="white" size="20" />
                                                    ) : activeStep === 0 ? (
                                                        "Los geht's"
                                                    ) : activeStep === Object.keys(questions).length - 1 ? (
                                                        'Auswertung'
                                                    ) : (
                                                        'Nächste Frage'
                                                    )}
                                                </Button>
                                            </>
                                        )}
                                    </IntroductionAnswerSection>
                                ) : (
                                    <QuestionAnswerSection>
                                        {checkLoading ? (
                                            <div></div>
                                        ) : (
                                            <>
                                                <QuestionAnswerSectionLeft>
                                                    <HexagonWrapper>
                                                        <AnswerHexagon height={214} width={214} />
                                                        <AnswerPoint
                                                            height="40"
                                                            width="40"
                                                            answer={5}
                                                            label="Fast vollständig"
                                                            saveAnswer={saveAnswer}
                                                            positions={convertAngleToRadians(214 / 2, 1 * 60)}
                                                            labelPositions={convertAngleToRadians(
                                                                214 / 2 + 80,
                                                                1 * 60 + 5,
                                                            )}
                                                        />
                                                        <AnswerPoint
                                                            height="40"
                                                            width="40"
                                                            answer={4}
                                                            label="Fortgeschritten"
                                                            saveAnswer={saveAnswer}
                                                            positions={convertAngleToRadians(214 / 2, 2 * 60)}
                                                            labelPositions={convertAngleToRadians(
                                                                214 / 2 + 80,
                                                                2 * 60 - 5,
                                                            )}
                                                        />
                                                        <AnswerPoint
                                                            height="40"
                                                            width="40"
                                                            answer={3}
                                                            label="Umsetzung in Arbeit"
                                                            saveAnswer={saveAnswer}
                                                            positions={convertAngleToRadians(214 / 2, 3 * 60)}
                                                            labelPositions={convertAngleToRadians(214 / 2 + 50, 3 * 60)}
                                                        />
                                                        <AnswerPoint
                                                            height="40"
                                                            width="40"
                                                            answer={2}
                                                            label="Umsetzung begonnen"
                                                            saveAnswer={saveAnswer}
                                                            positions={convertAngleToRadians(214 / 2, 4 * 60)}
                                                            labelPositions={convertAngleToRadians(
                                                                214 / 2 + 80,
                                                                4 * 60 + 5,
                                                            )}
                                                        />
                                                        <AnswerPoint
                                                            height="40"
                                                            width="40"
                                                            answer={1}
                                                            label="Gar nicht umgesetzt"
                                                            saveAnswer={saveAnswer}
                                                            positions={convertAngleToRadians(214 / 2, 5 * 60)}
                                                            labelPositions={convertAngleToRadians(
                                                                214 / 2 + 80,
                                                                5 * 60 - 5,
                                                            )}
                                                        />
                                                        <AnswerPoint
                                                            height="40"
                                                            width="40"
                                                            answer={6}
                                                            label="Vollständig"
                                                            saveAnswer={saveAnswer}
                                                            positions={convertAngleToRadians(214 / 2, 6 * 60)}
                                                            labelPositions={convertAngleToRadians(214 / 2 + 50, 6 * 60)}
                                                        />
                                                        <AnswerSelector
                                                            height="40"
                                                            width="40"
                                                            positions={
                                                                selectedAnswer
                                                                    ? convertAngleToRadians(
                                                                          214 / 2,
                                                                          answerCalcHelper[selectedAnswer] * 60,
                                                                      )
                                                                    : { x: 0, y: 0 }
                                                            }
                                                        />
                                                    </HexagonWrapper>
                                                </QuestionAnswerSectionLeft>
                                                <QuestionAnswerSectionRight>
                                                    <HexagonWrapper
                                                        style={{
                                                            marginRight: 0,
                                                            marginBottom: 60,
                                                            display: mobileView ? 'none' : 'inline',
                                                        }}
                                                    >
                                                        <ImportanceCenterLabel>Relevanz</ImportanceCenterLabel>
                                                        <ImportanceHexagon height="185" width="185" />
                                                        <ImportancePoint
                                                            height="42"
                                                            width="37"
                                                            answerImportance
                                                            selectedImportance={selectedImportance}
                                                            importance={5}
                                                            saveImportance={saveImportance}
                                                            positions={convertAngleToRadians(185 / 2, 1 * 60)}
                                                        />
                                                        <ImportancePoint
                                                            height="42"
                                                            width="37"
                                                            answerImportance
                                                            selectedImportance={selectedImportance}
                                                            importance={4}
                                                            saveImportance={saveImportance}
                                                            positions={convertAngleToRadians(185 / 2, 2 * 60)}
                                                        />
                                                        <ImportancePoint
                                                            height="42"
                                                            width="37"
                                                            answerImportance
                                                            selectedImportance={selectedImportance}
                                                            importance={3}
                                                            saveImportance={saveImportance}
                                                            positions={convertAngleToRadians(185 / 2, 3 * 60)}
                                                        />
                                                        <ImportancePoint
                                                            height="42"
                                                            width="37"
                                                            answerImportance
                                                            selectedImportance={selectedImportance}
                                                            importance={2}
                                                            saveImportance={saveImportance}
                                                            positions={convertAngleToRadians(185 / 2, 4 * 60)}
                                                        />
                                                        <ImportancePoint
                                                            height="42"
                                                            width="37"
                                                            answerImportance
                                                            selectedImportance={selectedImportance}
                                                            importance={1}
                                                            saveImportance={saveImportance}
                                                            positions={convertAngleToRadians(185 / 2, 5 * 60)}
                                                        />
                                                        <ImportancePoint
                                                            height="42"
                                                            width="37"
                                                            answerImportance
                                                            selectedImportance={selectedImportance}
                                                            importance={6}
                                                            saveImportance={saveImportance}
                                                            positions={convertAngleToRadians(185 / 2, 6 * 60)}
                                                        />
                                                    </HexagonWrapper>
                                                    <div
                                                        style={{
                                                            marginBottom: 40,
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <IntroductionWrapper
                                                            intro={activeStep === 0}
                                                            style={{ marginBottom: 10 }}
                                                        >
                                                            <IntroductionHeader>Umsetzung</IntroductionHeader>
                                                        </IntroductionWrapper>
                                                        <Button
                                                            style={{ marginBottom: 10, width: 250, height: 40 }}
                                                            variant="contained"
                                                            withArrowRight
                                                            rounded
                                                            size="xtra-small"
                                                            onClick={() => {
                                                                nextStep(false);
                                                            }}
                                                            color="primary"
                                                            textColor="secondary"
                                                            disabled={
                                                                (activeStep > 0 && !selectedAnswer) ||
                                                                saveResponseLoading ||
                                                                categoryLoading
                                                            }
                                                        >
                                                            {saveResponseLoading || categoryLoading ? (
                                                                <CircularLoader color="white" size="20" />
                                                            ) : activeStep === 0 ? (
                                                                "Los geht's"
                                                            ) : activeStep ===
                                                              Object.keys(categoryData?.category?.questions).length ? (
                                                                'Auswertung'
                                                            ) : (
                                                                'Nächste Frage'
                                                            )}
                                                        </Button>
                                                        {activeStep ===
                                                            Object.keys(categoryData?.category?.questions).length && (
                                                            <Button
                                                                style={{ marginBottom: 10, width: 250, height: 40, whiteSpace: 'nowrap' }}
                                                                variant="outlined"
                                                                rounded
                                                                size="xtra-small"
                                                                onClick={() => {
                                                                    nextStep(true);
                                                                }}
                                                                disabled={
                                                                    (activeStep > 0 && !selectedAnswer) ||
                                                                    saveResponseLoading ||
                                                                    categoryLoading
                                                                }
                                                                color="primary"
                                                                textColor="secondary"
                                                            >
                                                                Speichern & zur Übersicht
                                                            </Button>
                                                        )}
                                                        {activeStep > 0 && (
                                                            <Button
                                                                style={{ marginBottom: 10, width: 250, height: 40 }}
                                                                variant="text"
                                                                withArrowLeft
                                                                rounded
                                                                size="xtra-small"
                                                                onClick={prevStep}
                                                                disabled={saveResponseLoading || categoryLoading}
                                                            >
                                                                {activeStep > 1
                                                                    ? `Zurück zu Frage ${activeStep - 1}`
                                                                    : 'Zurück'}
                                                            </Button>
                                                        )}
                                                        
                                                    </div>
                                                </QuestionAnswerSectionRight>
                                            </>
                                        )}
                                    </QuestionAnswerSection>
                                )}
                            </QuestionSection>
                        </QuestionnaireWrapper>
                        {!mobileView && (
                            <Wizard
                                changeStep={changeStep}
                                steps={Array.from(
                                    { length: categoryData?.category?.questions?.length ?? 0 },
                                    (_, i) => i + 1,
                                )}
                                answeredSteps={answeredSteps}
                                activeStep={activeStep}
                            />
                        )}
                        {mobileView && (
                            <MobileWizard
                                steps={Array.from(
                                    { length: categoryData?.category?.questions?.length ?? 0 },
                                    (_, i) => i + 1,
                                )}
                                activeStep={activeStep}
                            />
                        )}
                    </>
                )}
            </QuestionnaireContainer>
            <NoteDialog
                open={noteDialogOpen}
                initValue={currentNotes}
                saveNotes={saveNotes}
                onClose={closeNoteDialog}
            />
        </>
    );
};
