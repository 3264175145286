import { useMutation, useQuery } from '@apollo/client';
import { AddCompanyDialog } from 'components/AddCompanyDialog/AddCompanyDialog';
import { SectionLabelWrapper } from 'components/CustomersList/CustomersList.styled';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { SectionLabel, Select } from 'shared/components';
import { Checkbox } from 'shared/components/Checkbox/Checkbox';
import { CircularLoader } from 'shared/components/CircularLoader/CircularLoader';
import { PlusIcon } from 'shared/components/PlusIcon/PlusIcon';
import { Company, Sector, Size } from 'shared/components/Select/Select.types';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { errorMessages } from 'utils/constants';
import { theme } from 'utils/theme/theme';
import { Button } from '../../shared/components/Button/Button';
import { TextField } from '../../shared/components/TextField/TextField';
import { CREATE_CUSTOMER } from './CreateCustomer.mutation';
import { GET_COMPANIES, GET_SECTORS, GET_SIZES } from './CreateCustomer.query';
import { CreateCustomerContainer, CreateCustomerForm, FieldWrapper } from './CreateCustomer.styled';

const possibleErrors = {
    'Die angegebene Größe gibt es nicht.': 'size',
    'Die angegebene Branche gibt es nicht.': 'sector',
};

export const CreateCustomer = () => {
    const [addCompanyDialogOpen, setAddCompanyDialogOpen] = useState<boolean>(false);
    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
        setError,
    } = useForm<CreateCustomerFormData>();

    const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

    const { data: sizesData, loading: sizesLoading } = useQuery(GET_SIZES);
    const { data: sectorsData, loading: sectorsLoading } = useQuery(GET_SECTORS);
    const { data: companiesData, loading: companiesLoading, refetch: refetchCompanies } = useQuery(GET_COMPANIES);

    const closeDialog = (submitted?: boolean) => {
        setAddCompanyDialogOpen(false);
        if (submitted) refetchCompanies();
    };

    const openDialog = () => {
        setAddCompanyDialogOpen(true);
    };

    const [createCustomer] = useMutation(CREATE_CUSTOMER, {
        onCompleted: (data) => {
            if (data?.createCustomer?.ok) {
                navigate('../');
            }
            // console.log('data: ', data);
            // navigate('../');
        },
        onError: (err) => {
            console.error('err: ', err.message);
            console.error('err.status: ', err.networkError?.message);

            if (possibleErrors[err.message]) {
                setError(possibleErrors[err.message], { message: err.message });
            }
        },
    });

    const navigate = useNavigate();

    const onSubmit = (data: CreateCustomerFormData) => {
        createCustomer({
            variables: data,
        });
    };

    return (
        <>
            <CreateCustomerContainer>
                {sectorsLoading || sizesLoading || companiesLoading ? (
                    <CircularLoader thick size="200" />
                ) : (
                    <>
                        {!mobileView && (
                            <SectionLabelWrapper>
                                <SectionLabel>Kunden anlegen</SectionLabel>
                            </SectionLabelWrapper>
                        )}
                        <CreateCustomerForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <FieldWrapper>
                                <Select
                                    {...register('company', {
                                        required: errorMessages.required,
                                    })}
                                    label="Firma"
                                    error={!!errors.company}
                                    errorMessage={errors?.company?.message}
                                    placeholder="Firma"
                                    options={companiesData?.companies?.map((company: Company) => ({
                                        value: company.id,
                                        label: company.name,
                                    }))}
                                />
                                <PlusIcon
                                    width="32"
                                    height="32"
                                    marginTop={10}
                                    marginLeft={10}
                                    clickable
                                    onClick={openDialog}
                                />
                            </FieldWrapper>
                            <TextField
                                {...register('name', {
                                    required: errorMessages.required,
                                })}
                                label="Ansprechpartner"
                                autoComplete="nope"
                                error={!!errors?.name}
                                errorMessage={errors?.name?.message}
                                placeholder="Name"
                            />
                            <TextField
                                {...register('email', {
                                    required: errorMessages.required,
                                })}
                                label="E-Mail"
                                autoComplete="nope"
                                error={!!errors?.email}
                                errorMessage={errors?.email?.message}
                                placeholder="E-Mail-Adresse"
                            />
                            <Select
                                {...register('sector', { required: errorMessages.required })}
                                label="Branche"
                                error={!!errors?.sector}
                                errorMessage={errors?.sector?.message}
                                placeholder="Welcher Branche ist Ihr Unternehmen zuzuordnen?"
                                options={sectorsData?.sectors?.map((sector: Sector) => ({
                                    value: sector.id,
                                    label: sector.label,
                                }))}
                            />
                            <Select
                                {...register('size', {
                                    required: errorMessages.required,
                                })}
                                label="Grösse"
                                error={!!errors?.size}
                                errorMessage={errors?.size?.message}
                                placeholder="Wieviele Mitarbeiter hat Ihr Unternehmen?"
                                options={sizesData?.sizes?.map((size: Size) => ({ value: size.id, label: size.label }))}
                            />
                            <Checkbox
                                {...register('disclaimerAccepted', { required: errorMessages.required })}
                                label="Der Kunde ist einverstanden, dass die von Ihm angegebenen Daten in einer von der Canon Deutschland GmbH verwalteten, zentralen Datenbank gespeichert werden und diese Daten durch die Canon Deutschland GmbH in deren Namen und Auftrag beauftragte Dienstleister, insbesondere die ALEX & GROSS GmbH, mit anderen Daten der Canon Deutschland GmbH verbundenen Unternehmen zusammengeführt werden.​ Er wurde auf die Canon Datenschutzerklärung hingewiesen und darüber informiert, dass die Datenschutzrichtlinien, die ihn betreffen, und weitere Informationen zum Thema Datenschutz auf der Canon Webseite: https://www.canon.de/privacy-policy/  zu finden sind.​"
                                error={errors?.disclaimerAccepted}
                                errorMessage={errors?.disclaimerAccepted?.message}
                            />
                            <Button
                                style={{ alignSelf: 'center', marginTop: 24 }}
                                variant="text"
                                type="submit"
                                color="primary"
                                size="medium"
                            >
                                Erstellen
                            </Button>
                        </CreateCustomerForm>
                    </>
                )}
            </CreateCustomerContainer>

            <AddCompanyDialog open={addCompanyDialogOpen} onClose={closeDialog} />
        </>
    );
};
