import { ArrowFatLeft, ArrowFatRight } from 'phosphor-react';
import { PaginationButton, PaginationContainer, PaginationInner, PaginationItem } from './Pagination.styled';
import { DOTS, usePagination } from './usePagination';

export const Pagination = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className }: any) => {
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    //console.log('totalCount',totalCount)
    console.log(window.innerHeight);

    if (currentPage === 0 || paginationRange.length < 2){
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <PaginationContainer>
            <PaginationInner>
                <PaginationButton onClick={onPrevious} disabled={currentPage === 1}>
                    <ArrowFatLeft />
                </PaginationButton>
                {paginationRange?.map((pageNumber, i) => {
                    if (pageNumber === DOTS) {
                        return <PaginationItem key={i}>{DOTS}</PaginationItem>;
                    }

                    return (
                        <PaginationItem
                            key={i}
                            className="pagination-item"
                            isSelected={pageNumber === currentPage}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </PaginationItem>
                    );
                })}
                <PaginationButton disabled={currentPage === lastPage} onClick={onNext}>
                    <ArrowFatRight />
                </PaginationButton>
            </PaginationInner>
        </PaginationContainer>
    );
};
