import { ClickAwayContainer, MobileMenuCloseButton, MobileMenuContainer, MobileMenuHeader } from './MobileMenu.styled';
import DigiCheckLogo from '../../../assets/img/digicheck-logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItem, UserInfo } from '../Menu/Menu.styled';
import { useAuthContext } from 'shared/hooks/useAuthContext';

const questionnaireRegex = /\/[0-9]+\/questionnaire\/[0-9]+$/;

export const MobileMenu = ({ open, onClose }): JSX.Element => {
    const navigate = useNavigate();
    const { user, logoutUser , isReporter, isAdmin} = useAuthContext();

    const location = useLocation();

    const redirectToCustomerDetails = (): void => {
        const customerId = location.pathname.split('/')[2];
        onClose();
        navigate(`/customers/${customerId}/overview`);
    };

    const redirectToCustomersList = () => {
        onClose();
        navigate('/customers');
    };

    const redirectToDashboard = () => {
        navigate('/dashboard');
    };

    const redirectToAdminSettings = () => {
        navigate('/admin');
    };

    const logout = () => {
        onClose();
        logoutUser();
    };

    return (
        <>
            <ClickAwayContainer open={open} onClick={onClose} />
            <MobileMenuContainer open={open}>
                <MobileMenuHeader>
                    <MobileMenuCloseButton onClick={onClose} />
                </MobileMenuHeader>
                <UserInfo mobile>{user?.name?.replace(/ /g, '.')}</UserInfo>
                {questionnaireRegex.test(location.pathname) && (
                    <MenuItem mobile onClick={redirectToCustomerDetails}>
                        Kundenübersicht
                    </MenuItem>
                )}
                <MenuItem mobile onClick={redirectToDashboard}>
                    Dashboard
                </MenuItem>
                <MenuItem mobile onClick={redirectToCustomersList}>
                    Kundenliste
                </MenuItem>
               {(isAdmin || isReporter) && <MenuItem mobile onClick={redirectToAdminSettings}>
                    Administration
                </MenuItem> }
                <MenuItem mobile onClick={logoutUser}>
                    Ausloggen
                </MenuItem>
                <DigiCheckLogo />
            </MobileMenuContainer>
        </>
    );
};
