export const ImportanceHexagon = ({ height = '214', width = '214' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 214 214`}>
            <defs>
                <clipPath id="clip-Zeichenfläche_1">
                    <rect width="214" height="214" />
                </clipPath>
            </defs>
            <g id="Zeichenfläche_1" data-name="Zeichenfläche – 1" clipPath="url(#clip-Zeichenfläche_1)">
                <g id="hexagon-outlined" transform="translate(-10.638 -28.92)">
                    <path
                        id="Pfad_7"
                        data-name="Pfad 7"
                        d="M126.18,162.291l-92.723-53.47V215.8Z"
                        transform="translate(-7.819 -25.431)"
                        fill="#ccc"
                        opacity="0.284"
                    />
                    <path
                        id="Pfad_8"
                        data-name="Pfad 8"
                        d="M154.457,162.291l92.72-53.47V215.8Z"
                        transform="translate(-36.095 -25.431)"
                        fill="#b3b3b3"
                        opacity="0.284"
                    />
                    <path
                        id="Pfad_9"
                        data-name="Pfad 9"
                        d="M154.457,178.6l92.72,53.512-92.722,53.513Z"
                        transform="translate(-36.095 -41.737)"
                        fill="#999"
                        opacity="0.284"
                    />
                    <path
                        id="Pfad_10"
                        data-name="Pfad 10"
                        d="M154.457,145.985l92.72-53.47-92.722-53.47Z"
                        transform="translate(-36.095 -9.125)"
                        fill="#ccc"
                        opacity="0.284"
                    />
                    <path
                        id="Pfad_11"
                        data-name="Pfad 11"
                        d="M126.18,178.6,33.457,232.109l92.722,53.513Z"
                        transform="translate(-7.819 -41.737)"
                        fill="#7f7f7f"
                        opacity="0.284"
                    />
                    <path
                        id="Pfad_12"
                        data-name="Pfad 12"
                        d="M126.18,145.985,33.457,92.515l92.722-53.47Z"
                        transform="translate(-7.819 -9.125)"
                        fill="#e6e6e6"
                        opacity="0.284"
                    />
                    <path
                        id="Pfad_13"
                        data-name="Pfad 13"
                        d="M126.179,39.046,33.457,92.515V199.5l92.722,53.513L218.9,199.5V92.515Z"
                        transform="translate(-7.819 -9.125)"
                        fill="none"
                        stroke="#000"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                    />
                </g>
            </g>
        </svg>
    );
};
