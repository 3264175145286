import { InnerLayout } from 'components/Layout/InnerLayout';
import { Layout } from 'components/Layout/Layout';
import { Footer, Header, Menu, Sidebar } from 'shared/components';
import { Content } from 'shared/components/Content';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { theme } from 'utils/theme/theme';
import { Router } from '../Router';


export const DefaultPage = () => {
    const xlView = useMediaQuery(theme.breakpoints.down('xl'));
    const lgView = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <Layout>
            <Header />
            <InnerLayout>
                {!lgView && <Menu />}
                <Content>
                    <Router />
                </Content>
                {!xlView && <Sidebar />}
            </InnerLayout>
            <Footer />
        </Layout>
    );
};
