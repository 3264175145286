import { EditUserItem } from 'components/UserManagment/UserManagment.styled';
import { Key, Pencil } from 'phosphor-react';
import styled from 'styled-components';
import { Button } from '../Button/Button';
import { CircularLoader } from '../CircularLoader/CircularLoader';
import { TableCell } from './TableCell';
import { TableRow } from './TableRow';

interface TableBodyProps {
    rows: any;
    loading: boolean;
}

const StyledTableBody = styled.tbody`
    display: table-row-group;
    position: relative;

    td:first-child {
        border-left: none;
    }

    td:last-child {
        border-right: none;
    }
`;

export const TableBody = ({ rows, loading, handleUser }: any): JSX.Element => {

    return (
        <StyledTableBody>
            {rows?.map((row: any) => (
                <TableRow key={row.id}>
                    <TableCell>{row.active ? 'Aktiv' : ' Inaktiv '}</TableCell>
                    <TableCell>{row.role.toLowerCase()}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                            <EditUserItem onClick={() => handleUser(row, 'password')}>
                                <Key className="icon" size={25} />
                            </EditUserItem>
                            <EditUserItem onClick={() => handleUser(row, 'edit')}>
                                <Pencil className="icon" size={25} />
                            </EditUserItem>
                    </TableCell>
                </TableRow>
            ))}
        </StyledTableBody>
    );
};
