import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Button, TextField } from 'shared/components';
import { REQUEST_PASSWORD } from './PasswordDialog.mutation';
import { theme } from 'utils/theme/theme';
import {
    PasswordDialogBackground,
    PasswordDialogContainer,
    PasswordDialogForm,
    PasswordDialogFormButtons,
} from './PasswordDialog.styled';
import { useAuthContext } from 'shared/hooks/useAuthContext';
import { useForm } from 'react-hook-form';
import { errorMessages } from 'utils/constants';
import { PasswordDialogData } from './PasswordDialog.types';

export const PasswordDialog = ({ open, editUser, setDialog, setEditUser }: any): JSX.Element => {
    const { isAdmin } = useAuthContext();
    const [success, setSuccess] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState('');

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<PasswordDialogData>();

    const [requestPasswordReset] = useMutation(REQUEST_PASSWORD, {
        onCompleted: (data) => {
            if (data.requestPasswordReset.message) {
                if (isAdmin) {
                    setMessage('Wir haben dem Benutzer eine E-Mail mit dem Reset-Link gesendet.');
                }
                if (!isAdmin) {
                    setMessage('Wir haben Ihnen eine E-Mail mit dem Reset-Link gesendet.');
                }
                setSuccess(true);
                setEditUser('');
            }
        },
        onError: (error) => {
            // console.log('ERROR REQUEST', error);
            setErrorMessage(error.message);
        },
    });

    const onSubmit = (data: PasswordDialogData) => {
        //console.log(editUser)
        requestPasswordReset({
            variables: {
                email: isAdmin ? editUser.email : data.email,
            },
        });
    };

    return (
        <PasswordDialogBackground open={open}>
            <PasswordDialogContainer>
                {!success ? (
                    <PasswordDialogForm onSubmit={handleSubmit(onSubmit)}>
                        {!isAdmin && (
                            <>
                                <p style={{ marginBottom: '2.5rem', fontSize: '1.5rem' }}>
                                    Bitte geben Sie Ihre E-Mail an, damit wir Ihnen einen Link zusenden können.
                                    <br />
                                    Überprüfen Sie Ihren Spam-Ordner, sollten Sie keine Nachricht von uns erhalten.
                                </p>
                                <TextField
                                    {...register('email', { required: errorMessages.required })}
                                    label="E-Mail"
                                    placeholder="Ihre E-Mail-Adresse"
                                    error={!!errors?.email}
                                    errorMessage={errors?.email?.message}
                                />
                                {errorMessage && <p style={{ fontSize: '1.5rem' }}>{errorMessage}</p>}
                            </>
                        )}

                        {isAdmin && (
                            <>
                                {!errorMessage && (
                                    <p style={{ marginBottom: '1rem', fontSize: '1.5rem' }}>
                                        Möchten Sie dem Benutzer eine Anfrage schicken, sein Passwort zurückzusetzen?
                                    </p>
                                )}
                                {errorMessage && (
                                    <p style={{ marginBottom: '1rem', fontSize: '1.5rem' }}>{errorMessage}</p>
                                )}
                            </>
                        )}

                        <PasswordDialogFormButtons>
                            {!errorMessage && (
                                <Button
                                    variant="contained"
                                    color="white"
                                    size="xtra-small"
                                    textColor={theme.colors.primary}
                                    rounded
                                    type="submit"
                                    onClick={() => {
                                        console.log('clicked!!!');
                                    }}
                                >
                                    Anfrage senden
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="white"
                                size="xtra-small"
                                textColor={theme.colors.primary}
                                rounded
                                type="button"
                                onClick={() => {
                                    setDialog(false);
                                    setErrorMessage('');
                                }}
                            >
                                schließen
                            </Button>
                        </PasswordDialogFormButtons>
                    </PasswordDialogForm>
                ) : (
                    <PasswordDialogForm>
                        {message && (
                            <>
                                <p style={{ fontSize: '1.7rem' }}>{message}</p>
                                <PasswordDialogFormButtons>
                                    <Button
                                        variant="contained"
                                        color="white"
                                        size="xtra-small"
                                        textColor={theme.colors.primary}
                                        rounded
                                        type="button"
                                        onClick={() => {
                                            setSuccess(false);
                                            setMessage('');
                                            setDialog(false);
                                            setValue('email', '');
                                        }}
                                    >
                                        schließen
                                    </Button>
                                </PasswordDialogFormButtons>
                            </>
                        )}
                    </PasswordDialogForm>
                )}
            </PasswordDialogContainer>
        </PasswordDialogBackground>
    );
};
