import { gql } from '@apollo/client';

export const CREATE_ACCOUNT = gql`
    mutation create_Account($name: String!, $email: String!, $password: String!, $role: Role!) {
        signUp(name: $name, email: $email, password: $password, role: $role) {
            message
            ok
            errors
        }
    }
`;
