export const convertAngleToRadians = (radius: number, angle: number) => {
    // Convert angle to radians

    const positions = {
        x: radius * Math.sin((Math.PI * 2 * angle) / 360),
        y: radius * Math.cos((Math.PI * 2 * angle) / 360),
    };
    return {
        x: Math.round(positions.x * 100) / 100,
        y: -Math.round(positions.y * 100) / 100,
    };
};
