import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const CreateAccountContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1 1 auto;
    position: relative;

    ${theme.breakpoints.down('lg')} {
        border-top: none; 
    }
`;

export const CreateAccountForm = styled.form`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 10rem;

    ${({ theme }) => theme.breakpoints.down('md')} {
        padding: 0;
    }
`;

export const FieldWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
`;

export const ErrorMessage = styled.div`
    width:100%;
    display: flex;
    justify-content: center;
    color: ${({ theme })=> theme.colors.primary}

`; 

