import { StyledCircularLoader, CircularLoaderContainer } from './CircularLoader.styled';
import { CircularLoaderProps } from './CircularLoader.types';

export const CircularLoader = ({
    size = '40',
    thick = false,
    color = 'primary',
    style,
}: CircularLoaderProps): JSX.Element => {
    return (
        <CircularLoaderContainer>
            <StyledCircularLoader size={size} thick={thick} color={color} style={style} />
        </CircularLoaderContainer>
    );
};
