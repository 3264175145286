import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const SectionLabel = styled.span`
    position: ${({ header }) => (header ? 'relative' : 'absolute')};
    top: ${({ header }) => (header ? '0' : '45px')};
    right: 0;
    font-size: 2rem !important;
    font-weight: 300;
    color: ${({ theme, red }) => (red ? theme.colors.primary : theme.colors.secondary)};
    box-sizing: border-box;
    user-select: none;

    ${theme.breakpoints.down('md')} {
        font-size: ${({ header }) => (header ? '1rem' : '1.25rem')} !important;
    }
`;
