import { gql } from '@apollo/client';

export const CREATE_CUSTOMER = gql`
    mutation CREATE_CUSTOMER($name: String!, $email: String!, $size: ID!, $sector: ID!, $company: ID!) {
        createCustomer(name: $name, email: $email, size: $size, sector: $sector, company: $company) {
            message
            ok
            errors
        }
    }
`;
