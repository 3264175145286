import styled from 'styled-components';
import { theme } from 'utils/theme/theme';
import { NoteDialogContainerProps } from './NoteDialog.types';

export const NoteDialogBackground = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    z-index: 500;
`;

export const NoteDialogContainer = styled.div`
    height: 640px;
    width: 1000px;
    padding: 2rem;
    padding-top: 1rem;
    background-color: ${({ theme }: NoteDialogContainerProps) => theme.colors.primary};
    border-top-width: 4px;
    border-top-style: solid;
    border-top-color: ${({ theme }) => theme.colors.textSecondary};
    position: relative;

    ${theme.breakpoints.down('lg')} {
        border-top: none;
    }
`;

export const NoteDialogHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    height: 10%;
    align-items: center;
`;

export const NoteDialogTitle = styled.h2`
    font-family: 'Tungsten A', 'Tungsten B' !important;
    font-size: 2.5rem !important;
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-left: 20px;
    font-weight: 500;

    ::after {
        content: '${({ label }) => label}';
    }
`;

export const NoteDialogCloseButton = styled.div`
    position: absolute;
    top: -22px;
    right: -22px;
    height: 44px;
    width: 44px;
    background-color: ${({ theme }) => theme.colors.textSecondary};
    border-radius: 50%;
    cursor: pointer;

    ::before {
        content: '';
        width: 4px;
        height: 28px;
        transform: translate(-50%, -50%) rotate(-45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 8px;
    }

    ::after {
        content: '';
        width: 4px;
        height: 28px;
        transform: translate(-50%, -50%) rotate(45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 8px;
    }
`;

export const NoteDialogFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 10%;
    flex: 1 1 auto;
    justify-content: flex-end;
`;

export const NoteDialogBody = styled.div`
    display: flex;
    flex: 1 1 auto;
    height: 80%;
    align-items: center;
    padding: 20px 0;
`;

export const NoteDialogTextArea = styled.textarea`
    outline: none;
    background-color: ${({ theme }) => theme.colors.textSecondary};
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 1.5rem !important;
    font-family: 'Gotham SSm A', 'Gotham SSm B' !important;
    height: 100%;
    width: 100%;
`;
