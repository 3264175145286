import styled from 'styled-components';

export const PainpointHexagon = ({ height = '43.856', width = '37.98', num }): JSX.Element => {
    return (
        <div style={{ width: `${width}px`, height: `${height}px`, position: 'relative' }}>
            <StyledPainpointHexagon
                height={height}
                viewBox="0 0 37.98 43.856"
                width={width}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="m288.3 112.986v-21.351l-18.485-10.675-18.49 10.675v21.35l18.49 10.675z"
                    fill="#fff"
                    stroke="#C00000"
                    transform="translate(-250.825 -80.383)"
                />
            </StyledPainpointHexagon>
            <PainpointScoreNum>{num}</PainpointScoreNum>
        </div>
    );
};

const StyledPainpointHexagon = styled.svg`
    path {
        fill: #fff;
    }
`;

const PainpointScoreNum = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    color: ${({ theme }) => theme.colors.primary};
    font-family: 'Tungsten A', 'Tungsten B' !important;
    font-weight: 500;
    font-size: 1.5rem !important;
    pointer-events: none;
    user-select: none;
    transform: translate(-50%, -50%);
`;
