import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import SerializingLink from 'apollo-link-serialize';

const httpLink = new HttpLink({
    uri: process.env.GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('auth-token');

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const serializingLink = new SerializingLink();

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, locations);
        });
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
    }
});

const link = ApolloLink.from([errorLink, serializingLink, authLink, httpLink]);

const cache = new InMemoryCache();

export const client = new ApolloClient({
    link,
    cache,
    connectToDevTools: process.env.STAGE !== 'production' ? true : false,
    credentials: 'include',
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
        },
    },
});
