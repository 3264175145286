import styled from 'styled-components';
import { SelectArrowProps, SelectErrorProps, SelectFieldProps, SelectLabelProps } from './Select.types';

export const SelectContainer = styled.div`
    overflow-y: visible !important;
    display: flex;
    justify-content: end;
    width: 100%;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 10px;
    position: relative;
    min-height: fit-content;

    .selectArrow {
        position: absolute;
        top: 8px;
        right: 0;
        fill: ${({ theme, dialogRed }: SelectArrowProps) =>
            dialogRed ? theme.colors.primary : theme.colors.secondary};
        pointer-events: none;
        transform: ${({ open }: SelectArrowProps) => (open ? 'rotate(-90deg)' : 'rotate(0deg)')};
        transition: transform 0.3s ease;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
        max-width: 85vw;
    }
`;

export const SelectField = styled.select`
    display: flex;
    flex-grow: 1;
    font-size: 1.5rem !important;
    padding-bottom: 5px;
    height: 40px;
    text-align: ${({ textAlign }: SelectFieldProps) => textAlign};
    outline: none;
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${({ theme, error, dialogRed }: SelectFieldProps) =>
        error ? (!dialogRed ? theme.colors.error : theme.colors.secondary) : theme.colors.secondary};

    color: ${({ theme, error, dialogRed }: SelectFieldProps) =>
        error ? (!dialogRed ? theme.colors.error : theme.colors.textSecondary) : theme.colors.secondary};
    appearance: none;
    cursor: pointer;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-right: 30px;
    width: 80%;
    background-color: ${({ theme }) => theme.colors.textSecondary};
`;

export const SelectError = styled.span`
    visibility: ${({ visible }: SelectErrorProps) => (visible ? 'visible' : 'hidden')};
    color: ${({ theme }: SelectErrorProps) => theme.colors.error};
    position: absolute;
    top: 50px;
    right: 0;
    font-size: 0.8rem !important;
`;

export const SelectLabel = styled.label`
    font-weight: 600;
    font-size: 2.5rem !important;
    text-transform: uppercase;
    text-align: right;
    user-select: none;
    width: 30%;
    padding-right: 50px;
    color: ${({ theme, error }: SelectLabelProps) => (error ? theme.colors.error : theme.colors.secondary)};

    ${({ theme }) => theme.breakpoints.down('md')} {
        display: none;
    }
`;

export const SelectArrow = styled.div`
    border: 10px solid transparent;
    border-bottom-width: 8px;
    border-bottom-style: solid;
    border-bottom-color: ${({ theme }) => theme.colors.secondary};
    position: absolute;
    right: 8px;
    top: 10px;
    transform: ${({ open }: SelectArrowProps) => (open ? 'rotate(-180deg)' : 'rotate(-90deg)')};
    transition: transform 0.3s ease;
    transform-origin: 50% 100%;
`;

export const SelectClickAway = styled.div`
    position: relative;
    display: flex;
    flex: 1 1 auto;
    max-width: 70%;
    overflow: hidden;

    ${({ theme }) => theme.breakpoints.down('md')} {
        max-width: 100%;
    }
`;
