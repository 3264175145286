import { FC, ReactNode } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from 'shared/hooks/useAuthContext';

interface AdminRoute {
    children?: ReactNode;
}

export const AdminRoute: FC<AdminRoute> = () => {
    const { isAdmin, isReporter } = useAuthContext();
    const location = useLocation()
    
    return (isAdmin || isReporter) ? <Outlet /> : <Navigate to="/dashboard" replace state={{ from: location }} />
     
};
