import { theme } from 'utils/theme/theme';
import { Button } from 'shared/components';
import {
    SuccessDialogBackground,
    SuccessDialogButtons,
    SuccessDialogContainer,
    SuccessMessage,
} from './CreateAccountSuccessDialog.styled';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { useNavigate } from 'react-router-dom';

export const SuccessDialog = ({ open, message, onClose }: any): JSX.Element => {
    const navigate = useNavigate();
    const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <SuccessDialogBackground open={open}>
            <SuccessDialogContainer>
                <SuccessMessage>
                    <span>{message}</span>
                    <span>Möchten Sie weitere Benutzer hinzufügen? </span>
                </SuccessMessage>
                <SuccessDialogButtons>
                    <Button
                        variant="contained"
                        color="white"
                        size="xtra-small"
                        textColor={theme.colors.primary}
                        rounded
                        type="button"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Ja
                    </Button>
                    <Button
                        variant="contained"
                        color="white"
                        size="xtra-small"
                        textColor={theme.colors.primary}
                        rounded
                        type="button"
                        onClick={() => {
                            onClose();
                            navigate('/dashboard');
                        }}
                    >
                        Nein, zum dashboard
                    </Button>
                </SuccessDialogButtons>
            </SuccessDialogContainer>
        </SuccessDialogBackground>
    )

}