import { BreakpointKey, DefaultBreakpoints, ThemeBreakpoints } from './theme.types';

const defaultBreakpoints: DefaultBreakpoints = {
    keys: ['xs', 'sm','md', 'lg', 'xl', 'xxl'],
    values: {
        xs: 0,
        sm: 600,
        md: 1110,
        lg: 1300,
        xl: 1536,
        xxl: 1800,
    },
    unit: 'px',
    step: 5,
};

const { keys, values, unit, step } = defaultBreakpoints;

const up = (key: BreakpointKey): string => `@media (min-width:${values[key]}${unit})`;
const down = (key: BreakpointKey): string => `@media (max-width:${values[key] - step / 100}${unit})`;

const between = (start: BreakpointKey, end: BreakpointKey): string => {
    const endIndex = keys.indexOf(end);

    return `@media (min-width:${values[start]}${unit}) and (max-width:${values[keys[endIndex]] - step / 100}${unit})`;
};

const only = (key: BreakpointKey): string => {
    if (keys.indexOf(key) + 1 < keys.length) {
        return between(key, keys[keys.indexOf(key) + 1]);
    }

    return up(key);
};

export const breakpoints: ThemeBreakpoints = {
    keys,
    values,
    unit,
    step,
    up,
    down,
    between,
    only,
};
