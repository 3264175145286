import { gql } from '@apollo/client';

export const CREATE_ADMIN_REPORT = gql`
    mutation CREATE_ADMIN_REPORT {
        createAdminFullReport {
            message
            errors
            ok
        }
    }
`;
