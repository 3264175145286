import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const DialogBackground = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${({ open }: any) => (open ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    z-index: 500;
`;

export const DialogContainer = styled.div`
    height: 300px;
    width: 600px;
    padding: 2rem;
    padding-top: 1rem;
    background-color: ${({ theme }) => theme.colors.primary};
    position: relative;
    display: flex;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 85vw;
        padding: 1rem;
    }
`;

export const DialogForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    .title {
        color: white;
        margin: 1.5rem 0;
    }
`;

export const DialogButtonsContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
`;

export const DialogCloseButton = styled.div`
    position: absolute;
    top: -22px;
    right: -22px;
    height: 44px;
    width: 44px;
    background-color: ${({ theme }) => theme.colors.textSecondary};
    border-radius: 50%;
    cursor: pointer;

    ::before {
        content: '';
        width: 4px;
        height: 28px;
        transform: translate(-50%, -50%) rotate(-45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 8px;
    }

    ::after {
        content: '';
        width: 4px;
        height: 28px;
        transform: translate(-50%, -50%) rotate(45deg);
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: 8px;
    }
`;

export const ProgressBar = styled.div`
    border: 1px solid white;
    width:100%;
    height:50px;
`;

export const Bar = styled.div`
    width: ${({ progress }) => progress}%;
    height: 49px;
    background-color: white;
    > h1 {
        text-align: right;
        margin-right: 1.5rem;
        color: #c00000;
    }
`;

export const DownloadSuccess = styled.div`
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color : ${({theme}) => theme.colors.textSecondary};

    a {
        text-decoration: none;
        color : ${({theme}) => theme.colors.textSecondary};
        margin: 1.5rem 0;
        cursor: pointer;
        font-size: 1.5rem;
    }
`;
