import {
    PainpointHexagonName,
    PainpointRankWrapper,
    PDFPainpointHexagonLabel,
    PDFPainpointLabel,
    PDFPainpointRank,
} from 'components/Summary/Summary.styled';
import { SummaryCircles } from 'shared/components';
import { PainpointHexagon } from 'shared/components/PainpointHexagon';
import styled from 'styled-components';
import DigiCheckLogo from '../../assets/img/digicheck-logo.svg';

const scoreLabels = {
    1: 'Gar nicht umgesetzt',
    2: 'Umsetzung begonnen',
    3: 'Umsetzung in Arbeit',
    4: 'Fortgeschritten',
    5: 'Fast vollständig',
    6: 'Vollständig',
};

const LogoWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;

    svg {
        transform: scale(0.7);
    }

    tspan {
        font-size: 46px !important;
        font-family: 'Tungsten A', 'Tungsten B' !important;
    }

    font-family: 'Tungsten A', 'Tungsten B' !important;
`;

export const PDF = ({ summaryData, painpointsData, customerData, pdfRef, mmRef }) => (
    <div style={{ display: 'flex' }}>
        <div ref={mmRef} style={{ height: '1mm', width: '1mm', backgroundColor: 'transparent' }}></div>
        <div
            ref={pdfRef}
            id="pdf-container"
            style={{
                width: '210mm',
                position: 'absolute',
                top: '-100%',
                left: '-100%',
            }}
        >
            <div
                style={{
                    width: '210mm',
                    marginLeft: 24,
                    paddingTop: 20,
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        marginBottom: 20,
                        fontSize: '1.5rem',
                        fontFamily: 'Gotham SSm A, Gotham SSm B',
                        color: '#4A4E52',
                    }}
                >
                    Gesamtauswertung:
                    <br />
                    {customerData?.customer?.company?.name}
                    <br />
                    {customerData?.customer?.name}
                </div>
                <div style={{ position: 'absolute', display: 'flex', top: 20, right: 0 }}>
                    <div
                        style={{
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            flex: '1 1 auto',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <svg height="37" viewBox="0 0 130 37" width="130">
                            <path d="m0 0h130v37h-130z" fill="#fff" />
                            <g fill="#c00000" transform="translate(0 4.774)">
                                <path d="m21.477 24.079a10.911 10.911 0 0 1 -11.077-10.739 10.911 10.911 0 0 1 11.077-10.74 11.279 11.279 0 0 1 5.869 1.63l-5.869 9.11 11.05-6.186a14.72 14.72 0 0 0 -5.009-4.667 21.111 21.111 0 0 0 -10.403-2.487c-5.815 0-10.915 2.045-13.759 5.112a12.055 12.055 0 0 0 -3.356 8.228 12.054 12.054 0 0 0 3.356 8.228c2.852 3.075 7.875 5.112 13.591 5.112s10.737-2.04 13.591-5.112q.234-.253.454-.513l-.433-1.567a11.167 11.167 0 0 1 -9.082 4.591" />
                                <path
                                    d="m111.114 34.185-4.635-17.063a6.532 6.532 0 0 0 -6.321-4.784 6.611 6.611 0 0 0 -2.239.388l-9.983 3.584h10.264l1.756 6.462a9.766 9.766 0 0 0 -6.346-2.325c-4.819 0-8.725 3.186-8.725 7.117s3.906 7.116 8.725 7.116a10.433 10.433 0 0 0 8.427-4.246l1.018 3.75zm-14.144-2.649a3.972 3.972 0 1 1 4.03-3.971 3.972 3.972 0 0 1 -4.03 3.971"
                                    transform="translate(-53.477 -8.165)"
                                />
                                <path
                                    d="m169.142 12.339a4.052 4.052 0 0 0 -1.7.372l-6.407 2.947a4.011 4.011 0 0 0 -3.972-3.319 4.052 4.052 0 0 0 -1.7.372l-7.827 3.6h5.5v17.874h8.054v-15.885a2.014 2.014 0 0 1 4.027 0v15.885h8.053v-17.874a4 4 0 0 0 -4.027-3.972"
                                    transform="translate(-92.936 -8.166)"
                                />
                                <path
                                    d="m303.623 12.339a4.055 4.055 0 0 0 -1.7.372l-6.407 2.947a4.012 4.012 0 0 0 -3.972-3.319 4.057 4.057 0 0 0 -1.7.372l-7.827 3.6h5.5v17.874h8.054v-15.885a2.014 2.014 0 1 1 4.027 0v15.885h8.053v-17.874a4 4 0 0 0 -4.027-3.972"
                                    transform="translate(-177.651 -8.166)"
                                />
                                <path
                                    d="m247.551 23.593a11.411 11.411 0 1 1 -11.41-11.254 11.411 11.411 0 0 1 11.41 11.254m-10.933-7.836a2.521 2.521 0 0 0 -3.083-1.757 2.479 2.479 0 0 0 -1.78 3.041l3.908 14.388a2.52 2.52 0 0 0 3.083 1.756 2.479 2.479 0 0 0 1.78-3.041z"
                                    transform="translate(-141.535 -8.166)"
                                />
                            </g>
                        </svg>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: '0 0 auto',
                            paddingLeft: 30,
                            marginLeft: 30,
                            borderLeft: '2px solid #4a4e52',
                        }}
                    >
                        <svg height="37" viewBox="0 0 134 37" width="134">
                            <path d="m0 0h134v37h-134z" fill="#fff" />
                            <path
                                d="m1.472-.619v-31.581h7.406q7.038 0 7.038 6.9v17.778q0 6.9-7.038 6.9zm4.922-27.34v23.1h1.794a2.476 2.476 0 0 0 2.806-2.8v-17.5a2.476 2.476 0 0 0 -2.806-2.8zm12.236.5v-4.421h4.83v4.421zm.092 26.84v-24.769h4.646v24.769zm20.746-24.769v24.633q0 5.279-5.428 5.279h-2.99q-4.876 0-4.876-4.737v-1.444h4.232v1.083q0 1.489 1.426 1.489h1.61q1.426 0 1.426-1.489v-4.151a6.757 6.757 0 0 1 -4.368 1.715h-.046a4.2 4.2 0 0 1 -3.243-1.218 4.861 4.861 0 0 1 -1.127-3.429v-13.354a4.861 4.861 0 0 1 1.127-3.429 4.2 4.2 0 0 1 3.243-1.218h.046a6.66 6.66 0 0 1 4.37 1.76v-1.489zm-4.646 17.55v-12.862a4.155 4.155 0 0 0 -2.438-.812q-1.656 0-1.656 1.534v11.378q0 1.534 1.656 1.534a4.32 4.32 0 0 0 2.438-.772zm7.314-19.625v-4.421h4.83v4.421zm.092 26.844v-24.769h4.646v24.769z"
                                fill="#c00000"
                                transform="translate(-1.472 32.476)"
                            />
                            <path
                                d="m16.79-13.626h4.83v7.333q0 5.849-5.98 5.849h-2.44q-5.98 0-5.98-5.849v-20.334q0-5.849 5.98-5.849h2.392q5.934 0 5.934 5.8v6.433h-4.826v-6.157a1.628 1.628 0 0 0 -1.84-1.845h-.83a1.649 1.649 0 0 0 -1.886 1.845v19.883a1.649 1.649 0 0 0 1.886 1.844h.92a1.628 1.628 0 0 0 1.84-1.845zm12.51-5.308h4.74v-13.272h4.922v31.492h-4.922v-13.856h-4.74v13.856h-4.92v-31.492h4.92zm12.6-13.272h10.954v4.364h-6.026v8.908h5.8v4.364h-5.8v9.493h6.118v4.363h-11.04zm23.138 18.58h4.83v7.333q0 5.849-5.98 5.849h-2.432q-5.98 0-5.98-5.849v-20.334q0-5.849 5.98-5.849h2.392q5.934 0 5.934 5.8v6.433h-4.83v-6.157a1.628 1.628 0 0 0 -1.84-1.845h-.828a1.649 1.649 0 0 0 -1.884 1.845v19.883a1.649 1.649 0 0 0 1.886 1.845h.92a1.628 1.628 0 0 0 1.84-1.845zm22.592 12.912h-5.29l-4.784-15.431v15.431h-4.922v-31.492h4.922v15.206l5.106-15.206h5.152l-5.52 15.476z"
                                transform="translate(46.186 32.476)"
                            />
                        </svg>
                    </div>
                </div>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 33%)',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 20,
                    }}
                >
                    {summaryData?.check?.categories?.map((cat) => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                transform: 'scale(0.6)',
                                width: '100%',
                                textAlign: 'center',
                                position: 'relative',
                            }}
                        >
                            <div
                                style={{
                                    top: '-30%',
                                    position: 'absolute',
                                    fontSize: '1.75rem',
                                    fontFamily: 'Gotham SSm A, Gotham SSm B',
                                }}
                            >
                                {cat?.name === 'Informationsmanagement' ? 'Informations-management' : cat?.name}
                            </div>
                            <SummaryCircles
                                score={cat?.score}
                                width={240}
                                benchnmarkScore={cat?.allBenchmark}
                                color="#819E87"
                                categoryLabel="Durchschnitt"
                                benchmarkIndicator={false}
                            />
                        </div>
                    ))}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
                    <span style={{ color: '#C00000', fontWeight: 300, marginLeft: 20 }}>Entwicklungsfelder</span>
                    {painpointsData?.painpoints?.map((painpoint, idx) => (
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '10% 70% 25%',
                                alignItems: 'center',
                                height: 350,
                                borderBottomWidth: 3,
                                borderBottomStyle: 'dashed',
                                borderBottomColor: '#C00000',
                            }}
                        >
                            <PainpointRankWrapper>
                                <PDFPainpointRank>{idx + 1}</PDFPainpointRank>
                            </PainpointRankWrapper>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: '90%',
                                    padding: '2.5rem 0',
                                }}
                            >
                                <PDFPainpointLabel>{painpoint.questionLabel}</PDFPainpointLabel>
                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            height: 50,
                                        }}
                                    >
                                        <PainpointHexagonName>Umsetzung:&nbsp;</PainpointHexagonName>
                                        <PainpointHexagon num={painpoint.score} />
                                        <PDFPainpointHexagonLabel>
                                            {scoreLabels[painpoint.score]}
                                        </PDFPainpointHexagonLabel>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            height: 50,
                                            marginLeft: 20,
                                        }}
                                    >
                                        <PainpointHexagonName>Relevanz:&nbsp;</PainpointHexagonName>
                                        <PainpointHexagon num={painpoint.relevance} />
                                        <PDFPainpointHexagonLabel></PDFPainpointHexagonLabel>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    transform: 'scale(0.5)',
                                }}
                            >
                                <SummaryCircles
                                    score={painpoint.scoringResult}
                                    width={240}
                                    benchnmarkScore={painpoint.generalScore}
                                    color="#819E87"
                                    categoryLabel="Durchschnitt"
                                    benchmarkIndicator={false}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
);
