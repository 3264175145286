import styled from 'styled-components';

export const SummaryCirclesWrapper = styled.div`
    position: relative;
`
export const SummaryCirclesInner = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`
export const SummaryCirclesOuter = styled.div`

`
export const SummaryScore = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-100%, -50%);
    text-align: center;
`

export const SummaryScoreTitle = styled.div`
    font-size: 1rem;
    font-weight: 200;
    font-family: 'Gotham SSm A','Gotham SSm B';
    color: ${({ theme }) => theme.colors.secondary};
`

export const SummaryScoreLabel = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    line-height: .8;
    color: ${({ theme }) => theme.colors.secondary};
    font-family: 'Gotham SSm A','Gotham SSm B';
`
export const SummaryScoreValue = styled.div`
    text-align: center;
    font-size: 4rem;
    text-align:center;
    font-weight: 500;
    font-family: 'Gotham SSm A','Gotham SSm B';
    color: ${({ theme }) => theme.colors.secondary};
`
export const SummaryCirclesBenchmarkScore = styled.div`
    text-align: center;
    position: absolute;
    top: 50%;
    line-height: 1;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${({ color }) => color};
    font-size: 4rem;
    text-align:center;
    font-weight: 500;
    font-family: 'Gotham SSm A','Gotham SSm B';
`

export const SummaryCirclesBenchmarkScoreLabel = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    font-family: 'Gotham SSm A','Gotham SSm B';
`