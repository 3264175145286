import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const HeaderContainer = styled.div`
    width: 100%;
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    padding: 0 3rem;
    /* ::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 5%;
        height: 5px;
        width: 80%;
        background-color: ${({ theme }) => theme.colors.primary};
    } */
`;

export const HeaderContainerCanon = styled.div`
    height: 100px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    justify-content: flex-end;
    border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
`;
export const HeaderContainerDigi = styled.div`
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding-left: 30px;
    margin-left: 30px;
    border-left: 2px solid #4a4e52;
`;

export const MobileHeaderContainer = styled.div`
    height: 120.5px;
    width: 100%;
    position: relative;
    z-index: 100;
    padding: 0 1rem;
    display: flex;
    align-items: center;

    ${theme.breakpoints.down('md')} {
        height: 80px;
    }

    ${theme.breakpoints.down('sm')} {
        height: 60px;
    }
`;

export const SurveyMobileHeaderContainer = styled.div`
    width: 100%;
    position: relative;
    z-index: 100;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${theme.breakpoints.down('md')} {
    }

    ${theme.breakpoints.down('sm')} {
    }
`;

export const LogoPlaceholder = styled.span`
    font-size: 2rem !important;
    position: relative;
    color: ${({ theme }) => theme.colors.primary};
    margin-left: auto;
    font-weight: 600;

    .additionalText {
        color: black;
        font-size: 2rem !important;
        font-weight: 600;
    }
`;
