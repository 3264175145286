import styled from 'styled-components';

export const PasswordForm = styled.form`
    display: flex;
    height: 100%;
    width: 100%;
    min-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 5rem;
    padding-right: 10rem;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 0;
    }
`;
