import { LayoutSurvey } from 'components/Layout/LayoutSurvey'
import { SurveyIntroWrapper, SurveyOuter, SurveyParagrapgh } from 'components/Survey/Survey.styled'
import React from 'react'
import { DigiCheckQuickLogo } from 'shared/DigiCheckQuickLogo/DigiCheckQuickLogo'
import styled from 'styled-components'

const ErrorTitle = styled.h1`
    font-size: 2rem;
    margin-bottom: .5rem;
    font-weight: 400;
    color: #C00000;
`
const SurveyErrorPage = () => {
  return (
    <LayoutSurvey>
        <SurveyOuter>
            <SurveyIntroWrapper>
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="#C00000" viewBox="0 0 256 256"><path d="M160,40A88.09,88.09,0,0,0,81.29,88.67,64,64,0,1,0,72,216h88a88,88,0,0,0,0-176Zm0,160H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.11A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,72,72Zm-8-72V88a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,172,164Z"></path></svg>
                    <ErrorTitle>Etwas ist schief gelaufen</ErrorTitle>
                    <SurveyParagrapgh>
                        Die Umfrage konnte nicht geladen werden. Bitte versuchen Sie es später erneut.
                    </SurveyParagrapgh>
            </SurveyIntroWrapper>
        </SurveyOuter>
    </LayoutSurvey>
  )
}

export default SurveyErrorPage
