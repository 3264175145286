import { gql } from '@apollo/client';

export const GET_SECTORS = gql`
    query GET_SECTORS {
        sectors {
            id
            slug
            label
        }
    }
`;

export const GET_SIZES = gql`
    query GET_SIZES {
        sizes {
            id
            slug
            label
        }
    }
`;

export const GET_COMPANIES = gql`
    query GET_COMPANIES {
        companies {
            id
            name
        }
    }
`;
