import { forwardRef } from 'react';
import { StyledTextField, TextFieldContainer, TextFieldError, TextFieldLabel } from './TextField.styled';
import { TextFieldProps } from './TextField.types';

export const TextField = forwardRef(
    (
        {
            label = '',
            placeholder = '',
            type = 'text',
            textAlign = 'left',
            error = false,
            errorMessage = '',
            autoComplete,
            defaultValue,
            dialogRed,
            ...props
        }: TextFieldProps,
        ref,
    ) => (
        <TextFieldContainer>
            {!!label && <TextFieldLabel error={error}>{label}_</TextFieldLabel>}
            <StyledTextField
                {...props}
                ref={ref}
                placeholder={placeholder}
                type={type}
                textAlign={textAlign}
                error={error}
                autoComplete={autoComplete}
                defaultValue={defaultValue}
            />
            <TextFieldError dialogRed={dialogRed} visible={error && !!errorMessage}>
                {errorMessage}
            </TextFieldError>
        </TextFieldContainer>
    ),
);

TextField.displayName = 'TextField';
