import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, TextField } from 'shared/components';
import { errorMessages } from 'utils/constants';
import { theme } from 'utils/theme/theme';
import { CREATE_COMPANY } from './AddCompanyDialog.mutation';
import {
    AddCompanyDialogBackground,
    AddCompanyDialogCloseButton,
    AddCompanyDialogContainer,
    AddCompanyDialogForm,
} from './AddCompanyDialog.styled';
import { AddCompanyDialogFormData, AddCompanyDialogProps } from './AddCompanyDialog.types';

export const AddCompanyDialog = ({ open, onClose }: AddCompanyDialogProps): JSX.Element => {
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid, isSubmitting },
    } = useForm<AddCompanyDialogFormData>();

    const [createCompany] = useMutation(CREATE_COMPANY, {
        refetchQueries: ['companies'],
        onCompleted: () => {
            onClose(true);
        },
    });

    const onSubmit = (data: AddCompanyDialogFormData) => {
        console.log('data submitted: ', data);
        createCompany({ variables: data });
    };

    return (
        <AddCompanyDialogBackground open={open}>
            <AddCompanyDialogContainer>
                <AddCompanyDialogCloseButton id="dialog-close-button" onClick={onClose} />
                <AddCompanyDialogForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <TextField
                        {...register('name', { required: errorMessages.required })}
                        label="Firma"
                        placeholder="Firma hinzufügen"
                        autoComplete="nope"
                        textAlign="center"
                        type="text"
                        error={!!errors?.name}
                        errorMessage={errors?.name?.message}
                    />
                    <Button
                        variant="contained"
                        color="white"
                        size="xtra-small"
                        textColor={theme.colors.primary}
                        rounded
                        type="submit"
                        onClick={() => {
                            console.log('clicked!!!');
                        }}
                        disabled={!isDirty}
                    >
                        Speichern
                    </Button>
                </AddCompanyDialogForm>
            </AddCompanyDialogContainer>
        </AddCompanyDialogBackground>
    );
};
