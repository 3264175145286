import styled from 'styled-components';
import { theme } from 'utils/theme/theme';

export const StyledDashboard = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;
    position: relative;
    width: 100%;

    ${theme.breakpoints.down('lg')} {
        border-top: none;
    }
`;

export const DashboardInner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1 1 auto;
    position: relative;
    align-items: center;
    justify-content: center;

    button {
        position: relative;
        &:not(:first-child) {
            margin-top: 3rem;
            &:before {
                content: '';
                position: absolute;
                display: block;
                top: -2rem;
                width: 3rem;
                height: 2px;
                background-color: ${({ theme }) => theme.colors.lightGrey};
            }
        }
    }
    ${theme.breakpoints.down('lg')} {
        border-top: none;
    }
`;
