import { gql, useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Button, TextField } from 'shared/components';
import { CircularLoader } from 'shared/components/CircularLoader/CircularLoader';
import { errorMessages } from 'utils/constants';
import { PasswordForm } from './ResetPassword.styled';

interface FormData {
    password: string;
    repeatPassword: string;
}

const RESET_PASSWORD = gql`
    mutation RESET_PASSWORD($resetToken: String!, $password: String!) {
        resetPassword(resetToken: $resetToken, password: $password) {
            ok
            errors
            message
        }
    }
`;

export const ResetPassword = (): JSX.Element => {
    const params = useParams();
    const navigate = useNavigate();

    if (!params?.resetToken) {
        navigate('/login');
    }

    const [resetPassword] = useMutation(RESET_PASSWORD, {
        onCompleted: () => {
            navigate('/login');
        },
        onError: (err) => {
            console.log('error: ', err);
        },
    });

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors, isSubmitting },
    } = useForm<FormData>();

    const onSubmit = async (data: FormData): void => {
        console.log('data submitted: ', data);
        await resetPassword({ variables: { password: data.password, resetToken: params.resetToken } });
    };

    return (
        <PasswordForm onSubmit={handleSubmit(onSubmit)}>
            <TextField
                {...register('password', { required: errorMessages.required })}
                label=""
                placeholder="Neues Passwort"
                error={!!errors?.password}
                errorMessage={errors?.password?.message}
                textAlign="center"
                type="password"
                autoComplete="new-password"
            />
            <TextField
                {...register('repeatPassword', {
                    required: errorMessages.required,
                    validate: {
                        samePassword: (value: string) => {
                            if (value) {
                                const passwordValue = getValues('password');

                                if (value !== passwordValue) return errorMessages.samePassword;
                                return true;
                            }

                            return true;
                        },
                    },
                })}
                label=""
                placeholder="Passwort wiederholen"
                error={!!errors?.repeatPassword}
                errorMessage={errors?.repeatPassword?.message}
                textAlign="center"
                type="password"
                autoComplete="new-password"
            />
            <Button disabled={isSubmitting} variant="text" color="primary" type="submit" size="large">
                Absenden
            </Button>
        </PasswordForm>
    );
};
