 const surveyData ={
    name: 'Survey',
    steps:[
        {
            id:0,
            name:'Survey',
            key:'survey',
            text:['Die Digitalisierung transformiert die Geschäftswelt in einem rasanten Tempo. Unternehmen stehen vor der Herausforderung, ihre Prozesse, Technologien und Strategien kontinuierlich anzupassen, um innovativ, effizient und wettbewerbsfähig zu bleiben.', 
            'Mit unserem DigiCHECK beginnen Sie die Analyse des digitalen Reifegrades Ihrer Arbeitsumgebung. Im Anschluss erhalten Sie ein erstes Ergebnis, um dann gemeinsam mit einem unserer Fachexperten den DigiCHECK abschließen zu können.'],
        },
        {
            id:1,
            name:'Infrastruktur',
            key:'infrastruktur',
            questions:['Alle firmeneigenen Drucker und Scanner sind in die Netzwerkumgebung und ins Internet eingebunden.','Cloudbasierte Server und Systeme zur Kosteneinsparung bzw. Erhöhung der Sicherheit werden genutzt.'],
        },
        {
            id:2,
            name:'Security',
            key:'security',
            questions:['Es wurden Sicherheitskonzepte innerhalb des Dokumenten-Outputs (z.B. Authentifizierung am Multifunktionsdrucksystem) etabliert.','Geltende Rechtsprechungen und Richtlinien (interne und externe z.B. EU-DSGVO) werden durch die eingesetzten Hard- und Softwarelösungen erfüllt.'],
        },
        {
            id:3,
            name:'Mobiles Arbeiten',
            key: 'mobiles',
            questions:['Einfaches Scannen und Drucken auch über das Smartphone, insbesondere im Homeoffice sind möglich.','Daten und Informationen aus dem Unternehmensnetzwerk können durch die Mitarbeiter mobil abgerufen werden.'],
        },
        {
            id:4,
            key:'auswertung',
            name:'Auswertung',
            text: 'Vereinbaren Sie jetzt einen Beratungstermin mit einem unserer Experten.',
            textResult: [
                {
                    rangeValue: { min:120 ,max: 140},
                    hexCode:"#81D84E",
                    label:'SEHR GUT',
                    result: 'Ihr Unternehmen ist ein Vorreiter in der Digitalisierung und setzt Maßstäbe in der digitalenTransformation. Doch die digitale Welt entwickelt sich ständig weiter. Es lohnt sich, am Ball zu bleiben und neue Technologien zu integrieren, um die Führungsposition zu halten.',
                },
                {
                    rangeValue:{min:90, max:110},
                    hexCode:"#81D84E",
                    label:'GUT',
                    result:'Sie sind auf einem guten Weg der Digitalisierung und haben viele Prozesse erfolgreich digital transformiert. Dennoch gibt es immer Raum für Innovationen, um Ihre digitale Infrastruktur weiter zu stärken und zukunftssicher zu machen.',
                    
                },
                {
                    rangeValue:{ min:60, max:80 },
                    hexCode:"#FF922D",
                    label:'DURCHSCHNITT',
                    result: 'Ihr Unternehmen hat eine solide Basis für die Digitalisierung geschaffen, aber es gibt noch Potenzial, das ausgeschöpft werden kann. Die Vertiefung Ihrer digitalen Transformation kann Prozesse weiter optimieren und die Sicherheit erhöhen.',
                },
                {
                    rangeValue:{ min:30, max:50 },
                    hexCode:"#CC0100",
                    label:'MÄßIG',
                    result:'Sie haben erste Schritte in Richtung Digitalisierung unternommen, doch es gibt wesentliche Bereiche, die noch nicht digital transformiert sind. Eine gezielte Optimierung kann die Effizienz steigern und neue Möglichkeiten für Ihr Unternehmen eröffnen.'
                },
                {
                    rangeValue: {min: 0, max:20},
                    hexCode:"#CC0100",
                    label:'SCHWACH',
                    result:'Ihre Digitalisierungsstrategie ist noch in den Anfängen und es fehlt an einer umfassenden Integration digitaler Technologien. Es ist wichtig, jetzt zu handeln, um nicht den Anschluss zu verlieren und die Wettbewerbsfähigkeit zu sichern.'
                },
            ],
        },
        {
            id:5,
            key:'termine',
            name:'Termin vereinbaren',
            text: 'Die Ergebnisse Ihres DigiCHECK liefern erste Einblicke in Ihren Digitalisierungsstand. Im persönlichen Gespräch wird Ihr digitaler Reifegrad anschließend genau erfasst sowie maßgeschneiderte Lösungen erarbeitet.',
            dataConsent:'Mit dem Absenden dieses Formulars bestätigen Sie, dass Sie unsere <a href="https://www.canon.de/privacy/privacy-notice/" target="_blank">Datenschutzhinweise</a> gelesen und verstanden haben. Wir sammeln statistische Daten über das Öffnen von E-Mails und Klicks mit Hilfe von branchenüblichen Technologien. Die Statistiken helfen uns, unsere Marketingstrategien zu überprüfen und zu verbessern.'
        },
    ]
 }


 export { surveyData }