import { CircularProgress } from 'shared/components';
import {
    SummaryCirclesBenchmarkScore,
    SummaryCirclesBenchmarkScoreLabel,
    SummaryCirclesInner,
    SummaryCirclesOuter,
    SummaryCirclesWrapper,
    SummaryScore,
    SummaryScoreLabel,
    SummaryScoreTitle,
    SummaryScoreValue,
} from './SummaryCircles.styled';

export const SummaryCircles = ({
    score,
    benchnmarkScore,
    color,
    categoryLabel,
    width,
    benchmarkIndicator = true,
    innerLabel = '',
}: any) => {
    console.log(benchnmarkScore);
    return (
        <SummaryCirclesWrapper>
            <SummaryScore>
                <SummaryScoreTitle>Gesamt</SummaryScoreTitle>
                <SummaryScoreValue>{score}</SummaryScoreValue>
                <SummaryScoreLabel>Punkte</SummaryScoreLabel>
            </SummaryScore>
            <SummaryCirclesOuter>
                <CircularProgress
                    label="Dein Score"
                    size={width + 60}
                    progress={score}
                    strokeWidth={10}
                    color="#4A4E52"
                />
            </SummaryCirclesOuter>
            <SummaryCirclesInner>
                <CircularProgress
                    label={categoryLabel}
                    benchmarkIndicator={benchmarkIndicator}
                    size={width}
                    progress={benchnmarkScore}
                    strokeWidth={10}
                    color={color}
                />
            </SummaryCirclesInner>
            <SummaryCirclesBenchmarkScore color={color}>
                <SummaryCirclesBenchmarkScoreLabel>{innerLabel}</SummaryCirclesBenchmarkScoreLabel>
                {benchnmarkScore}
                <SummaryCirclesBenchmarkScoreLabel>Punkte</SummaryCirclesBenchmarkScoreLabel>
            </SummaryCirclesBenchmarkScore>
        </SummaryCirclesWrapper>
    );
};
